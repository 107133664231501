import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { IncludedInModule } from '@portal-core/general/pipes/included-in/included-in.module';
import { GridModule } from '@portal-core/ui/grid/grid.module';
import { ConditionsGridComponent } from './conditions-grid.component';

@NgModule({
  declarations: [
    ConditionsGridComponent
  ],
  imports: [
    CommonModule,
    GridModule,
    IncludedInModule,
    MatTooltipModule,
    MatIconModule,
  ],
  exports: [
    ConditionsGridComponent
  ]
})
export class ConditionsGridModule { }
