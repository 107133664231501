<div class="mc-spring-board">
  <mat-toolbar class="mc-spring-board-header mc-review-edit-snippet-dialog-toolbar" color="secondary">
    <mc-maximize-dialog-button [dialogRef]="dialogRef" [maximized]="true"></mc-maximize-dialog-button>
    <button mat-icon-button color="primary" tabindex="-1" (click)="closeDialog()">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
    </button>
  </mat-toolbar>

  <div class="mc-spring-board-form-body mc-review-edit-snippet-dialog-container">
    <ng-container *ngIf="review$ | async as review">
      <mc-flare-file-reviewer *ngIf="!review.file.IsDeleted" class="mc-review-edit-snippet-dialog-editor" [reviewPackageId]="reviewPackageId" [reviewFile]="review.file" [featureSetVersion]="featureSetVersion" [reviewFileUser]="review.user" [reviewer]="reviewer" (reviewerStatusUpdate)="onReviewerStatusUpdated($event)" (navToFile)="onNavToFile($event)"></mc-flare-file-reviewer>
    </ng-container>

    <mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>

    <div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
      <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async" (retry)="onRetryLoadFile()"></mc-errors-expansion-panel>
    </div>
  </div>
</div>