import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { nodeToFlareXML } from '@common/flare/util/prosemirror-flare-xml';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';

export class SvgSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    svg: {
      group: 'inline',
      inline: true,
      attrs: {
        content: { default: undefined }
      },
      parseDOM: [{
        tag: 'svg',
        getAttrs: function (dom: HTMLElement) {
          return {
            content: dom.outerHTML
          };
        }
      }],
      toDOM() { return ['span', { class: 'mc-pm-svg' }, 'SVG']; },
      toFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
        return nodeToFlareXML(node, options, 'svg', {
          xml: node.attrs.content
        });
      }
    }
  };
}
