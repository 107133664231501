import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthRoute } from '@portal-core/auth/enums/auth-route.enum';
import { ApiService } from '@portal-core/auth/services/api.service';
import { AuthRouteService } from '@portal-core/auth/services/auth-route.service';
import { AuthUrlService } from '@portal-core/auth/services/auth-url.service';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CentralInstanceSubdomainGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private authRouteService: AuthRouteService,
    private authUrlService: AuthUrlService,
    private apiService: ApiService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // Route guards can be called multiple times as navigation occurs. Check if the subdomain has already been validated before doing the work to validate it.
    if (this.authService.hasValidatedSubdomain()) {
      return of(this.authService.isValidSubdomain());
    }

    return this.authService.getLicenseCentralInstance$(this.authUrlService.getSubdomain()).pipe(
      tap(centralInstance => {
        this.apiService.setCentralInstance(centralInstance);
      }),
      switchMap(() => this.authService.getSubdomainLicense$()),
      map(license => {
        if (license) {
          return true;
        } else {
          this.authRouteService.navigateToAuthRoute$(AuthRoute.SubdomainGuardError).subscribe();
          return false;
        }
      }),
      catchError(() => {
        this.authRouteService.navigateToAuthRoute$(AuthRoute.SubdomainGuardError).subscribe();
        return of(false);
      })
    );
  }
}
