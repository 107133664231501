import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ProjectFilesAddSnippetDialogComponent } from './project-files-add-snippet-dialog.component';
import { ProjectFilesTreeItemPreviewModule } from '@portal-core/project-files/components/project-files-tree-item-preview/project-files-tree-item-preview.module';

@NgModule({
  declarations: [
    ProjectFilesAddSnippetDialogComponent
  ],
  imports: [
    CommonModule,
    ProjectFilesTreeItemPreviewModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatToolbarModule
  ],
  exports: [
    ProjectFilesAddSnippetDialogComponent
  ]
})
export class ProjectFilesAddSnippetDialogModule { }
