import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { contentToFlareXML, NodeToFlareXMLOptions } from '@common/flare/util/prosemirror-flare-xml';
import { nbspEntityNumber } from '@common/html/util/dom';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { MarkSpec, NodeSpec, ProseMirrorNode } from 'prosemirror-model';

/*
 * WhitespaceSchemaPlugin
 * Flare block nodes default to an nbsp character when empty. This module adds support for default nbsp content in block nodes.
 * Adds a contentToFlareXML method to the node spec that returns an nbsp character when the node has no content.
 */
export class WhitespaceSchemaPlugin extends SchemaPlugin {
  modifyNodesAndMarks(nodes: Dictionary<NodeSpec>, marks: Dictionary<MarkSpec>) {
    // Update the node toDOM methods for supporting nbsp content
    Object.entries(nodes).forEach(([name, nodeSpec]) => {
      // Add nbsp content support to every block element
      if (nodeSpec.group === 'block' || nodeSpec.defaultContentToNbsp) {
        // Define contentToFlareXML to return an nbsp character when the node has no content
        const originalContentToFlareXML = nodeSpec.contentToFlareXML;
        nodeSpec.contentToFlareXML = function (node: ProseMirrorNode, options: ToFlareXMLOptions, nodeOptions?: NodeToFlareXMLOptions): string {
          // Build the content
          let xml = '';
          if (typeof originalContentToFlareXML === 'function') {
            xml = originalContentToFlareXML.apply(this, arguments);
          } else {
            xml = contentToFlareXML(node, options, nodeOptions);
          }

          // Return an nbsp character if the content is empty
          if (xml === '') {
            return nbspEntityNumber;
          } else {
            return xml;
          }
        };
      }
    });
  }
}
