import { Injectable } from '@angular/core';
import { escapeHTML } from '@common/util/string';
import { marked } from 'marked';

@Injectable({
    providedIn: 'root'
})
export class MarkdownService {

    private madcapHTMLRegex = /^ {0,3}(?:<(script|pre|style|textarea)[\s>][\s\S]*?(?:<\/\1>[^\n]*\n+|$)|<!--(?!-?>)[\s\S]*?(?:-->|$)[^\n]*(\n+|$)|<\?[\s\S]*?(?:\?>\n*|$)|<![A-Z][\s\S]*?(?:>\n*|$)|<!\[CDATA\[[\s\S]*?(?:\]\]>\n*|$)|<\/?([a-zA-Z][a-zA-Z0-9-]*:[a-zA-Z][a-zA-Z0-9-]*|address|article|aside|base|basefont|blockquote|body|caption|center|col|colgroup|dd|details|dialog|dir|div|dl|dt|fieldset|figcaption|figure|footer|form|frame|frameset|h[1-6]|head|header|hr|html|iframe|legend|li|link|main|menu|menuitem|meta|nav|noframes|ol|optgroup|option|p|param|section|source|summary|table|tbody|td|tfoot|th|thead|title|tr|track|ul)(?: +|\n|\/?>)[\s\S]*?(?:(?:\n *)+\n|$)|<(?!script|pre|style|textarea)([a-z][\w-]*)(?: +[a-zA-Z:_][\w.:-]*(?: *= *"[^"\n]*"| *= *'[^'\n]*'| *= *[^\s"'=<>`]+)?)*? *\/?>(?=[ \t]*(?:\n|$))[\s\S]*?(?:(?:\n *)+\n|$)|<\/(?!script|pre|style|textarea)[a-z][\w-]*\s*>(?=[ \t]*(?:\n|$))[\s\S]*?(?:(?:\n *)+\n|$))/
    private codeRenderer = {
        code(code: string, infoString: string, escaped: boolean) {
            const startTag = '<MadCap:codeSnippet> <MadCap:codeSnippetBody>';
            const closeTag = '</MadCap:codeSnippetBody> </MadCap:codeSnippet>';
            return `${startTag}${escapeHTML(code)}${closeTag}`;
        },
        codespan(code: string) {
            return code;
        }
    };

    private tokenizer = {
        code(src: string) {
            const match = src.match(/ {4}[^\n]+/);
            // use default tokenizer
            if (!match) {
                return false;
            }
        },
        html: (src: string) => {
            const match = src.match(this.madcapHTMLRegex);
            if (match) {
                const token = {
                    type: 'html',
                    block: true,
                    raw: src,
                    pre: src.match('<pre|<script|<style') ? true : false,
                    text: src
                };
                return token;
            }
        }

    };

    toFlareXML(markdown: string): string {
        const renderer = new marked.Renderer();
        const tokenizer = new marked.Tokenizer();
        Object.assign(renderer, this.codeRenderer);
        Object.assign(tokenizer, this.tokenizer);
        // Throws warnings without the headerIds setting and the mangle setting.
        // marked(): mangle parameter is enabled by default, but is deprecated since version 5.0.0, and will be removed in the future. To clear this warning, install https://www.npmjs.com/package/marked-mangle, or disable by setting `{mangle: false}`
        // marked(): headerIds and headerPrefix parameters enabled by default, but are deprecated since version 5.0.0, and will be removed in the future. To clear this warning, install  https://www.npmjs.com/package/marked-gfm-heading-id, or disable by setting `{headerIds: false}
        return marked(markdown, { renderer: renderer, tokenizer: tokenizer, headerIds: false, mangle: false });
    }
}

