import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { CoreService } from '@portal-client/core/core.service';

@Component({
  selector: 'mc-app-drawer-user-launch-pad',
  templateUrl: './app-drawer-user-launch-pad.component.html',
  styleUrls: ['./app-drawer-user-launch-pad.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppDrawerUserLaunchPadComponent implements OnInit {
  currentLicenseUser$: Observable<LicenseUser>;

  constructor(private authService: AuthService, private coreService: CoreService) { }

  ngOnInit() {
    this.currentLicenseUser$ = this.coreService.getLicenseUser$();
  }

  onSignOutClicked() {
    this.authService.logout();
  }
}
