import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { LicenseHostMapsState } from '@portal-core/license-host-maps/services/license-host-maps-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([LicenseHostMapsState])
  ]
})
export class LicenseHostMapsServicesModule { }
