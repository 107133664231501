import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { CommonModule } from '@angular/common';
import { NotificationListModule } from '@portal-core/notifications/components/notification-list/notification-list.module';
import { TaskNotificationListComponent } from './task-notification-list.component';

@NgModule({
  declarations: [
    TaskNotificationListComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressBarModule,
    NotificationListModule
  ],
  exports: [
    TaskNotificationListComponent
  ]
})
export class TaskNotificationListModule { }
