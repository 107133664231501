import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { BuildsScheduleState } from '@portal-core/builds/services/builds-schedule-data.service';

@NgModule({
  imports: [
    NgxsModule.forFeature([BuildsScheduleState])
  ]
})
export class BuildsScheduleServicesModule { }
