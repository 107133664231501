import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DataStreamService } from '@portal-core/data-stream/services/data-stream.service';
import { AuditCategoryName } from '@portal-core/notifications/enums/audit-category-name.enum';
import { NotificationPage } from '@portal-core/notifications/models/notification-page.model';
import { Notification } from '@portal-core/notifications/models/notification.model';
import { NotificationsService } from '@portal-core/notifications/services/notifications.service';
import { NotificationList } from '@portal-core/notifications/util/notification-list';
import { filter as _filter } from 'lodash';
import { Observable } from 'rxjs';

@Component({
  selector: 'mc-task-notification-list',
  templateUrl: './task-notification-list.component.html',
  styleUrls: ['./task-notification-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskNotificationListComponent extends NotificationList implements OnChanges, OnInit {
  @HostBinding('class.mc-notification-list') notificationListClass: boolean = true;
  @Input() taskBoardId: number;
  @Input() licenseId: number;

  constructor(
    protected notificationsService: NotificationsService,
    protected dataStreamService: DataStreamService,
    cdr: ChangeDetectorRef
  ) {
    super(notificationsService, dataStreamService, cdr);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.licenseId || changes.taskBoardId) {
      this.onConfigurationChanged();
    }
  }

  protected isConfigured(): boolean {
    return typeof this.licenseId === 'number';
  }

  protected fetchNotifications$(pageSize: number, pageIndex: number): Observable<NotificationPage> {
    return this.notificationsService.getTaskNotificationsByLicenseId$(this.licenseId, pageSize, pageIndex, this.taskBoardId);
  }

  protected filterNotifications(notifications: { [id: string]: Notification }): Notification[] {
    return _filter(notifications, (notification: Notification) => {
      return notification.LicenseId === this.licenseId && notification.Category
        && (this.taskBoardId == null || notification.TaskBoardId === this.taskBoardId)
        &&
        (
          notification.Category.Name === AuditCategoryName.Tasks ||
          notification.Category.Name === AuditCategoryName.TaskDeleted ||
          notification.Category.Name === AuditCategoryName.TaskNewOrAssigned ||
          notification.Category.Name === AuditCategoryName.TaskEdited ||
          notification.Category.Name === AuditCategoryName.TaskCommentsorFileActions ||
          notification.Category.Name === AuditCategoryName.TaskStatusChanged ||
          notification.Category.Name === AuditCategoryName.TaskBoardCreated ||
          notification.Category.Name === AuditCategoryName.TaskBoardDeleted ||
          notification.Category.Name === AuditCategoryName.TaskBoardEdited
        );
    });
  }
}
