import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { StopPropagationModule } from '@portal-core/general/directives/stop-propagation/stop-propagation.module';
import { LicenseAvatarModule } from '@portal-core/licenses/components/license-avatar/license-avatar.module';
import { IfAuthorModule } from '@portal-core/user-access/directives/if-author/if-author.module';
import { AppLicenseDropdownComponent } from '@portal-client/app/components/app-license-dropdown/app-license-dropdown.component';

@NgModule({
  declarations: [
    AppLicenseDropdownComponent
  ],
  imports: [
    CommonModule,
    IfAuthorModule,
    LicenseAvatarModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatToolbarModule,
    RouterModule,
    StopPropagationModule
  ],
  exports: [
    AppLicenseDropdownComponent
  ]
})
export class AppLicenseDropdownModule { }
