import { Injectable } from '@angular/core';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { SystemMessageType } from '@portal-core/system-messages/enums/system-message-type.enum';
import { SystemMessage } from '@portal-core/system-messages/models/system-message.model';
import { SystemMessagesDataService } from '@portal-core/system-messages/services/system-messages-data.service';
import { UsersService } from '@portal-core/users/services/users.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class SystemMessagesService extends CollectionServiceBase<SystemMessage> {
  constructor(
    private systemMessagesDataService: SystemMessagesDataService,
    protected dataService: DataService,
    private usersService: UsersService
  ) {
    super(systemMessagesDataService, dataService);
  }

  whatsNewMessage: SystemMessage = {
    Id: 'whats-new',
    Message: 'We\'ve made some improvements. Check out what we\'ve been up to!',
    Title: 'What\'s New!',
    Type: SystemMessageType.WhatsNew,
    Url: 'https://documentation.madcapsoftware.com/central/Default.htm#cshid=Whats_New'
  };

  getWhatsNewMessage$(): Observable<SystemMessage> {
    return this.usersService.getWhatsNew$().pipe(
      map(response => {
        if (response.Result === 'True') {// response.Result is either 'True' or 'False'
          return this.whatsNewMessage;
        }
      }),
      tap(message => {
        if (message) {
          this.systemMessagesDataService.addItems$([message]);
        }
      })
    );
  }

  updateLatestAppVersionViewed$(): Observable<any> {
    return this.usersService.updateLatestVersionViewed$().pipe(
      tap(() => this.systemMessagesDataService.removeItems$([this.whatsNewMessage.Id]))
    );
  }
}
