import { CollabFileType } from '@common/collab/enums/collab-file-type.enum';
import { CollabSchema } from '@common/prosemirror/model/collab-schema';
import { CollabSessionError } from '@common/sockets/enums/collab-session-error.enum';
import { CollabSessionSaveResult } from '@common/sockets/enums/collab-session-save-result.enum';
import { CollabSessionSyncState } from '@common/sockets/enums/collab-session-sync-state.enum';
import { SocketEmitter, SocketEmitterError } from '@common/sockets/socket-emitter';
import { CustomSocketError } from '@common/sockets/socket-error';
import { CollabSessionErrorEvent } from '@common/sockets/types/collab-session-error-event.type';
import { CustomError } from '@common/util/custom-error';
import { isEnumValue } from '@common/util/enum';
import { EventEmitter } from '@common/util/event-emitter';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { SubscriptionProperty } from '@common/util/subscription-property.decorator';
import { URL } from '@common/util/url';
import { byteSize } from '@common/util/utf';
import { throttle } from 'lodash';
import { SendableSteps, collab, getVersion, receiveTransaction, sendableSteps } from 'prosemirror-collab';
import { EditorState, Plugin, Transaction } from 'prosemirror-state';
import { Step } from 'prosemirror-transform';
import { Observable, Subscription } from 'rxjs';
import { Socket, io } from 'socket.io-client';

export interface CollabSessionOptions {
  authToken?: string;
  changeThrottleTimeMS?: number;
  clientID?: number | string;
  collabAuthorityUrl: string;
  editorState?: EditorState;
  fileId: number;
  fileType: CollabFileType;
  offline?: boolean;
  plugins?: Plugin[];
  schema: CollabSchema;
  socketMaxHttpBufferSizeBytes?: number;
  userID: string;
}

export interface CollabSessionTransactionTooLargeEvent {
  maxSize: number;
  transaction: Transaction;
  updateSize: number;
}

/**
 * CollabSession
 */
export class CollabSession extends EventEmitter {
  authToken: string;
  clientID: number | string;
  collabAuthorityUrl: string;
  editorState: EditorState;
  fileId: number;
  fileType: CollabFileType;
  plugins: Plugin[];
  projectId: number;
  schema: CollabSchema;
  socketMaxHttpBufferSizeBytes: number;
  userID: string;

  connected: boolean = false;
  docId: string;
  loaded: boolean = false;
  socket: Socket;
  stopped: boolean = false;
  private emitter: SocketEmitter;
  private throttledSendChanges: Function;
  @SubscriptionProperty() private offlineSubscription: Subscription;

  /** The session's current error if it is in an error state. */
  error: CollabSessionErrorEvent = null;
  /** An observable of the session's current error. */
  @PropertyObservable('error') error$: Observable<CollabSessionErrorEvent>;

  /** Wether the doc when serialized to an xml string is larger than the max size for a doc. */
  exceedsMaxSize: boolean;
  @PropertyObservable('exceedsMaxSize') exceedsMaxSize$: Observable<boolean>;

  /** Wether the doc when saved to MongoDB is larger than the max size for a document in MongoDB. */
  exceedsMongoDBMaxSize: boolean;
  @PropertyObservable('exceedsMongoDBMaxSize') exceedsMongoDBMaxSize$: Observable<boolean>;

  /** Whether or not the session is in read-only mode. */
  readOnly: boolean = false;
  @PropertyObservable('readOnly') readOnly$: Observable<boolean>;

  /** The size of the doc when it is serialized to an xml string. */
  size: number;
  @PropertyObservable('size') size$: Observable<number>;

  /** The current syncing state of the session. */
  get syncState(): CollabSessionSyncState {
    return this._syncState;
  }
  set syncState(syncState: CollabSessionSyncState) {
    if (this._syncState === CollabSessionSyncState.Stopped && syncState !== CollabSessionSyncState.Stopped) {
      throw new CustomError('CollabSessionInvalidSyncStateTransition', `${this._syncState} -> ${syncState}`);
    }

    // If the session is reverting then only allow the sync state to change to Stopped, Reverted, or RevertFailed
    // Reverting is a special case because the reversion has priority over loading and saving states.
    if (this._syncState === CollabSessionSyncState.Reverting) {
      if (syncState !== CollabSessionSyncState.Stopped && syncState !== CollabSessionSyncState.Reverted && syncState !== CollabSessionSyncState.RevertFailed) {
        return;
      }
    }

    if (syncState === CollabSessionSyncState.Saved) {
      if (this._syncState !== CollabSessionSyncState.Saving) {
        throw new CustomError('CollabSessionInvalidSyncStateTransition', `${this._syncState} -> ${syncState}`);
      }
    } else if (syncState === CollabSessionSyncState.Loaded) {
      if (this._syncState !== CollabSessionSyncState.Loading) {
        throw new CustomError('CollabSessionInvalidSyncStateTransition', `${this._syncState} -> ${syncState}`);
      }
    } else if (syncState === CollabSessionSyncState.SaveFailed) {
      if (this._syncState !== CollabSessionSyncState.Saving) {
        throw new CustomError('CollabSessionInvalidSyncStateTransition', `${this._syncState} -> ${syncState}`);
      }
    } else if (syncState === CollabSessionSyncState.Unsaved) {
      if (this._syncState !== CollabSessionSyncState.Loaded && this._syncState !== CollabSessionSyncState.Saved && this._syncState !== CollabSessionSyncState.Saving && this._syncState !== CollabSessionSyncState.SaveFailed && this._syncState !== CollabSessionSyncState.Unsaved && this._syncState !== CollabSessionSyncState.RevertFailed) {
        throw new CustomError('CollabSessionInvalidSyncStateTransition', `${this._syncState} -> ${syncState}`);
      }
    } else if (syncState === CollabSessionSyncState.Saving) {
      if (this._syncState !== CollabSessionSyncState.Loaded && this._syncState !== CollabSessionSyncState.Saved && this._syncState !== CollabSessionSyncState.SaveFailed && this._syncState !== CollabSessionSyncState.Unsaved) {
        throw new CustomError('CollabSessionInvalidSyncStateTransition', `${this._syncState} -> ${syncState}`);
      }
    } else if (syncState === CollabSessionSyncState.Loading) {
      if (this._syncState !== CollabSessionSyncState.NotLoaded) {
        throw new CustomError('CollabSessionInvalidSyncStateTransition', `${this._syncState} -> ${syncState}`);
      }
    } else if (syncState === CollabSessionSyncState.Reverted) {
      if (this._syncState !== CollabSessionSyncState.Reverting) {
        throw new CustomError('CollabSessionInvalidSyncStateTransition', `${this._syncState} -> ${syncState}`);
      }
    } else if (syncState === CollabSessionSyncState.RevertFailed) {
      if (this._syncState !== CollabSessionSyncState.Reverting) {
        throw new CustomError('CollabSessionInvalidSyncStateTransition', `${this._syncState} -> ${syncState}`);
      }
    }

    this._syncState = syncState;
  }
  private _syncState: CollabSessionSyncState = CollabSessionSyncState.NotLoaded;
  @PropertyObservable('syncState') syncState$: Observable<CollabSessionSyncState>;

  /**
   * Whether or not the session is offline. The session does not determine if it is offline or not.
   * This should be set whenever the client's offline state changes such as as browser's offline mode being toggled.
   */
  offline: boolean = false;
  @PropertyObservable('offline') offline$: Observable<boolean>;

  constructor(options: CollabSessionOptions) {
    super();

    this.authToken = options.authToken ?? '';
    this.clientID = options.clientID;
    this.collabAuthorityUrl = options.collabAuthorityUrl;
    this.editorState = options.editorState;
    this.fileType = options.fileType ?? CollabFileType.Unknown;
    this.fileId = options.fileId ?? null;
    this.offline = options.offline ?? false;
    this.plugins = options.plugins ?? [];
    this.schema = options.schema;
    this.socketMaxHttpBufferSizeBytes = options.socketMaxHttpBufferSizeBytes;
    this.userID = options.userID;

    // Listen to the offline status changing so that a new connection can be made when coming back online
    this.offlineSubscription = this.offline$.subscribe(offline => {
      if (!offline) {
        this.socket?.connect();
      }
    });

    // Created a throttled version of sendChanges so that the updates sent to the server don't happen too often
    this.throttledSendChanges = throttle(() => {
      // Use setTimeout to always run this function in the next event cycle. Its possible for sendChanges to call throttledSendChanges and we only want one sendChanges running at a time.
      setTimeout(() => {
        try {
          // Because this function is scheduled to happen in the future its possible that the session has been stopped since then
          // So do a check to make sure the changes can and should be sent.
          if (this.stopped || !this.emitter || !this.editorState) {
            return;
          }

          const stepsInfo = sendableSteps(this.editorState);
          if (stepsInfo) {
            this.sendChanges(this.editorState, stepsInfo).catch(error => {
              // Do nothing.
              // This error has already been handled by onServerError but it still needs to be caught so that it is not an unhandled promise error
            });
          }
        } catch (error) {
          this.error = { type: CollabSessionError.ThrottledSendChangesFailed, error };
        }
      });
    }, options.changeThrottleTimeMS ?? 1000, {
      leading: false
    });
  }

  /** Creates a session with the server by opening a socket connection and setting up event handlers. */
  start() {
    // Initiate the socket connection
    const baseUrl = typeof document !== 'undefined' ? document.baseURI : undefined;
    const socketUrl = new URL(this.collabAuthorityUrl + '/socket.io', baseUrl);

    this.socket = io(`${socketUrl.protocol}//${socketUrl.host}`, {
      auth: {
        token: this.authToken
      },
      path: socketUrl.pathname,
      query: {
        fileId: Number.isInteger(this.fileId) ? this.fileId.toString() : '',
        schemaVersion: this.schema.version
      }
    });

    this.socket.on('connect_error', (error: CustomSocketError) => {
      if (error.data && isEnumValue(CollabSessionError, error.data.name)) {
        this.error = { type: error.data.name as CollabSessionError, error: error.data };
      } else {
        this.error = { type: CollabSessionError.UnableToReachServer, error };
      }
    });

    this.socket.on('connect_timeout', error => {
      this.error = { type: CollabSessionError.UnableToReachServer, error };
    });

    this.socket.on('reconnect_error', error => {
      this.error = { type: CollabSessionError.UnableToReachServer, error };
    });

    // Listen to disconnects in order to track the connection status
    this.socket.on('disconnect', reason => {
      this.connected = false;
      // Since the socket disconnected the emissions need to be canceled
      this.emitter?.cancelAllEmissions();
    });

    // Listen to connects in order to track the connection status and initialize the collab statuses
    this.socket.on('connect', () => {
      this.emitter.cancelAllEmissions();
      this.connected = true;
      this.error = null;
    });

    // Listen to the ready event and load the document
    this.socket.on('ready', data => {
      // Store the docId for future communication with the collab server
      this.docId = data.docId;

      // If the doc is already loaded then fetch the updates for it
      if (this.loaded) {
        this.fetchUpdates();
        // Else load the entire doc
      } else {
        this.loadDoc();
      }
    });

    // Listen to incompatible schema errors
    this.socket.on('doc.schema.incompatible', error => {
      this.onServerError(error);
    });

    // Listen to doc changed events in order to get the latest updates to the doc
    this.socket.on('doc.changed', data => {
      if (this.loaded) {
        this.fetchUpdates();
      }
    });

    // Listen to doc readOnly changed events in order to update the readOnly state
    this.socket.on('doc.readOnly.changed', data => {
      this.readOnly = data.readOnly;
    });

    // Create an emitter for sending messages over the socket
    this.emitter = new SocketEmitter(this.socket);
  }

  /** Disconnects the socket and cleans up resources. */
  stop(): Promise<void> {
    return new Promise((resolve) => {
      this.socket?.once('disconnect', () => {
        this.emit('stopped');

        // Make sure all listeners are removed from the socket
        this.socket?.removeAllListeners();
        this.socket = null;

        // Remove all listeners from the session
        this.removeAllListeners();

        resolve();
      });

      this.stopped = true;
      this.loaded = false;
      this.editorState = null;
      this.syncState = CollabSessionSyncState.Stopped;
      this.socket?.disconnect();
      this.offlineSubscription = null;
      this.emitter?.cancelAllEmissions();
      this.emitter = null;
    });
  }

  /** Saves the local doc changes to the server. */
  save(): Promise<CollabSessionSaveResult> {
    return new Promise<CollabSessionSaveResult>((resolve, reject) => {
      // Because this function is designed to be called externally its possible that the session has been stopped so do a check to make sure the changes can and should be sent.
      if (this.stopped || !this.emitter) {
        // Consider this a successful save
        resolve(CollabSessionSaveResult.SessionStopped);
        return;
      }

      const stepsInfo = sendableSteps(this.editorState);

      // If there are steps to save then send the changes to the server
      if (stepsInfo) {
        this.sendChanges(this.editorState, stepsInfo).then(resolve, reject);
      } else {
        // Else there are no changes so consider this a successful save
        resolve(CollabSessionSaveResult.NothingToSave);
      }
    });
  }

  revertDocToSavedState(): Promise<void> {
    this.syncState = CollabSessionSyncState.Reverting;

    return this.emitter.emit('doc.revert', {
      docId: this.docId
    }, this.retryEmit.bind(this)).then(() => {
      this.syncState = CollabSessionSyncState.Reverted;
    }).catch(error => {
      this.syncState = CollabSessionSyncState.RevertFailed;
      this.onServerError(error);
    });
  }

  /** Dispatches a transaction to the prosemirror editor. */
  dispatch(transaction: Transaction) {
    this.runDispatch(transaction, true);
  }

  /** Dispatches a transaction to the prosemirror editor and optionally sends any changes to the server. */
  private runDispatch(transaction: Transaction, checkForChanges: boolean) {
    // Do not allow changes if the collab session has been stopped
    if (this.stopped) {
      return;
    }

    // Do not allow changes if there is no connection to the server
    // OR the session has been stopped
    // OR the doc has not been successfully loaded from the server
    // OR the doc is reverting
    if (this.offline || !this.connected || !this.loaded || this.syncState === CollabSessionSyncState.Reverting) {
      // If there is no connection then try reconnecting right now
      if (!this.connected) {
        this.socket.connect();
      } else if (!this.loaded) {
        // Else if the doc has not been loaded yet try again
        this.loadDoc();
      }

      return;
    }

    // Do not allow transactions if they change the doc when in readonly mode.
    // We want to keep transactions that change the selection so the user can still select content in the doc.
    // We also want to allow passive transactions like meta data loading.
    if (this.readOnly && transaction.docChanged) {
      return;
    }

    // Apply the transaction
    const newState = this.editorState.apply(transaction);

    // Ignore the transaction if it is too large to send over the socket connection
    if (typeof this.socketMaxHttpBufferSizeBytes === 'number') {
      const stepsInfo = sendableSteps(newState);
      if (stepsInfo) {
        const update = this.createUpdate(newState, stepsInfo);
        const updateSize = byteSize(JSON.stringify(update));

        // If the update size is greater than 1MB then ignore the transaction (add 5120 to account for the message overhead)
        if (updateSize + 5120 > this.socketMaxHttpBufferSizeBytes) {
          this.emit('transactionTooLarge', {
            maxSize: this.socketMaxHttpBufferSizeBytes,
            transaction,
            updateSize
          });
          return;
        }
      }
    }

    // If there is new state after the transaction then check if there are more changes to send to the server
    if (newState) {
      this.editorState = newState;
      if (checkForChanges) {
        this.checkForAndSendChanges();
      }
    }

    // Apply the state to the editor
    if (this.editorState) {
      this.emit('update', this);
    }
  }

  private createUpdate(state: EditorState, stepsInfo: SendableSteps): any {
    return {
      docId: this.docId,
      schemaVersion: this.schema.version,
      docVersion: getVersion(state),
      steps: stepsInfo.steps.map(step => step.toJSON()),
      clientID: stepsInfo.clientID ?? 0,
      userID: this.userID
    };
  }

  /** Checks if there are unsaved changes and sends them to the server if there are. */
  private checkForAndSendChanges(): boolean {
    // If there are unsaved changes then send them to the server
    if (sendableSteps(this.editorState)) {
      // If saving then don't change the sync state
      if (this.syncState !== CollabSessionSyncState.Saving) {
        this.syncState = CollabSessionSyncState.Unsaved;

        // Send the changes to the collab server
        this.throttledSendChanges();
      }

      return true;
    } else {
      // If the sync state is currently a failed save when we have nothing to save
      if (this.syncState === CollabSessionSyncState.SaveFailed) {
        // Then the save must have been successful but the session didn't get a response from the server saying as much
        // Set the sync state to Saved by first going through Saving
        this.syncState = CollabSessionSyncState.Saving;
        this.syncState = CollabSessionSyncState.Saved;
      }
    }

    return false;
  }

  /** Sends the local doc changes to the server. */
  private sendChanges(state: EditorState, stepsInfo: SendableSteps): Promise<CollabSessionSaveResult> {
    if (this.syncState === CollabSessionSyncState.Stopped) {
      return Promise.resolve(CollabSessionSaveResult.SessionStopped);
    } else if (this.syncState === CollabSessionSyncState.NotLoaded || this.syncState === CollabSessionSyncState.Loading) {
      return Promise.resolve(CollabSessionSaveResult.NotLoaded);
    } else if (this.syncState === CollabSessionSyncState.Saving) {
      return Promise.resolve(CollabSessionSaveResult.AlreadySaving);
    } else if (this._syncState === CollabSessionSyncState.Loaded || this._syncState === CollabSessionSyncState.Saved || this._syncState === CollabSessionSyncState.SaveFailed || this._syncState === CollabSessionSyncState.Unsaved) {
      if (!stepsInfo || !Array.isArray(stepsInfo.steps) || stepsInfo.steps.length === 0) {
        return Promise.resolve(CollabSessionSaveResult.NothingToSave);
      } else {
        this.syncState = CollabSessionSyncState.Saving;

        try {
          return this.emitter.emit('doc.update', {
            docId: this.docId,
            schemaVersion: this.schema.version,
            docVersion: getVersion(state),
            steps: stepsInfo.steps.map(step => step.toJSON()),
            clientID: stepsInfo.clientID ?? 0,
            userID: this.userID
          }, this.retryEmit.bind(this)).then(() => {
            const tr = receiveTransaction(this.editorState, stepsInfo.steps, Array.isArray(stepsInfo.steps) ? stepsInfo.steps.map(() => stepsInfo.clientID) : [], { mapSelectionBackward: true });
            this.runDispatch(tr, false);
            this.syncState = CollabSessionSyncState.Saved;
            const hasUnsavedChanges = this.checkForAndSendChanges();

            if (hasUnsavedChanges) {
              return CollabSessionSaveResult.MoreToSave;
            } else {
              return CollabSessionSaveResult.Saved;
            }
          }).catch(error => {
            // Update the sync state
            if (error.name === SocketEmitterError.AlreadyEmittingMessage) {
              return CollabSessionSaveResult.AlreadySaving;
            }

            this.syncState = CollabSessionSyncState.SaveFailed;
            this.onServerError(error);
            throw error;
          });
        } catch (error) {
          // Update the sync state
          this.syncState = CollabSessionSyncState.SaveFailed;
          return Promise.reject(error);
        }
      }
    } else {
      return Promise.resolve(CollabSessionSaveResult.UnableToSave);
    }
  }

  /** Gets the the doc changes from the server and applies them to the local document. */
  private fetchUpdates() {
    if (!this.emitter.emitting('doc.get')) {
      this.emitter.emit('doc.updates', {
        docId: this.docId,
        schemaVersion: this.schema.version,
        docVersion: getVersion(this.editorState)
      }, this.retryEmit.bind(this)).then(res => {
        // Update the doc state
        this.readOnly = res.readOnly;
        this.size = res.size;
        this.exceedsMaxSize = res.exceedsMaxSize;
        this.exceedsMongoDBMaxSize = res.exceedsMongoDBMaxSize;

        // If the local version is older than the authority's version then update the local version
        if (getVersion(this.editorState) < res.version) {
          const tr = receiveTransaction(this.editorState, Array.isArray(res.steps) ? res.steps.map(step => Step.fromJSON(this.schema, step)) : [], res.clientIDs, { mapSelectionBackward: true });
          this.dispatch(tr);
        } else {
          // There are no updates to apply but there might be more changes to send to the server
          this.checkForAndSendChanges();
        }

        // If the collab session was in error because of a version mismatch then that error has been fixed by this fetching of doc updates
        if (this.error?.type === CollabSessionError.VersionMismatch) {
          this.error = null;
        }
      }).catch(error => {
        this.onServerError(error);
      });
    }
  }

  /** Loads the entire document with the server version. */
  private loadDoc() {
    // Unload the doc and clear any pending emissions
    this.emitter.cancelAllEmissions();
    this.loaded = false;
    this.editorState = null;
    this.syncState = CollabSessionSyncState.NotLoaded;
    // Switch to loading the doc
    this.syncState = CollabSessionSyncState.Loading;

    this.emitter.emit('doc.get', {
      docId: this.docId,
      schemaVersion: this.schema.version
    }, this.retryEmit.bind(this)).then(data => {
      // Load the doc into a new editor state and apply it to the editor
      this.editorState = this.createEditorState(data.doc, data.version);
      // Update the doc state
      this.readOnly = data.readOnly;
      this.size = data.size;
      this.exceedsMaxSize = data.exceedsMaxSize;
      this.exceedsMongoDBMaxSize = data.exceedsMongoDBMaxSize;

      this.emit('update', this);

      // If the collab session was in error because the version was too old or the version was invalid then that error has been fixed by loading the doc
      if (this.error?.type === CollabSessionError.VersionTooOld || this.error?.type === CollabSessionError.DocVersionInvalid) {
        this.error = null;
      }
      this.loaded = true;
      this.syncState = CollabSessionSyncState.Loaded;
      this.emit('load', this);
    }).catch(error => {
      if (error.name !== SocketEmitterError.AlreadyEmittingMessage) {
        this.syncState = CollabSessionSyncState.LoadFailed;
      }
      this.onServerError(error);
    });
  }

  /** Creates a new state with the proper plugins. */
  private createEditorState(doc, docVersion: number): EditorState {
    const collabPlugin = collab({
      version: docVersion,
      clientID: this.clientID
    });

    return EditorState.create({
      doc: this.schema.nodeFromJSON(doc),
      schema: this.schema,
      plugins: this.plugins.concat(collabPlugin)
    });
  }

  /** Returns true if the SocketEmitter should retry emitting a message when an error occurs. */
  private retryEmit(error: Error): boolean {
    // These errors will be handled by the session and the message should not be re-emitted
    if (error.name === CollabSessionError.SchemaVersionIncompatible ||
      error.name === CollabSessionError.VersionMismatch ||
      error.name === CollabSessionError.VersionTooOld ||
      error.name === CollabSessionError.DocVersionInvalid ||
      error.name === CollabSessionError.DocAccessDenied ||
      error.name === CollabSessionError.DocLoadFailed ||
      error.name === CollabSessionError.DocSizeTooLarge ||
      error.name === CollabSessionError.MongoDBDocumentSizeTooLarge ||
      error.name === CollabSessionError.BadRequest ||
      error.name === CollabSessionError.DocReadOnly
    ) {
      return false;
    }

    return true;
  }

  /** Handles server errors. */
  private onServerError(error: Error) {
    let handled = true;

    if (error.name === CollabSessionError.SchemaVersionIncompatible) {
      // The client and server have incompatible schemas meaning they cannot work together. So just disconnect from the server
      this.stop();
    } else if (error.name === CollabSessionError.VersionMismatch) {
      // The client is not at the same version of the server. So fetch the changes to become up to date with the server
      this.fetchUpdates();
    } else if (error.name === CollabSessionError.VersionTooOld) {
      // The server does not have enough history to bring this document up to date step by step. So instead just reload the entire document from the server
      this.loadDoc();
    } else if (error.name === CollabSessionError.DocVersionInvalid) {
      // The client has somehow moved ahead of the server in version numbers. To restore the client to a working state just reload the entire document from the server
      this.loadDoc();
    } else if (error.name === CollabSessionError.DocAccessDenied) {
      // The user does not have access to this collaborative document or the document does not exist
      this.stop();
    } else if (error.name === CollabSessionError.DocLoadFailed) {
      // The collab server was unable to load the document
      this.stop();
    } else if (error.name === CollabSessionError.DocSizeTooLarge) {
      // The collab server was unable to load the document because it was too large
      this.size = (error as any).size;
      this.stop();
    } else if (error.name === CollabSessionError.MongoDBDocumentSizeTooLarge) {
      // The collab server was unable to load the document because it was too large to create in MongoDB
      this.stop();
    } else if (error.name === CollabSessionError.BadRequest) {
      // The collab server was unable to load the document because it received invalid parameters
      this.stop();
    } else if (error.name === CollabSessionError.DocReadOnly) {
      this.readOnly = true;
    } else if (error.name === SocketEmitterError.AlreadyEmittingMessage || error.name === SocketEmitterError.EmissionCanceled) {
      // Do nothing. These errors from the SocketEmitter can happen under normal operations
      handled = false;
    } else {
      handled = false;
    }

    if (handled) {
      this.error = { type: error.name as CollabSessionError, error };
    }
  }
}
