import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

export class MadCapExpandingSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapexpanding: {
      group: 'inline',
      inline: true,
      content: 'inline*',
      definingForContent: true,
      parseDOM: [{
        tag: 'MadCap\\:expanding',
        preserveWhitespace: true
      }],
      toDOM() { return ['span', { class: 'mc-madcap-expanding' }, 0]; },
      tagName: 'MadCap:expanding'
    },
    madcapexpandinghead: {
      group: 'inline',
      inline: true,
      content: 'inline*',
      definingForContent: true,
      linkBucket: 'expanding_text',
      parseDOM: [{
        tag: 'MadCap\\:expandingHead',
        preserveWhitespace: true
      }],
      toDOM() { return ['span', { class: 'mc-madcap-expanding-head' }, 0]; },
      tagName: 'MadCap:expandingHead'
    },
    madcapexpandingbody: {
      group: 'inline',
      inline: true,
      content: 'inline*',
      definingForContent: true,
      linkBucket: 'expanding_text',
      parseDOM: [{
        tag: 'MadCap\\:expandingBody',
        preserveWhitespace: true
      }],
      toDOM() { return ['span', { class: 'mc-madcap-expanding-body' }, 0]; },
      tagName: 'MadCap:expandingBody'
    }
  };
}
