import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CollabFileType } from '@common/collab/enums/collab-file-type.enum';
import { CollabSchema } from '@common/prosemirror/model/collab-schema';
import { CurrentService } from '@portal-core/current/services/current.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { License } from '@portal-core/licenses/models/license.model';
import { FlareTextEditorComponent } from '@portal-core/project-files/components/flare-text-editor/flare-text-editor.component';
import { OpenAICompletion } from '@portal-core/project-files/models/open-ai/open-ai-completion.model';
import { OpenAIMessage } from '@portal-core/project-files/models/open-ai/open-ai-message.model';
import { ProjectFilesService } from '@portal-core/project-files/services/project-files.service';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { LoadingState } from '@portal-core/util/loading-state';
import { Subscription, first } from 'rxjs';

export interface ProjectFilesAIDialogData {
  content: string;
  commitId: string;
  branchName: string;
  licenseUser: LicenseUser;
  projectId: number;
  filePath: string;
  projectLanguage: string;
  showConditions: boolean;
};

export interface OpenAIUIMessage {
  AI: boolean;
  body: string;
  createdOn: string;
};

@Component({
  selector: 'mc-project-files-ai-dialog',
  templateUrl: './project-files-ai-dialog.component.html',
  styleUrls: ['./project-files-ai-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProjectFilesAIDialogComponent extends DialogBase implements OnInit {
  @ViewChild('messagesList', { static: false }) messagesListRef: ElementRef;

  static DialogConfig: MatDialogConfig = {
    width: '102rem',
    maxHeight: '70rem',
    minHeight: '30rem',
    autoFocus: '.mc-project-files-ai-dialog-input'
  }

  collabFileType: typeof CollabFileType = CollabFileType;
  AIForm: FormGroup;
  loadingState: LoadingState<string> = new LoadingState<string>();
  content: string;
  commitId: string;
  branchName: string;
  projectId: number;
  filePath: string;
  projectLanguage: string;
  showConditions: boolean;

  license: License;
  licenseUser: LicenseUser;
  messages: OpenAIUIMessage[] = [];
  private openAISubscription: Subscription;
  private openAIMessages: OpenAIMessage[] = [];

  constructor(
    protected dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ProjectFilesAIDialogData,
    protected dialogRef: MatDialogRef<ProjectFilesAIDialogComponent>,
    private projectFilesService: ProjectFilesService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private errorService: ErrorService,
    private currentService: CurrentService

  ) {
    super(dialog, dialogRef);
  }

  get requirePromptOnClose(): boolean {
    if (this.content) {
      // content is part of the messages so if a user sent a message it will be the second one.
      if (this.messages.length >= 2) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.messages.length >= 1) {
        return true;
      } else {
        return false;
      }
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.content = this.data.content;
    this.commitId = this.data.commitId;
    this.filePath = this.data.filePath;
    this.branchName = this.data.branchName;
    this.projectId = this.data.projectId;
    this.projectLanguage = this.data.projectLanguage;
    this.showConditions = this.data.showConditions;
    this.licenseUser = this.data.licenseUser;
    this.license = this.currentService.getActiveLicense();

    this.createAIForm();

    // "Selected Text"
    if (this.content) {
      this.createOpenAIUIMessage(false, this.content);
    }

  }

  onInsertOrReplace(editor: FlareTextEditorComponent) {
    const content = editor.getEditorState().doc.content as any;
    const schema = editor.getEditorState().schema as CollabSchema;
    const code = content ? schema.nodeToCode(content.content[0]) : '';
    this.closeDialog(code, true);
  }

  protected createAIForm() {
    this.AIForm = this.formBuilder.group({
      prompt: new UntypedFormControl('')
    });
  }

  fetchOpenAIResponse(enterEvent: any = null) {
    enterEvent?.preventDefault();
    const prompt = this.AIForm.controls.prompt.value;
    if (!prompt) return;

    this.AIForm.controls.prompt.setValue('');

    // cancel getting an answer from gpt
    if (this.openAISubscription) {
      this.scrollToMessageListBottom();
      this.unsubscribeAIResponse();
      this.loadingState.update(false);
      // these two messages are the last question asked from the user and an empty message from gpt used to show that its loading
      this.messages = this.messages.slice(0, -2);
      this.openAIMessages = this.openAIMessages.slice(0, -2);
      return;
    }

    this.loadingState.update(true);

    // add the user's message to the messages list and add an empty AI message that will be filled later.
    this.createOpenAIUIMessage(false, prompt);
    this.createOpenAIUIMessage(true, '');
    this.scrollToMessageListBottom();

    this.openAISubscription = this.projectFilesService.getOpenAIResponse$(this.projectId, this.openAIMessages).pipe(first()).subscribe(newCompletion => {
      this.assignNewChatGPTContent(newCompletion);
      this.loadingState.update(false);
      this.scrollToMessageListBottom();
      this.unsubscribeAIResponse();
    }, error => {
      this.loadingState.update(false, 'There was a problem loading the response from ChatGPT.', this.errorService.getErrorMessages(error));
      this.messages.pop();
      this.openAIMessages.pop();
      this.unsubscribeAIResponse();
    });
  }
  private unsubscribeAIResponse() {
    this.openAISubscription.unsubscribe();
    this.openAISubscription = null;
  }

  private assignNewChatGPTContent(newCompletion: OpenAICompletion) {
    const chatGptString = newCompletion.choices[0].message.content;
    this.messages[this.messages.length - 1].body = chatGptString;
    this.openAIMessages[this.messages.length - 1].content = chatGptString;
    this.cdr.markForCheck();
  }

  private scrollToMessageListBottom() {
    requestAnimationFrame(() => this.messagesListRef.nativeElement.scrollTop = this.messagesListRef.nativeElement.scrollHeight);
  }

  private createOpenAIUIMessage(AI: boolean, content: string) {
    this.messages.push({ AI: AI, body: content, createdOn: this.createDateString() });
    this.openAIMessages.push({ role: AI ? 'assistant' : 'user', content: content });
  }

  private createDateString(): string {
    return new Date().toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
  }

  onShowConditionsChanged(showConditions: boolean) {
    this.showConditions = showConditions;
  }
}

