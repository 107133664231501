import { FocusMonitor } from '@angular/cdk/a11y';
import { BACKSPACE } from '@angular/cdk/keycodes';
import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, Optional, Self, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { ProjectFilesTreeComponent } from '@portal-core/project-files/components/project-files-tree/project-files-tree.component';
import { ProjectFolder } from '@portal-core/project-files/enums/project-folder.enum';
import { CustomInputBase } from '@portal-core/ui/forms/util/custom-input-base.directive';
import { PopupComponent } from '@portal-core/ui/popup/components/popup/popup.component';
import { PopupTriggerDirective } from '@portal-core/ui/popup/directives/popup-trigger/popup-trigger.directive';
import { Subscription } from 'rxjs';

interface PathBreadcrumb {
  folderName: string;
  path: string;
  endOfRestrictedToFolderCrumb: boolean;
  partOfRestrictedToFolderCrumb: boolean;
}

@Component({
  selector: 'mc-project-file-path-input',
  templateUrl: './project-file-path-input.component.html',
  styleUrls: ['./project-file-path-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '[class.mc-project-file-path-input-disabled]': 'disabled',
    '[class.mc-text-disabled]': 'disabled'
  },
  // tslint:disable-next-line: no-inputs-metadata-property
  inputs: ['disabled', 'tabIndex'],
  providers: [
    { provide: MatFormFieldControl, useExisting: ProjectFilePathInputComponent }
  ]
})
export class ProjectFilePathInputComponent extends CustomInputBase<string> implements OnChanges, OnDestroy, AfterContentInit {
  /**
   Gets or sets the restricted to folder for the control.
  - The restricted to folder is not editable part of the path.
  - The restricted to folder is used in the folder selector control.
  */
  @Input() restrictedToFolder: ProjectFolder;

  /**
  Gets or sets whether it is a folder or a file path.
  When true, the part after '/' delimiter of path is not a file name it's just a part of the folder path, so
    - when a user selects a directory from the folder selector the last part will be lost.
    - when a user clicks on a breadcrumb the last part will be lost.
    - when the restricted to folder is changed and the current path does not start with the new restricted to folder the last part will be lost.
  */
  @Input() isFolder: boolean;

  /** The height of the popup in pixels. Defaults to 250 pixels. */
  @Input() popupHeight?: number | 'width' = 250;

  /** The project id to pick the directory from. */
  @Input() projectId: number;

  /** The branch name to pick the directory from. */
  @Input() branchName: string;

  /** The place in the tree to scroll to on open if a user hasn't selected a value yet. */
  @Input() defaultPath: string;

  @ViewChild('textInput', { static: true }) textInputElementRef: ElementRef<HTMLInputElement>;
  @ViewChild('trigger', { static: true, read: PopupTriggerDirective }) popupTrigger: PopupTriggerDirective;

  /** Required by CustomInputBase */
  controlType: string = 'mc-project-file-path-input';
  textChangeSubscription: Subscription;
  /** Binds to the input field to observe value changes and to set the value. */
  textControl: FormControl = new FormControl('');
  valueBreadcrumbs: PathBreadcrumb[] = [];

  @ViewChild('popup', { static: true }) popup: PopupComponent;

  @ViewChild(ProjectFilesTreeComponent, { static: true }) projectFilesTree: ProjectFilesTreeComponent;

  /** Returns true if the file path input's value is empty. It is considered empty if the value is not an array or is an empty array. */
  get empty(): boolean {
    return !this.value && !this.textControl.value;
  }

  /** Required by MatFormFieldControl */
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  constructor(
    _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public _parentForm: NgForm,
    @Optional() public _parentFormGroup: FormGroupDirective,
    @Optional() @Self() public ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    protected focusMonitor: FocusMonitor
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, cdr, focusMonitor);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.textChangeSubscription?.unsubscribe();
  }

  onPopupOpened() {
    this.projectFilesTree.checkViewportSize();
    if (this.value || this.defaultPath) {
      // Scroll the project files tree to what user selected when the popup is opened or the default path
      this.projectFilesTree.scrollToPath(this.value ?? this.defaultPath);
    } else {
      // Scroll the project files tree back to the top when the popup is opened and no value selected
      this.projectFilesTree.scrollToTop();
    }
  }

  ngAfterContentInit() {
    this.checkForTrailingSlashAndSetBreadcrumbs(this.value);

    // Listen for changes to the text control's value and apply to change the path.
    this.textChangeSubscription = this.textControl.valueChanges.subscribe(textValue => {
      const newFolder = textValue.includes('/');
      const textValueCollapsedSlashes = this.collapseSlashes(textValue);
      const value: string = this.valueBreadcrumbs.length ? this.valueBreadcrumbs[this.valueBreadcrumbs.length - 1].path + '/' + textValueCollapsedSlashes : textValueCollapsedSlashes;
      let valueSplit: string[] = value.split('/').map(valuePart => valuePart.trim());
      // remove whatever folder that is just plain spaces.
      valueSplit = this.removeSpaces(valueSplit);
      // set the value of the input.
      this.setValueFromUI(valueSplit.join('/'));

      // update the breadcrumbs if needed.
      if (newFolder) {
        // current file name
        const newTextValue = valueSplit.pop().trim();
        this.valueBreadcrumbs = this.createBreadCrumbs(valueSplit);
        this.textControl.setValue(newTextValue, { emitEvent: false });
        this.getFocusableElement<HTMLInputElement>().setSelectionRange(0, 0);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.restrictedToFolder?.currentValue == changes.restrictedToFolder?.previousValue) {
      return;
    }
    // save the current path if the new restricted to folder is empty or starts the current path
    const keepPath = !this.restrictedToFolder || this.value.startsWith(this.restrictedToFolder);
    const path = keepPath ? this.value : this.restrictedToFolder + '/' + this.fileName();
    this.updateValue(path);
    this.projectFilesTree.changeRootFolder(this.restrictedToFolder ?? '');
  }

  setDisabledState?(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);

    if (this.disabled) {
      this.textControl.disable();
    } else {
      this.textControl.enable();
    }
  }

  getProjectFilesTreeSelectedDirectoryPath() {
    return this.projectFilesTree.selectedDirectoryPath;
  }

  /** Forwards keydown events from the input text field to the file path for navigation purposes. */
  onInputKeyDown(event: KeyboardEvent) {
    const selectionStart = this.textInputElementRef.nativeElement.selectionStart;
    const selectionEnd = this.textInputElementRef.nativeElement.selectionEnd;

    if (event.keyCode === BACKSPACE && selectionStart === 0 && selectionStart === selectionEnd) {
      event.preventDefault();

      if (this.valueBreadcrumbs.length) {
        if (this.valueBreadcrumbs.length === 1 && !this.restrictedToFolder) {
          const newTextValue = this.valueBreadcrumbs[this.valueBreadcrumbs.length - 1].folderName;
          this.valueBreadcrumbs = [];
          this.textControl.setValue(newTextValue + this.textControl.value);
          this.getFocusableElement<HTMLInputElement>().setSelectionRange(newTextValue.length, newTextValue.length);
        } else if (this.valueBreadcrumbs.length > 1 && (!this.restrictedToFolder || this.valueBreadcrumbs.length > this.restrictedToFolder.split('/').length)) {
          const newFolderLocation = this.valueBreadcrumbs[this.valueBreadcrumbs.length - 2];
          const newTextValue = this.valueBreadcrumbs[this.valueBreadcrumbs.length - 1].folderName;
          this.valueBreadcrumbs = this.createBreadCrumbs(newFolderLocation.path.split('/'));
          this.textControl.setValue(newTextValue + this.textControl.value);
          this.getFocusableElement<HTMLInputElement>().setSelectionRange(newTextValue.length, newTextValue.length);
        }
      }
    }
  }

  /** Handles click event; moves current parent folder to one clicked. */
  onItemClicked(item: PathBreadcrumb) {
    this.updateValue(item.path + '/' + this.fileName())
  }

  /** Gives focus to the file path control. */
  focus() {
    this.textInputElementRef.nativeElement.focus();
  }

  /** Required by CustomInputBase */
  getDefaultPlaceholder(): string {
    return '';
  }

  /** Required by CustomInputBase */
  getFocusableElementRef(): ElementRef<HTMLElement> {
    return this.textInputElementRef;
  }

  /** Required by MatFormFieldControl */
  onContainerClick(event: MouseEvent): void {
    this.focus();
  }

  private checkForTrailingSlashAndSetBreadcrumbs(newValue: string) {
    const newValueSplit = newValue?.split('/') ?? [''];
    const newFileName = newValueSplit.pop();
    this.valueBreadcrumbs = this.createBreadCrumbs(newValueSplit);
    this.textControl.setValue(newFileName);
  }

  private collapseSlashes(pathStr: string): string {
    let newPathStr: string = '';
    let slashFlag: boolean = false;

    for (let i = 0; i < pathStr.length; i++) {
      const testPathChar = pathStr[i];
      if (!slashFlag) {
        if (testPathChar === '/') {
          slashFlag = true;
        }
        newPathStr += testPathChar;
      } else {
        if (testPathChar !== '/') {
          slashFlag = false;
          newPathStr += testPathChar;
        }
      }
    }
    if (newPathStr[0] === '/') {
      return newPathStr.slice(1);
    }
    return newPathStr;
  }

  private createBreadCrumbs(pathParts: string[]): PathBreadcrumb[] {
    const breadCrumbs: PathBreadcrumb[] = [];
    const rootParts = this.restrictedToFolder ? this.restrictedToFolder.split('/') : [];
    let currentStr = '';
    let partOfRestrictedToFolderCrumb = true;
    for (let i = 0; i < pathParts.length; i++) {
      currentStr += pathParts[i].substr(0, pathParts[i].length);
      partOfRestrictedToFolderCrumb = partOfRestrictedToFolderCrumb ? rootParts && (pathParts[i] === rootParts[i]) : false;
      breadCrumbs.push({ folderName: pathParts[i], path: currentStr, partOfRestrictedToFolderCrumb, endOfRestrictedToFolderCrumb: currentStr === this.restrictedToFolder ? true : false });
      currentStr += '/';
    }
    return breadCrumbs;
  }

  private removeSpaces(pathParts: string[]): string[] {
    for (let i = 0; i < pathParts.length; i++) {
      if (!pathParts[i].trim().length && i !== pathParts.length - 1) {
        pathParts.splice(i, 1);
        i--;
      }
    }
    return pathParts;
  }

  onCancelClicked() {
    this.popup.close();
  }

  onAcceptClicked() {
    this.updateValue(this.projectFilesTree.selectedDirectoryPath + '/' + this.fileName())
    this.popup.close();
  }

  /** Sets the value and path properties on the file path  picker and notifies Angular Forms of the changes.  */
  private setValueFromUI(value: string) {
    this.value = value;
    this.onChange(this.value); // So Angular Forms know this control's value has changed
    this.onTouched(); // So Angular Forms know this control has been touched
  }

  /**
  Gets the file name of the current path.
  */
  private fileName(): string {
    return this.isFolder ? '' : this.value?.split('/').pop() ?? '';
  }

  /**
  Updates the control value and appearance.
  */
  private updateValue(path: string) {
    this.checkForTrailingSlashAndSetBreadcrumbs(path);
    this.setValueFromUI(path);
  }

  public openPopup() {
    this.popupTrigger.open();
  }
}
