import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VersionApiService } from '@portal-core/general/services/version-api.service';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  constructor(private versionApiService: VersionApiService) { }

  getVersionAndBuildDate$(): Observable<string> {
    return this.versionApiService.getVersionAndBuildDate$();
  }
}
