import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec } from 'prosemirror-model';

export class MadCapProxySchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapbodyproxy: {
      group: 'block',
      attrs: {
        'MadCap:autonum': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:bodyProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['MadCap:autonum']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-body-proxy' }, 'Topic Body Proxy']; },
      tagName: 'MadCap:bodyProxy',
      isVoid: true
    },

    madcapbreadcrumbsproxy: {
      group: 'block',
      attrs: {
        'MadCap:autonum': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:breadcrumbsProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['MadCap:autonum']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-breadcrumbs-proxy' }, 'Breadcrumbs Proxy']; },
      tagName: 'MadCap:breadcrumbsProxy',
      isVoid: true
    },

    madcapcentralaccountproxy: {
      group: 'block',
      attrs: {
        'data-mc-skin': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:centralAccountProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['data-mc-skin']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-central-account-proxy' }, 'Central Account Proxy']; },
      tagName: 'MadCap:centralAccountProxy',
      isVoid: true
    },

    madcaptopictoolbarproxy: {
      group: 'block',
      attrs: {
        'data-mc-skin': { default: undefined },
        'MadCap:autonum': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:topicToolbarProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['MadCap:autonum', 'data-mc-skin']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-topic-toolbar-proxy' }, 'Topic Toolbar Proxy']; },
      tagName: 'MadCap:topicToolbarProxy',
      isVoid: true
    },

    madcapminitocproxy: {
      group: 'block',
      attrs: {
        'MadCap:autonum': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:miniTocProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['MadCap:autonum']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-mini-toc-proxy' }, 'Mini-TOC Proxy']; },
      tagName: 'MadCap:miniTocProxy',
      isVoid: true
    },

    madcaptocproxy: {
      group: 'block',
      attrs: {
        'MadCap:autonum': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:tocProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['MadCap:autonum']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-toc-proxy' }, 'Output TOC Proxy']; },
      tagName: 'MadCap:tocProxy',
      isVoid: true
    },

    madcapindexproxy: {
      group: 'block',
      attrs: {
        'MadCap:autonum': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:indexProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['MadCap:autonum']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-index-proxy' }, 'Output Index Proxy']; },
      tagName: 'MadCap:indexProxy',
      isVoid: true
    },

    madcaplistofproxy: {
      group: 'block',
      attrs: {
        'MadCap:autonum': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:listOfProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['MadCap:autonum']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-list-of-proxy' }, 'List-of Proxy']; },
      tagName: 'MadCap:listOfProxy',
      isVoid: true
    },

    madcapconceptsproxy: {
      group: 'block',
      attrs: {
        'MadCap:autonum': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:conceptsProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['MadCap:autonum']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-concepts-proxy' }, 'Concepts Proxy']; },
      tagName: 'MadCap:conceptsProxy',
      isVoid: true
    },

    madcapglossaryproxy: {
      group: 'block',
      attrs: {
        'MadCap:autonum': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:glossaryProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['MadCap:autonum']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-glossary-proxy' }, 'Output Glossary Proxy']; },
      tagName: 'MadCap:glossaryProxy',
      isVoid: true
    },

    madcaprelationshipsproxy: {
      group: 'block',
      attrs: {
        'MadCap:autonum': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:relationshipsProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['MadCap:autonum']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-relationships-proxy' }, 'Relationships Proxy']; },
      tagName: 'MadCap:relationshipsProxy',
      isVoid: true
    },

    madcapendnotesproxy: {
      group: 'block',
      attrs: {
        'MadCap:autonum': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:endnotesProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['MadCap:autonum']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-endnotes-proxy' }, 'Endnotes Proxy']; },
      tagName: 'MadCap:endnotesProxy',
      isVoid: true
    },

    madcapsearchbarproxy: {
      group: 'block',
      attrs: {
        'data-mc-skin': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:searchBarProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['data-mc-skin']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-search-bar-proxy' }, 'Search Bar Proxy']; },
      tagName: 'MadCap:searchBarProxy',
      isVoid: true
    },

    madcapmenuproxy: {
      group: 'block',
      attrs: {
        'data-mc-skin': { default: undefined },
        'mc-linked-toc': { default: undefined },
      },
      parseDOM: [{
        tag: 'MadCap\\:menuProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['data-mc-skin', 'mc-linked-toc']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-menu-proxy' }, 'Menu Proxy']; },
      tagName: 'MadCap:menuProxy',
      isVoid: true
    },

    madcapsearchresultsproxy: {
      group: 'block',
      attrs: {
        'data-mc-skin': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:searchResultsProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['data-mc-skin']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-search-results-proxy' }, 'Search Results Proxy']; },
      tagName: 'MadCap:searchResultsProxy',
      isVoid: true
    },

    madcapelearningtoolbarproxy: {
      group: 'block',
      attrs: {
        'data-mc-skin': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:eLearningToolbarProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['data-mc-skin']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-e-learning-toolbar-proxy' }, 'eLearning Toolbar Proxy']; },
      tagName: 'MadCap:eLearningToolbarProxy',
      isVoid: true
    },

    madcaptestresultsproxy: {
      group: 'block',
      attrs: {
        'data-mc-skin': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:testResultsProxy',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['data-mc-skin']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-test-results-proxy' }, 'Test Results Proxy']; },
      tagName: 'MadCap:testResultsProxy',
      isVoid: true
    }
  };
}
