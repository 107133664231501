import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavbarHelpButtonModule } from '@portal-core/layout/components/navbar-help-button/navbar-help-button.module';
import { AppNavbarLoggedOutLaunchPadComponent } from './app-navbar-logged-out-launch-pad.component';

@NgModule({
  declarations: [
    AppNavbarLoggedOutLaunchPadComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    NavbarHelpButtonModule
  ],
  exports: [
    AppNavbarLoggedOutLaunchPadComponent
  ]
})
export class AppNavbarLoggedOutLaunchPadModule { }
