import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec } from 'prosemirror-model';

export class IFrameSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    iframe: {
      group: 'inline',
      inline: true,
      attrs: {
        src: { default: undefined },
      },
      parseDOM: [{
        tag: 'iframe',
        preserveWhitespace: true,
        getAttrs: function (dom: HTMLElement) {
          return {
            ...getSchemaAttrs(dom, ['src'])
          };
        }
      }],
      toDOM() { return ['div', { class: 'mc-pm-iframe mc-pm-iframe-inline' }, 'Iframe']; },
      tagName: 'iframe'
    }
  };
}
