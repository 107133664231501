import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { cache } from '@common/util/cache.operator';
import { switchMapKeep } from '@common/util/switch-map-keep.operator';
import { CoreService } from '@portal-client/core/core.service';
import { ApiService } from '@portal-core/auth/services/api.service';
import { AuthUrlService } from '@portal-core/auth/services/auth-url.service';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { LicenseProfileDialogComponent, LicenseProfileDialogData } from '@portal-core/licenses/components/license-profile-dialog/license-profile-dialog.component';
import { LicenseActivationType } from '@portal-core/licenses/enums/license-activation-type.enum';
import { LicenseType } from '@portal-core/licenses/enums/license-type.enum';
import { License } from '@portal-core/licenses/models/license.model';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { Observable, combineLatest, filter, map, of, switchMap } from 'rxjs';

interface LicenseItem {
  href: string;
  isCurrentLicense: boolean;
  license: License;
}

@Component({
  selector: 'mc-app-license-dropdown',
  templateUrl: './app-license-dropdown.component.html',
  styleUrls: ['./app-license-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLicenseDropdownComponent implements OnInit {
  @Input() showSettings?: boolean = true;

  activeLicenses$: Observable<LicenseItem[]>;
  billingFailedLicenses$: Observable<LicenseItem[]>;
  currentLicense$: Observable<License>;
  deactivatedLicenses$: Observable<LicenseItem[]>;
  expiredLicenses$: Observable<LicenseItem[]>;
  expiredTrials$: Observable<LicenseItem[]>;
  hasActiveAndNonActiveLicenses$: Observable<boolean>;
  hasActiveLicenses$: Observable<boolean>;
  hasBillingFailedLicenses$: Observable<boolean>;
  hasCurrentLicense$: Observable<boolean>;
  hasDeactivatedLicenses$: Observable<boolean>;
  hasExpiredLicenses$: Observable<boolean>;
  hasExpiredTrials$: Observable<boolean>;
  licenses$: Observable<LicenseItem[]>;
  menuClass$: Observable<string>;
  loginPortalUrl$: Observable<string>;
  showBillingFailedLicenses: boolean = false;
  showDeactivatedLicenses: boolean = false;
  showExpiredLicenses: boolean = false;
  showExpiredTrials: boolean = false;
  subdomainUrlDisplayText$: Observable<string>;

  constructor(
    private dialog: MatDialog,
    private coreService: CoreService,
    private licensesService: LicensesService,
    private apiService: ApiService,
    private authService: AuthService,
    private authUrlService: AuthUrlService
  ) { }

  ngOnInit() {
    this.licenses$ = this.coreService.getLicenseId$().pipe(
      filter(currentLicenseId => typeof currentLicenseId === 'number'),
      switchMapKeep(() => this.licensesService.getLicensesForCurrentUser$()),
      map(([currentLicenseId, licenses]) => {
        return licenses.map(license => {
          return {
            href: this.authUrlService.buildSubdomainUrl(license.VanityBaseUrl),
            isCurrentLicense: license.Id === currentLicenseId,
            license
          };
        });
      }),
      cache()
    );

    this.currentLicense$ = this.coreService.getLicenseId$().pipe(
      switchMap(licenseId => typeof licenseId === 'number' ? this.getLicenseById$(licenseId) : of(null)),
      cache()
    );

    this.hasCurrentLicense$ = this.coreService.getLicenseId$().pipe(
      map(licenseId => typeof licenseId === 'number')
    );

    this.subdomainUrlDisplayText$ = this.currentLicense$.pipe(
      map(license => license ? this.authUrlService.buildSubdomainUrlDisplayText(license.VanityBaseUrl) : null)
    );

    this.loginPortalUrl$ = this.apiService.centralInstance$.pipe(
      map(centralInstance => centralInstance?.InstanceCode ? this.authUrlService.getLoginPortalUrl(['instance', centralInstance.InstanceCode, 'licenses']) : this.authUrlService.getLoginPortalUrl())
    );

    this.menuClass$ = this.hasCurrentLicense$.pipe(
      map(hasCurrentLicense => {
        let classes = 'mc-menu mc-navbar-menu mc-app-license-dropdown-menu-panel';
        if (!hasCurrentLicense) {
          classes += ' mc-navbar-button-dropdown-menu';
        }

        return classes;
      })
    );

    // Create observables for the license dropdown
    this.activeLicenses$ = this.licenses$.pipe(
      map(licenses => {
        if (licenses) {
          return licenses.filter(item => {
            return item.license.LicenseActivationStatus === LicenseActivationType.Active;
          }).sort((itemA, itemB) => {
            return itemA.license.KeyLabel.localeCompare(itemB.license.KeyLabel);
          });
        }
      }),
      cache()
    );

    this.expiredLicenses$ = this.licenses$.pipe(
      map(licenses => {
        if (licenses) {
          return licenses.filter(item => {
            return item.license.LicenseType !== LicenseType.Trial && (item.license.LicenseActivationStatus === LicenseActivationType.Inactive ||
              item.license.LicenseActivationStatus === LicenseActivationType.Pending);
          }).sort((itemA, itemB) => {
            return itemA.license.KeyLabel.localeCompare(itemB.license.KeyLabel);
          });
        }
      }),
      cache()
    );

    this.expiredTrials$ = this.licenses$.pipe(
      map(licenses => {
        if (licenses) {
          return licenses.filter(item => {
            return item.license.LicenseType === LicenseType.Trial && (item.license.LicenseActivationStatus === LicenseActivationType.Inactive ||
              item.license.LicenseActivationStatus === LicenseActivationType.Pending);
          }).sort((itemA, itemB) => {
            return itemA.license.KeyLabel.localeCompare(itemB.license.KeyLabel);
          });
        }
      }),
      cache()
    );

    this.deactivatedLicenses$ = this.licenses$.pipe(
      map(licenses => {
        if (licenses) {
          return licenses.filter(item => {
            return item.license.LicenseType !== LicenseType.Trial && item.license.LicenseActivationStatus === LicenseActivationType.Deactivated;
          }).sort((itemA, itemB) => {
            return itemA.license.KeyLabel.localeCompare(itemB.license.KeyLabel);
          });
        }
      })
    );

    this.billingFailedLicenses$ = this.licenses$.pipe(
      map(licenses => {
        if (licenses) {
          return licenses.filter(item => {
            return item.license.LicenseActivationStatus === LicenseActivationType.BillingFailed;
          }).sort((itemA, itemB) => {
            return itemA.license.KeyLabel.localeCompare(itemB.license.KeyLabel);
          });
        }
      }),
      cache()
    );

    this.hasActiveLicenses$ = this.activeLicenses$.pipe(
      map(licenses => licenses && licenses.length > 0),
      cache()
    );

    this.hasExpiredLicenses$ = this.expiredLicenses$.pipe(
      map(licenses => licenses && licenses.length > 0),
      cache()
    );

    this.hasExpiredTrials$ = this.expiredTrials$.pipe(
      map(licenses => licenses && licenses.length > 0),
      cache()
    );

    this.hasDeactivatedLicenses$ = this.deactivatedLicenses$.pipe(
      map(licenses => licenses && licenses.length > 0),
      cache()
    );

    this.hasBillingFailedLicenses$ = this.billingFailedLicenses$.pipe(
      map(licenses => licenses && licenses.length > 0),
      cache()
    );

    this.hasActiveAndNonActiveLicenses$ = combineLatest([
      this.hasActiveLicenses$,
      this.hasExpiredLicenses$,
      this.hasExpiredTrials$,
      this.hasDeactivatedLicenses$,
      this.hasBillingFailedLicenses$
    ]).pipe(
      map(([hasActiveLicenses, hasExpiredLicenses, hasExpiredTrials, hasDeactivatedLicenses, hasBillingFailedLicenses]) => {
        return hasActiveLicenses && (hasExpiredLicenses || hasExpiredTrials || hasDeactivatedLicenses || hasBillingFailedLicenses);
      })
    );
  }

  onSettingsClicked() {
    this.dialog.open<LicenseProfileDialogComponent, LicenseProfileDialogData>(LicenseProfileDialogComponent, {
      ...LicenseProfileDialogComponent.DialogConfig,
      data: {
        licenseId: this.coreService.getLicenseId(),
        licenseUserId: this.coreService.getLicenseUserId()
      }
    });
  }

  onSignOutClicked() {
    this.authService.logout();
  }

  getLicenseById$(licenseId: number): Observable<License> {
    return this.licensesService.getItemById$(licenseId, { allowApiRequest: false });
  }
}
