import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlareCodePipe } from '@portal-core/project-files/pipes/flare-code/flare-code.pipe';

@NgModule({
    declarations: [
        FlareCodePipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        FlareCodePipe
    ]
})
export class FlareCodePipeModule { }