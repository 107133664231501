import { Injectable, Inject } from '@angular/core';
import { NAVIGATOR } from '@portal-core/util/navigator.provider';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  isIOS: boolean;
  isMac: boolean;
  isWindows: boolean;

  constructor(@Inject(NAVIGATOR) navigator: Navigator) {
    this.isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);
    this.isMac = /(Mac)/i.test(navigator.platform);
    this.isWindows = /(Win)/i.test(navigator.platform);
  }

  isTouchDevice(): boolean {
    return ('ontouchstart' in window) || navigator.maxTouchPoints > 0;
  }
}
