import { getAttrs } from '@common/html/util/dom';
import { toBoolean, toBooleanString } from '@common/util/bool';
import { forEach, union } from 'lodash';
import { DOMOutputSpec } from 'prosemirror-model';

export const defaultParseRulePriority = 50;

// This is just a more specific type for the array in a DOMOutputSpec
export type DOMOutputSpecArray = Array<string | 0 | DOMOutputSpec | { [attr: string]: string }>;

export interface DOMOutputSpecModifications {
  attrs?: {
    add?: { [name: string]: string };
    set?: { [name: string]: string };
  };
  content?: string;
  tag?: string;
}

// Returns true if value is a DOMOutputSpec dom element
export function isDomElement(value: any): boolean {
  return !!value.nodeType; // This is the same test that prosemirror uses internally
}

// Returns true if value is a DOMOutputSpec
export function isDomOutputSpec(value: any): boolean {
  return typeof value === 'string' || Array.isArray(value) || isDomElement(value);
}

// Returns true if value is a DOMOutputSpec attrs object
export function isDomOutputAttrs(value: any): boolean {
  return value && typeof value === 'object' && !value.nodeType && !Array.isArray(value);
}

export function joinAttrValues(valueA: string, valueB: string): string {
  const valuesA = (valueA || '').split(/\s+/);
  const valuesB = (valueB || '').split(/\s+/);

  return union(valuesA, valuesB).join(' ').trim();
}

// Modifies a DOMOutputSpec array with the given mods
export function modifyDomOutputArray(arr: DOMOutputSpecArray, mods: DOMOutputSpecModifications): DOMOutputSpecArray {
  // If the tag should be modified
  if (mods.tag) {
    arr[0] = mods.tag;
  }

  // If the attrs should be modified
  if (mods.attrs) {
    let attrs = arr[1];

    // If the second element is not an object
    if (!isDomOutputAttrs(attrs)) {
      // Then splice in an object to be the attrs
      attrs = {};
      arr.splice(1, 0, attrs);
    }

    // If the attrs should be set on the array
    if (mods.attrs.set) {
      arr[1] = mods.attrs.set;
    }

    // If there are attrs to add to the array
    if (mods.attrs.add) {
      // Copy the attrs over
      forEach(mods.attrs.add, (value, name) => {
        // Join class values
        if (name === 'class') {
          attrs[name] = joinAttrValues(attrs[name], value);
        } else {
          attrs[name] = value;
        }
      });
    }
  }

  // If the content should be modified
  if (mods.content) {
    // Find the content index. It should be 0 (content hole) or a DOMOutputSpec
    let contentIndex = arr.findIndex((value, index) => {
      return index > 0 && (value === 0 || isDomOutputSpec(value));
    });
    if (contentIndex === -1) {
      contentIndex = arr.length;
    }

    arr[contentIndex] = mods.content;
  }

  return arr;
}

// Modifies a DOMOutputSpec dom element with the given mods
export function modifyDomOutputElement(element: Element, mods: DOMOutputSpecModifications): Element {
  // If the attrs should be modified
  if (mods.attrs) {
    // If the attrs should be set on the element
    if (mods.attrs.set) {
      // Remove all the existing attributes on the element
      for (let i = 0, length = element.attributes.length; i < length; i += 1) {
        const attr = element.attributes[i];
        element.removeAttribute(attr.nodeName);
      }

      // Set the new attrs on the element
      forEach(mods.attrs.set, (value, name) => {
        element.setAttribute(name, value);
      });
    }

    // If there are attrs to add to the element
    if (mods.attrs.add) {
      // Set the attrs on the element
      forEach(mods.attrs.add, (value, name) => {
        if (name === 'class') {
          // Update the classList
          value.split(' ').forEach(val => element.classList.add(val));
        } else {
          element.setAttribute(name, value);
        }
      });
    }
  }

  // If the content should be modified
  if (mods.content) {
    element.innerHTML = mods.content;
  }

  return element;
}

// Modifies a DOMOutputSpec with the given mods
export function modifyDomOutputSpec(spec: DOMOutputSpec, mods: DOMOutputSpecModifications) {
  if (Array.isArray(spec)) {
    return modifyDomOutputArray(spec as DOMOutputSpecArray, mods);
  } else if (isDomElement(spec)) {
    return modifyDomOutputElement(spec as Element, mods);
  }

  return spec;
}

/**
 * Converts a string, number, or boolean into a boolean for node attributes when parsing the DOM.
 * String values of true and 1 return true. The check is case-insensitive.
 * Any other string value returns false.
 * A number value of zero returns false.
 * Any other number value returns true.
 * A boolean value is simply returned.
 * A nullish value returns null.
 * @param value The string/number/boolean value to convert.
 * @returns Returns true or false depending on thee input.
 */
export function toAttributeBoolean(value: string | number | boolean): boolean {
  return toBoolean(value, null);
}

/**
 * Converts a string, number, or boolean into a boolean string for node attributes when converting to the DOM.
 * If value is a boolean then the string returned is all lowercase and either 'true' or 'false'.
 * If value is not a boolean then undefined is returned.
 * @param value The string/number/boolean value to convert.
 * @param startCase Whether the boolean string should use starting case or not (True/False). Defaults to false.
 * @returns A string version of boolean value.
 */
export function toAttributeBooleanString(value: string | number | boolean): string {
  value = toBoolean(value, null);
  return typeof value === 'boolean' ? toBooleanString(value, false) : undefined;
}

/**
 * Returns an element's attribute values for a set of names.
 * Performs a case-insensitive check for the attribute names.
 * @param element The element to get the attributes from.
 * @param attrs The list of attribute names to get.
 * @returns A dictionary of attribute name/value pairs.
 */
export function getSchemaAttrs(element: Element, attrs: string[]): Dictionary<string> {
  return getAttrs(element, attrs);
}

/**
 * Returns an element's attribute value.
 * Performs a case-insensitive check for the attribute name.
 * @param element The element to get the attribute from.
 * @param attr The attribute name to get.
 * @returns The attribute's value.
 */
export function getSchemaAttr(element: Element, attr: string): string {
  return getAttrs(element, [attr])[attr];
}

/**
 * Returns true if an element has an attribute.
 * Performs a case-insensitive check for the attribute name.
 * @param element The element to check for the attribute.
 * @param attr The attribute name to check.
 * @returns `true` if the element has the attribute, `false` otherwise.
 */
export function hasSchemaAttr(element: Element, attr: string): boolean {
  return element.hasAttribute(attr) || element.hasAttribute(attr.toLowerCase());
}
