import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SystemMessageType } from '@portal-core/system-messages/enums/system-message-type.enum';

@Component({
  selector: 'mc-system-message-card',
  templateUrl: './system-message-card.component.html',
  styleUrls: ['./system-message-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemMessageCardComponent implements OnInit {
  @Input() messageType = SystemMessageType.WhatsNew;
  @Input() title: string;
  @Input() message: string;

  @Output() cardClick = new EventEmitter();
  @Output() closeClick = new EventEmitter();

  icon: string;

  @Input() clickCardCallback = () => true;
  @Input() clickCloseCallback = () => false;

  constructor() { }

  ngOnInit() {
    this.icon = this.messageType === SystemMessageType.WhatsNew
      ? 'icon-gift'
      : 'icon-simon';
  }

  getMessageColorClassName(): string {
    return this.messageType === SystemMessageType.WhatsNew
      ? 'mc-whats-new-card'
      : 'mc-system-message-card';
  }

  onCardClick(): any {
    this.cardClick.emit(true);
  }

  onCloseClick(): any {
    this.closeClick.emit(false);
  }
}
