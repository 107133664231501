import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { Language } from '@portal-core/languages/models/language.model';

@CollectionStateSelectors({
  stateType: LanguagesState
})

@State<LanguagesState>({
  name: LanguagesState.source,
})

@Injectable()
export class LanguagesState extends CollectionStateBase {
  static source: string = 'Languages';

  getSource(): string {
    return LanguagesState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LanguagesDataService extends CollectionDataServiceBase<Language> {
  constructor(protected store: Store) {
    super(store, LanguagesState);
  }
}
