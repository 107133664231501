import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { CommitViewFormat } from '@portal-core/commits/enums/commit-view-format.enum';
import { Diff } from '@portal-core/commits/models/diff.model';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { html } from 'diff2html';
import { OutputFormatType } from 'diff2html/lib/types';
import { BehaviorSubject, Observable, combineLatest, of, switchMap } from 'rxjs';

const LargeDiffSizeBytes: number = 5000;

@Component({
  selector: 'mc-project-commit-diff',
  templateUrl: './project-commit-diff.component.html',
  styleUrls: ['./project-commit-diff.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectCommitDiffComponent implements OnInit {
  @Input() autoLoad: string;
  @Input() diff: Diff;
  @Input() view: string = CommitViewFormat.Vertical;
  @Input() disabledExpand: boolean = false;

  @InputObservable('diff') diff$: Observable<Diff>;
  @PropertyObservable('view') view$: Observable<CommitViewFormat>;

  @ViewChild('accordion', { static: true }) accordionRef: MatAccordion;

  diffData: string;
  diffDOM$: Observable<string>;
  expended: boolean;
  showDiffLoadRequest: boolean;
  showFilePath: boolean;
  showHeaderTitle: boolean;
  showNoDiffMessage: boolean;
  retry$: BehaviorSubject<string> = new BehaviorSubject('');

  ngOnInit() {
    this.expended = this.expended ? this.expended : true;
    this.showFilePath = this.showFilePath ? this.showFilePath : true;
    this.showHeaderTitle = !(typeof this.diff === 'string');
    if (this.autoLoad !== 'none') {
      this.diffDOM$ = combineLatest([
        this.diff$,
        this.view$,
        this.retry$
      ]).pipe(
        switchMap(([diff, view]) => {
          if (diff) {
            this.diffData = typeof diff === 'string' ? diff : diff.diff;
            this.loadNow();
            return of(html(this.diffData, { drawFileList: false, outputFormat: view as OutputFormatType }));
          } else {
            return of(null);
          }
        })
      );
    }
  }

  checkForMoved(oldPath: string, newPath: string): boolean {
    if (!oldPath || !newPath) return false;
    const oldPathArr = oldPath.split('/');
    const newPathArr = newPath.split('/');
    /* get rid of file name. */
    oldPathArr.pop();
    newPathArr.pop();
    /* compare */
    return oldPathArr.join('/') !== newPathArr.join('/');
  }

  loadNow() {
    // Determine if the diff should be loaded now

    let loadNow = false;
    if (this.autoLoad === 'all') {
      loadNow = true;
    } else if (this.autoLoad === 'small') {
      loadNow = this.diffData.length < LargeDiffSizeBytes;
    } else if (this.autoLoad === 'none') {
      this.showNoDiffMessage = true;
      loadNow = false;
    }

    /* no diff content */
    if (this.diffData?.length === 0) {
      this.showNoDiffMessage = true;
      this.showDiffLoadRequest = false;
    } else {
      if (loadNow) {
        this.showDiffLoadRequest = false;
        this.showNoDiffMessage = false;
      } else if (this.autoLoad !== 'none') {
        this.showDiffLoadRequest = true;
      }
    }
  }

  onViewSwitchClicked(outputFormat: string, e: MouseEvent) {
    this.view = outputFormat;
    e.stopPropagation();
  }

  expand() {
    this.accordionRef.openAll();
  }

  collapse() {
    this.accordionRef.closeAll();
  }

  setCommitView(view: CommitViewFormat) {
    this.view = view;
  }

  onRetryLoadingDiffClicked() {
    this.retry$.next('retry');
  }
}
