import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { nodeToFlareXML } from '@common/flare/util/prosemirror-flare-xml';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';

export class UnknownSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    unknown: {
      group: 'block',
      attrs: {
        content: { default: undefined },
        tagName: { default: undefined }
      },
      parseDOM: [{
        tag: '*',
        priority: 0,
        getAttrs(dom: HTMLElement) {
          return {
            content: dom.outerHTML,
            tagName: dom.nodeName.includes(':') ? dom.nodeName : dom.nodeName.toLowerCase()
          };
        }
      }],
      toDOM() {
        return ['div', { class: 'unknown-element icon-star' }];
      },
      toFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
        return nodeToFlareXML(node, options, null, {
          xml: node.attrs.content
        });
      },
      toDisplayName(node) {
        return node.attrs.tagName ?? 'unknown';
      }
    },

    unknowninline: {
      group: 'inline',
      inline: true,
      attrs: {
        content: { default: undefined },
        tagName: { default: undefined }
      },
      parseDOM: [{
        tag: '*',
        priority: 1,
        context: 'block/',
        getAttrs(dom: HTMLElement) {
          return {
            content: dom.outerHTML,
            tagName: dom.nodeName.includes(':') ? dom.nodeName : dom.nodeName.toLowerCase()
          };
        }
      }],
      toDOM() {
        return ['span', { class: 'unknown-inline-element icon-star' }];
      },
      toFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
        return nodeToFlareXML(node, options, null, {
          xml: node.attrs.content
        });
      },
      toDisplayName(node) {
        return node.attrs.tagName ?? 'unknown';
      }
    }
  };
}
