import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProjectFilesAIDialogModule } from '@portal-core/project-files/components/project-files-ai-dialog/project-files-ai-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    ProjectFilesAIDialogModule
  ]
})
export class ProjectFilesServicesModule { }
