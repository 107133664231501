import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersState } from '@portal-core/license-users/services/license-users-data.service';
import { License } from '@portal-core/licenses/models/license.model';
import { LicensesState } from '@portal-core/licenses/services/licenses-data.service';
import { Observable, map } from 'rxjs';

type SettingByName = (name: string) => any;

// CoreStateModel
export class CoreStateModel {
  licenseId: number;
  licenseUserId: number;
  settings: Dictionary;
  versionAndBuildDate: string;
}

// Actions
export class Reset {
  static readonly type: string = '[Core] Reset';
}

export class SetLicenseUserId {
  static readonly type: string = '[Core] SetLicenseUserId';
  constructor(public payload: { licenseUserId: number }) { }
}

export class SetLicenseId {
  static readonly type: string = '[Core] SetLicenseId';
  constructor(public payload: { licenseId: number }) { }
}

export class SetSettingByName {
  static readonly type: string = '[Core] SetSettingByName';
  constructor(public payload: { name: string, value: any }) { }
}

export class SetVersionAndBuildDate {
  static readonly type: string = '[Core] SetVersionAndBuildDate';
  constructor(public payload: { versionAndBuildDate: string }) { }
}

// CoreState
@State<CoreStateModel>({
  name: 'coreState',
  defaults: {
    licenseId: null,
    licenseUserId: null,
    settings: {},
    versionAndBuildDate: null
  }
})
@Injectable()
export class CoreState {
  @Selector()
  static licenseId(state: CoreStateModel): number { return state.licenseId; }

  @Selector()
  static licenseUserId(state: CoreStateModel): number { return state.licenseUserId; }

  @Selector()
  static settings(state: CoreStateModel): Dictionary { return state.settings; }

  @Selector()
  static settingByName(state: CoreStateModel): SettingByName {
    return (name: string) => {
      return state.settings[name];
    };
  }

  @Selector()
  static versionAndBuildDate(state: CoreStateModel): string { return state.versionAndBuildDate; }

  @Action(Reset)
  reset(ctx: StateContext<CoreStateModel>, action: Reset) {
    ctx.patchState({
      licenseId: null,
      licenseUserId: null
    });
  }

  @Action(SetLicenseId)
  setLicenseId(ctx: StateContext<CoreStateModel>, action: SetLicenseId) {
    ctx.patchState({
      licenseId: action.payload.licenseId
    });
  }

  @Action(SetLicenseUserId)
  setLicenseUserId(ctx: StateContext<CoreStateModel>, action: SetLicenseUserId) {
    ctx.patchState({
      licenseUserId: action.payload.licenseUserId
    });
  }

  @Action(SetSettingByName)
  setSettingByName(ctx: StateContext<CoreStateModel>, action: SetSettingByName) {
    ctx.patchState({
      settings: {
        ...ctx.getState().settings,
        [action.payload.name]: action.payload.value
      }
    });
  }

  @Action(SetVersionAndBuildDate)
  setVersionAndBuildDate(ctx: StateContext<CoreStateModel>, action: SetVersionAndBuildDate) {
    ctx.patchState({
      versionAndBuildDate: action.payload.versionAndBuildDate
    });
  }
}

// CoreDataService Implementation
@Injectable({
  providedIn: 'root'
})
export class CoreDataService {
  constructor(private store: Store) { }

  // Meta Selectors
  @Selector([CoreState.licenseId, LicensesState.itemsSelector])
  static license(licenseId: number, licenses: LicenseUser[]) {
    if (licenses && typeof licenseId === 'number') {
      return licenses[licenseId];
    }
  }

  @Selector([CoreState.licenseUserId, LicenseUsersState.itemsSelector])
  static licenseUser(licenseUserId: number, licenseUsers: LicenseUser[]) {
    if (licenseUsers && typeof licenseUserId === 'number') {
      return licenseUsers[licenseUserId];
    }
  }

  reset$(): Observable<any> {
    return this.store.dispatch(new Reset());
  }

  // License Id
  getLicenseId(): number {
    return this.store.selectSnapshot(CoreState.licenseId);
  }

  getLicenseId$(): Observable<number> {
    return this.store.select(CoreState.licenseId);
  }

  setLicenseId$(licenseId: number): Observable<any> {
    return this.store.dispatch(new SetLicenseId({ licenseId }));
  }

  // License
  getLicense(): License {
    return this.store.selectSnapshot(CoreDataService.license);
  }

  getLicense$(): Observable<License> {
    return this.store.select(CoreDataService.license);
  }

  // License User Id
  getLicenseUserId(): number {
    return this.store.selectSnapshot(CoreState.licenseUserId);
  }

  getLicenseUserId$(): Observable<number> {
    return this.store.select(CoreState.licenseUserId);
  }

  setLicenseUserId$(licenseUserId: number): Observable<any> {
    return this.store.dispatch(new SetLicenseUserId({ licenseUserId }));
  }

  // LicenseUser
  getLicenseUser(): LicenseUser {
    return this.store.selectSnapshot(CoreDataService.licenseUser);
  }

  getLicenseUser$(): Observable<LicenseUser> {
    return this.store.select(CoreDataService.licenseUser);
  }

  // Settings
  getSettingByName(name: string): any {
    return this.store.selectSnapshot(CoreState.settings)[name];
  }

  getSettingByName$(name: string): Observable<any> {
    return this.store.select(CoreState.settingByName).pipe(
      map(fn => fn(name))
    );
  }

  setSettingByName$(name: string, value: any): Observable<any> {
    return this.store.dispatch(new SetSettingByName({ name, value }));
  }

  // Version and Build Date
  getVersionAndBuildDate(): string {
    return this.store.selectSnapshot(CoreState.versionAndBuildDate);
  }

  getVersionAndBuildDate$(): Observable<string> {
    return this.store.select(CoreState.versionAndBuildDate);
  }

  setVersionAndBuildDate$(versionAndBuildDate: string): Observable<any> {
    return this.store.dispatch(new SetVersionAndBuildDate({ versionAndBuildDate }));
  }
}
