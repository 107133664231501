import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ProcessNotificationState } from './process-notification-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([ProcessNotificationState])
  ]
})
export class ProcessNotificationServicesModule { }
