import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { TranslationQuote } from '@portal-core/translation-quotes/models/translation-quote.model';

@CollectionStateSelectors({
  stateType: TranslationQuotesState
})
@State<TranslationQuotesState>({
  name: TranslationQuotesState.source
})
@Injectable()
export class TranslationQuotesState extends CollectionStateBase {
  static source: string = 'TranslationQuotes';
  getSource(): string {
    return TranslationQuotesState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TranslationQuotesDataService extends CollectionDataServiceBase<TranslationQuote> {
  constructor(protected store: Store) {
    super(store, TranslationQuotesState);
  }
}
