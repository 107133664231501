import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';
import { SchemaPlugin } from '../../prosemirror/model/schema-plugin';

export class ImageSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    image: {
      inline: true,
      attrs: {
        src: { default: undefined },
        alt: { default: undefined },
        title: { default: undefined }
      },
      group: 'inline',
      draggable: true,
      parseDOM: [{
        tag: 'img',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['src', 'alt', 'title']);
        }
      }],
      toDOM(node: ProseMirrorNode) {
        return ['img', {
          src: node.attrs.src,
          alt: node.attrs.alt,
          title: node.attrs.title
        }];
      },
      tagName: 'img',
      isVoid: true
    }
  };
}
