import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { SystemMessage } from '@portal-core/system-messages/models/system-message.model';

@CollectionStateSelectors({
  stateType: SystemMessagesState
})
@State<SystemMessagesState>({
  name: SystemMessagesState.source
})
@Injectable()
export class SystemMessagesState extends CollectionStateBase {
  static source = 'SystemMessages';
  getSource(): string {
    return SystemMessagesState.source;
  }
}
@Injectable({
  providedIn: 'root'
})
export class SystemMessagesDataService extends CollectionDataServiceBase<SystemMessage> {

  constructor(protected store: Store) {
    super(store, SystemMessagesState);
  }
}
