import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { OutputAnalyticsKeyState } from '@portal-core/output-analytics/services/output-analytics-key/output-analytics-key-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      OutputAnalyticsKeyState,
    ])
  ]
})
export class OutputAnalyticsKeyServicesModule { }
