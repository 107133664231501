import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { parseStyles, stringifyStyles } from '@common/html/util/style';
import { firstOrNull, makeArray } from '@common/util/array';
import { dirname, extname, isAbsoluteUrl, relative, resolvePath } from '@common/util/path';
import { McValidators } from '@portal-core/forms/services/mc-validators';
import { DimensionStylePresets } from '@portal-core/project-files/constants/dimension-style-presets.constant';
import { HTML5VideoFilter, MultimediaFilter } from '@portal-core/project-files/constants/file-filters.constants';
import { ProjectFolder } from '@portal-core/project-files/enums/project-folder.enum';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { UserSettings } from '@portal-core/users/interfaces/user-settings';
import { USER_SETTINGS_SERVICE } from '@portal-core/users/tokens/user-settings-service.token';
import { Subscription } from 'rxjs';

export interface MultimediaDialogData {
  width: string;
  height: string;
  projectId: number;
  commitId: string;
  src: string;
  currentFilePath: string;
}

export interface MultimediaDialogResult {
  attrs: ResultAttrs;
  isHTML5Video: boolean;
}

interface ResultAttrs {
  style: Dictionary;
  src: string;
}

interface MultimediaDialogForm {
  from: FormControl<string>,
  width: FormControl<string>,
  height: FormControl<string>,
  webLink: FormControl<string>,
  filePath: FormControl<string[]>
}

@Component({
  selector: 'mc-multimedia-dialog',
  templateUrl: './multimedia-dialog.component.html',
  styleUrls: ['./multimedia-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MultimediaDialogComponent extends DialogBase implements OnInit, OnDestroy {
  static DialogConfig: MatDialogConfig = {
    width: '50rem',
    maxWidth: '50rem',
    maxHeight: '57rem',
  };

  src: string;
  width: string;
  height: string;
  ProjectFolder: typeof ProjectFolder = ProjectFolder;

  multimediaForm: FormGroup<MultimediaDialogForm>;
  multimediaFilter: string = MultimediaFilter;

  fromSubscription: Subscription;

  presets = DimensionStylePresets;

  constructor(
    protected dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: MultimediaDialogData,
    @Inject(USER_SETTINGS_SERVICE) private userSettingsService: UserSettings,
    protected dialogRef: MatDialogRef<MultimediaDialogComponent>,
    private fb: UntypedFormBuilder
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.src = this.data.src;
    // set width and height, if user is not editing use what they did before.
    if (this.data.width || this.data.height) {
      this.width = this.data.width;
      this.height = this.data.height;
    } else {
      const settingStyle = parseStyles(this.userSettingsService.getSettingByName('project-files-page:editor:multimedia:style'));
      this.width = settingStyle?.width;
      this.height = settingStyle?.height;
    }
    this.createMultimediaForm();

    // sets the web src input either enabled or disabled.
    this.fromSubscription = this.multimediaForm.controls.from.valueChanges.subscribe(from => {
      const webLinkControl = this.multimediaForm.controls.webLink;
      const projectFileControl = this.multimediaForm.controls.filePath;
      if (from === 'fromWeb') {
        projectFileControl.disable();
        webLinkControl.enable();
      } else {
        projectFileControl.enable();
        webLinkControl.disable();
      }
    });
  }

  ngOnDestroy() {
    this.fromSubscription.unsubscribe();
  }

  onSubmit() {
    const multimediaForm = this.multimediaForm.controls;
    const from = multimediaForm.from.value;
    const filePath = firstOrNull(multimediaForm.filePath.value) ?? '';
    const width = multimediaForm.width.value;
    const height = multimediaForm.height.value;

    const style = { width, height };

    const result: MultimediaDialogResult = { attrs: { src: null, style }, isHTML5Video: false };

    if (from === 'fromProject' && extname(filePath).toLowerCase().match(HTML5VideoFilter)) {
      result.isHTML5Video = true;
    }

    if (multimediaForm.from.value === 'fromProject') {
      result.attrs.src = relative(dirname(this.data.currentFilePath), filePath);
    } else {
      result.attrs.src = multimediaForm.webLink.value;
    }

    // save defaults for next time
    this.userSettingsService.setSettingByName$('project-files-page:editor:multimedia:style', stringifyStyles(style));

    this.closeDialog(result);
  }

  protected createMultimediaForm() {
    const fromVal = this.src && isAbsoluteUrl(this.src) ? 'fromWeb' : 'fromProject';
    this.multimediaForm = this.fb.group({
      from: new FormControl(fromVal),
      filePath: new FormControl({ value: this.src ? makeArray(resolvePath(this.data.currentFilePath, this.src)) : null, disabled: !(fromVal === 'fromProject') }, [Validators.required]),
      webLink: new FormControl({ value: fromVal === 'fromWeb' ? this.src : null, disabled: !(fromVal === 'fromWeb') }, [Validators.required, McValidators.supportedExternalMultimediaLink]),
      width: new FormControl(this.width),
      height: new FormControl(this.height),
    });
  }
}
