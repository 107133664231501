import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { CurrentService } from '@portal-core/current/services/current.service';
import { AlertType } from '@portal-core/general/enums/alert-type.enum';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { Observable, first, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorAccessResolverGuardService implements Resolve<boolean> {
  constructor(private router: Router, private currentService: CurrentService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.currentService.getLicenseUser$().pipe(
      first(licenseUser => !!licenseUser),
      map(licenseUser => licenseUser.SeatType === LicenseUserSeatType.Author),
      tap(accessAllowed => {
        if (!accessAllowed) {
          this.router.navigate(['/alert'], { queryParams: { type: AlertType.UserLicenseAccessDenied } });
        }
      })
    );
  }
}
