import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs, toAttributeBoolean, toAttributeBooleanString } from '@common/util/schema';
import { NodeSpec } from 'prosemirror-model';

const MadCapELearningAttrs = {
  'MadCap:allowMicroContentStylesheets': {
    default: undefined,
    toFlareXML(value: any): string {
      return toAttributeBooleanString(value);
    }
  },
  'MadCap:generateStructuredData': {
    default: undefined,
    toFlareXML(value: any): string {
      return toAttributeBooleanString(value);
    }
  },
  'MadCap:querySource': { default: undefined },
  'MadCap:queryType': { default: undefined },
  'MadCap:sortAlphabetically': {
    default: undefined,
    toFlareXML(value: any): string {
      return toAttributeBooleanString(value);
    }
  },
  'MadCap:title': { default: undefined }
};

const MadCapELearningAttrNames = Object.keys(MadCapELearningAttrs);

function getMadCapELearningAttrs(dom: HTMLElement): Dictionary {
  const attrs = getSchemaAttrs(dom, MadCapELearningAttrNames);

  return {
    ...attrs,
    'MadCap:allowMicroContentStylesheets': toAttributeBoolean(attrs['MadCap:allowMicroContentStylesheets']),
    'MadCap:generateStructuredData': toAttributeBoolean(attrs['MadCap:generateStructuredData']),
    'MadCap:sortAlphabetically': toAttributeBoolean(attrs['MadCap:sortAlphabetically'])
  };
}

export class MadCapELearningProxySchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapfaqproxy: {
      group: 'block',
      content: '(madcapfilter | madcapquerymetatagmap)*',
      atom: true,
      attrs: MadCapELearningAttrs,
      parseDOM: [{
        tag: 'MadCap\\:faqProxy',
        getAttrs: getMadCapELearningAttrs
      }],
      toDOM() { return ['div', { class: 'mc-madcap-faq-proxy' }, 'FAQ Proxy']; },
      tagName: 'MadCap:faqProxy'
    },

    madcapknowledgeproxy: {
      group: 'block',
      content: '(madcapfilter | madcapquerymetatagmap)*',
      atom: true,
      attrs: MadCapELearningAttrs,
      parseDOM: [{
        tag: 'MadCap\\:knowledgeProxy',
        getAttrs: getMadCapELearningAttrs
      }],
      toDOM() { return ['div', { class: 'mc-madcap-knowledge-proxy' }, 'Knowledge Proxy']; },
      tagName: 'MadCap:knowledgeProxy'
    },

    madcappromotionproxy: {
      group: 'block',
      content: '(madcapfilter | madcapquerymetatagmap)*',
      atom: true,
      attrs: MadCapELearningAttrs,
      parseDOM: [{
        tag: 'MadCap\\:promotionProxy',
        getAttrs: getMadCapELearningAttrs
      }],
      toDOM() { return ['div', { class: 'mc-madcap-promotion-proxy' }, 'Promotion Proxy']; },
      tagName: 'MadCap:promotionProxy'
    },

    madcapquerymetatagmap: {
      content: 'madcapmetatag*',
      parseDOM: [{
        tag: 'MadCap\\:queryMetaTagMap'
      }],
      tagName: 'MadCap:queryMetaTagMap',
      specContext: 'madcapfaqproxy'
    },

    madcapfilter: {
      content: 'madcapmicrocontentsubset*',
      attrs: {
        'MadCap:type': { default: undefined },
        'MadCap:numberOfResults': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:filter',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['MadCap:type', 'MadCap:numberOfResults']);
        }
      }],
      tagName: 'MadCap:filter',
      specContext: 'madcapfaqproxy'
    },

    madcapmicrocontentsubset: {
      content: '(madcapfile | madcapmetatag)*',
      parseDOM: [{
        tag: 'MadCap\\:microContentSubset'
      }],
      tagName: 'MadCap:microContentSubset',
      specContext: 'madcapfilter'
    },

    madcapfile: {
      attrs: {
        'MadCap:link': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:file',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['MadCap:link']);
        }
      }],
      isVoid: true,
      tagName: 'MadCap:file',
      specContext: 'madcapmicrocontentsubset'
    },

    madcapmetatag: {
      attrs: {
        'MadCap:name': { default: undefined },
        'MadCap:value': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:metaTag',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['MadCap:name', 'MadCap:value']);
        }
      }],
      isVoid: true,
      tagName: 'MadCap:metaTag',
      specContext: 'madcapquerymetatagmap'
    }
  };
}
