import { FlareSerializer } from '@common/flare/flare-serializer';
import { BlockContainerSchemaPlugin } from '@common/flare/schema/block-container-schema.plugin';
import { BrSchemaPlugin } from '@common/flare/schema/br-schema-plugin';
import { FormSchemaPlugin } from '@common/flare/schema/form-schema.plugin';
import { MadCapCentralCDataSchemaPlugin } from '@common/flare/schema/madcap-central-cdata-schema.';
import { MadCapCentralNbspSchemaPlugin } from '@common/flare/schema/madcap-central-nbsp-schema-plugin';
import { MadCapStyleSchemaPlugin } from '@common/flare/schema/madcap-style-schema-plugin';
import { ScriptSchemaPlugin } from '@common/flare/schema/script-schema.plugin';
import { SvgSchemaPlugin } from '@common/flare/schema/svg-schema.plugin';
import { FlareSerializerExportOptions } from '@common/flare/types/to-flare-xml-options.type';
import { ValidationOptions } from '@common/html/types/serialization-options.type';
import { CollabSchema } from '@common/prosemirror/model/collab-schema';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { environment } from '@env/environment';
import { Fragment, MarkSpec, NodeSpec, ProseMirrorNode } from 'prosemirror-model';
import { CustomAttributesSchemaPlugin } from './schema/custom-attributes-schema-plugin';
import { DefinitionListSchemaPlugin } from './schema/definition-list-schema-plugin';
import { FlareDocSchemaPlugin } from './schema/flare-doc-schema-plugin';
import { HeadingSchemaPlugin } from './schema/heading-schema-plugin';
import { HrSchemaPlugin } from './schema/hr-schema.plugin';
import { HtmlTagsSchemaPlugin } from './schema/html-tags-schema-plugin';
import { IFrameSchemaPlugin } from './schema/iframe-schema-plugin';
import { ImageSchemaPlugin } from './schema/image-schema-plugin';
import { LinkSchemaPlugin } from './schema/link-schema-plugin';
import { ListSchemaPlugin } from './schema/list-schema-plugin';
import { MadCapAnnotationSchemaPlugin } from './schema/madcap-annotation-schema-plugin';
import { MadCapBookmarkSchemaPlugin } from './schema/madcap-bookmark-schema-plugin';
import { MadCapCentralCommentSchemaPlugin } from './schema/madcap-central-comment-schema-plugin';
import { MadCapChangeSchemaPlugin } from './schema/madcap-change-schema-plugin';
import { MadCapCodeSnippetSchemaPlugin } from './schema/madcap-code-snippet-schema-plugin';
import { MadCapConceptSchemaPlugin } from './schema/madcap-concept-schema-plugin';
import { MadCapConditionSchemaPlugin } from './schema/madcap-condition-schema-plugin';
import { MadCapDropdownSchemaPlugin } from './schema/madcap-dropdown-schema-plugin';
import { MadCapELearningProxySchemaPlugin } from './schema/madcap-elearning-proxy-schema-plugin';
import { MadCapEquationSchemaPlugin } from './schema/madcap-equation-schema-plugin';
import { MadCapExpandingSchemaPlugin } from './schema/madcap-expanding-schema-plugin';
import { MadCapFootnoteSchemaPlugin } from './schema/madcap-footnote-schema-plugin';
import { MadCapGlossaryTermSchemaPlugin } from './schema/madcap-glossary-term-schema-plugin';
import { MadCapKeywordSchemaPlugin } from './schema/madcap-keyword-schema-plugin';
import { MadCapMicroContentSchemaPlugin } from './schema/madcap-micro-content-schema-plugin';
import { MadCapModel3DSchemaPlugin } from './schema/madcap-model-3d-schema-plugin';
import { MadCapMultimediaSchemaPlugin } from './schema/madcap-multimedia-schema-plugin';
import { MadCapMultipleChoiceSchemaPlugin } from './schema/madcap-multiple-choice-schema.plugin';
import { MadCapNamedDestinationSchemaPlugin } from './schema/madcap-named-destination-schema-plugin';
import { MadCapPageBreakSchemaPlugin } from './schema/madcap-page-break-schema-plugin';
import { MadCapPageFooterSchemaPlugin } from './schema/madcap-page-footer-schema-plugin';
import { MadCapPageHeaderSchemaPlugin } from './schema/madcap-page-header-schema-plugin';
import { MadCapPopupSchemaPlugin } from './schema/madcap-popup-schema-plugin';
import { MadCapProxySchemaPlugin } from './schema/madcap-proxy-schema-plugin';
import { MadCapQRCodeSchemaPlugin } from './schema/madcap-qr-code-schema-plugin';
import { MadCapRelatedTopicsSchemaPlugin } from './schema/madcap-related-topics-schema-plugin';
import { MadCapResponsiveContentSchemaPlugin } from './schema/madcap-responsive-content-schema-plugin';
import { MadCapShortcutSchemaPlugin } from './schema/madcap-shortcut-schema-plugin';
import { MadCapSlideshowSchemaPlugin } from './schema/madcap-slideshow-schema-plugin';
import { MadCapSnippetSchemaPlugin } from './schema/madcap-snippet-schema-plugin';
import { MadCapTargetNameSchemaPlugin } from './schema/madcap-target-name-schema-plugin';
import { MadCapTogglerSchemaPlugin } from './schema/madcap-toggler-schema-plugin';
import { MadCapVariableSchemaPlugin } from './schema/madcap-variable-schema-plugin';
import { McCentralContainerSchemaPlugin } from './schema/mc-central-container-schema.plugin';
import { MMLSchemaPlugin } from './schema/mml-schema-plugin';
import { ParagraphSchemaPlugin } from './schema/paragraph-schema.plugin';
import { PreSchemaPlugin } from './schema/pre-schema-plugin';
import { TableSchemaPlugin } from './schema/table-schema-plugin';
import { TextSchemaPlugin } from './schema/text-schema.plugin';
import { UnknownSchemaPlugin } from './schema/unknown-schema-plugin';
import { WhitespaceSchemaPlugin } from './schema/whitespace-schema-plugin';

export class FlareSchema extends CollabSchema {
  static DocMaxCharLength: number = environment.flareTextEditorDocMaxCharLength ? parseInt(environment.flareTextEditorDocMaxCharLength, 10) : 0;

  get docMaxCharLength(): number { return FlareSchema.DocMaxCharLength; }
  get name(): string { return 'flare'; }
  get version(): string { return '0.5.0'; }

  flareSerializer: FlareSerializer;

  constructor() {
    const schemaPlugins: SchemaPlugin[] = [
      new FlareDocSchemaPlugin(),
      new ParagraphSchemaPlugin(),
      new TextSchemaPlugin(),
      new HeadingSchemaPlugin(),
      new IFrameSchemaPlugin(),
      new ImageSchemaPlugin(),
      new ListSchemaPlugin(),
      new DefinitionListSchemaPlugin(),
      new HrSchemaPlugin(),
      new BrSchemaPlugin(),
      new MadCapCentralCommentSchemaPlugin(),
      new MadCapCentralCDataSchemaPlugin(),
      new MadCapCentralNbspSchemaPlugin(), // Kept for backwards compatibility when exporting docs containing madcapcentralnbspblock or madcapcentralnbspinline nodes
      new MadCapPageBreakSchemaPlugin(),
      new MadCapChangeSchemaPlugin(),
      new MadCapDropdownSchemaPlugin(),
      new MadCapSnippetSchemaPlugin(),
      new MadCapVariableSchemaPlugin(),
      new MadCapNamedDestinationSchemaPlugin(),
      new MadCapKeywordSchemaPlugin(),
      new MadCapConceptSchemaPlugin(),
      new MadCapBookmarkSchemaPlugin(),
      new MadCapTogglerSchemaPlugin(),
      new MadCapGlossaryTermSchemaPlugin(),
      new MadCapExpandingSchemaPlugin(),
      new MadCapPopupSchemaPlugin(),
      new MadCapProxySchemaPlugin(),
      new MadCapELearningProxySchemaPlugin(),
      new MadCapSlideshowSchemaPlugin(),
      new MadCapModel3DSchemaPlugin(),
      new MadCapQRCodeSchemaPlugin(),
      new MadCapMultimediaSchemaPlugin(),
      new MadCapEquationSchemaPlugin(),
      new MadCapShortcutSchemaPlugin(),
      new MadCapFootnoteSchemaPlugin(),
      new MadCapMicroContentSchemaPlugin(),
      new MadCapRelatedTopicsSchemaPlugin(),
      new MadCapPageHeaderSchemaPlugin(),
      new MadCapPageFooterSchemaPlugin(),
      new MadCapResponsiveContentSchemaPlugin(),
      new MadCapTargetNameSchemaPlugin(),
      new MadCapConditionSchemaPlugin(),
      new MadCapCodeSnippetSchemaPlugin(),
      new MadCapMultipleChoiceSchemaPlugin(),
      new MMLSchemaPlugin(),
      new LinkSchemaPlugin(),
      new FormSchemaPlugin(),
      new PreSchemaPlugin(),
      new ScriptSchemaPlugin(),
      new SvgSchemaPlugin(),
      new BlockContainerSchemaPlugin(),
      new HtmlTagsSchemaPlugin(),
      new MadCapAnnotationSchemaPlugin(),
      new TableSchemaPlugin(),
      new McCentralContainerSchemaPlugin(),
      new UnknownSchemaPlugin(),
      // Modifies the specs on other nodes so these should go last
      new MadCapStyleSchemaPlugin(),
      new WhitespaceSchemaPlugin(),
      new CustomAttributesSchemaPlugin()
    ];

    let nodes: Dictionary<NodeSpec> = {};
    const marks: Dictionary<MarkSpec> = {};

    // Add schema nodes and marks from the schema plugins
    schemaPlugins.forEach(plugin => {
      plugin.addNodes(nodes);
      plugin.addMarks(marks);
    });

    // Move the default block node to the beginning of the node map so that it is used as the default by ProseMirror
    const defaultBlockNodeName = 'paragraph';
    const defaultBlockNodeSpec = nodes[defaultBlockNodeName];
    if (!defaultBlockNodeSpec) {
      throw new Error(`Default block node not found in schema for ${defaultBlockNodeName}`);
    }
    // Create a new object with the default block node at the beginning
    nodes = { [defaultBlockNodeName]: defaultBlockNodeSpec, ...nodes };

    // Let the schema plugins modify the nodes and marks now that they are all added
    schemaPlugins.forEach(plugin => {
      plugin.modifyNodesAndMarks(nodes, marks);
    });

    super({ nodes, marks });

    this.flareSerializer = new FlareSerializer();

    // Add schema props from the schema modules
    schemaPlugins.forEach(plugin => {
      plugin.addProps(this);
    });
  }

  codeToDoc(code: string): ProseMirrorNode {
    return this.flareSerializer.codeToDoc(code, this);
  }

  nodeToCode(node: ProseMirrorNode, options?: FlareSerializerExportOptions): string {
    return this.flareSerializer.nodeToCode(node, this, options);
  }

  fragmentToCode(fragment: Fragment, options?: FlareSerializerExportOptions): string {
    return this.flareSerializer.fragmentToCode(fragment, this, options);
  }

  validate(code: string, options?: ValidationOptions): Error | null {
    return this.flareSerializer.validate(code, this, options);
  }

  /**
   * Returns the display text for a tag name. Uses the node spec's toDisplayName if it exists. Then the node spec's tag name. Finally falling back to the node's name.
   * @param node The node to get the display name for.
   * @returns The display name for the node.
   */
  getTagDisplayName(node: ProseMirrorNode): string {
    // Use the toDisplayName function if it exists on the node
    if (typeof node.type.spec.toDisplayName === 'function') {
      return node.type.spec.toDisplayName(node);
    } else {
      return node.type.spec.tagName ?? node.type.name;
    }
  }
}
