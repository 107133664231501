import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mc-app-getting-started-dialog',
  templateUrl: './app-getting-started-dialog.component.html',
  styleUrls: ['./app-getting-started-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppGettingStartedDialogComponent implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '44rem'
  };

  @ViewChild('disableGettingStartedMessageCheckbox', { static: true }) disableGettingStartedMessageCheckbox: MatCheckbox;

  disableGettingStartedDialog: boolean;

  constructor(private dialogRef: MatDialogRef<AppGettingStartedDialogComponent>) { }

  ngOnInit() {
    this.dialogRef.beforeClosed().subscribe(() => {
      this.disableGettingStartedDialog = this.disableGettingStartedMessageCheckbox.checked;
    });
  }
}
