import { CdkScrollable } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CollabFileType } from '@common/collab/enums/collab-file-type.enum';
import { FlareCommands } from '@common/flare/flare-commands';
import { FlareSchema } from '@common/flare/flare-schema';
import { AnnotationAttributes } from '@common/flare/types/annotation-attributes.type';
import { DocSizeError } from '@common/html/constants/doc-size-error.constant';
import { MetaDataValues } from '@common/meta-data/types/meta-data-values.type';
import { ChangeTrackerUser } from '@common/prosemirror/changeset/change-tracker';
import { ImageAction, insertImageUploadErrors, removeImagePlaceholders, updateImagePlaceholders } from '@common/prosemirror/commands/image.command';
import { addColumnAfter, addColumnBefore, addRowAfter, addRowBefore, deleteColumn, deleteRow, deleteTable } from '@common/prosemirror/commands/table.command';
import { findNode } from '@common/prosemirror/model/node';
import { AppliedConditionsPluginOptions } from '@common/prosemirror/plugins/applied-conditions.plugin';
import { GutterItem, GutterItemType, GutterPluginTargetDecorationType, GutterPluginTargetItem, GutterPluginTargetType } from '@common/prosemirror/plugins/gutter.plugin';
import { ImagePlaceholderAction, ImageUploadPluginKey } from '@common/prosemirror/plugins/image-upload.plugin';
import { selectionIsInNode } from '@common/prosemirror/state/selection';
import { selectNodeContent } from '@common/prosemirror/state/transaction';
import { updateNodeAttrs } from '@common/prosemirror/transform/node';
import { ReviewFileUserStatus } from '@common/reviews/enums/review-file-user-status.enum';
import { CollabSession } from '@common/sockets/collab-session';
import { CollabSessionSaveResult } from '@common/sockets/enums/collab-session-save-result.enum';
import { CollabSessionSyncState } from '@common/sockets/enums/collab-session-sync-state.enum';
import { CollabSessionErrorEvent } from '@common/sockets/types/collab-session-error-event.type';
import { setColorLightness } from '@common/util/colors';
import { resolvePath } from '@common/util/path';
import { CommitFileDialogResult } from '@portal-core/commits/components/commit-file-dialog/commit-file-dialog.component';
import { MaxAttachmentSizeBytes } from '@portal-core/data/common/constants/max-attachment-size.constant';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ErrorDialogComponent } from '@portal-core/general/components/error-dialog/error-dialog.component';
import { MatMenuPosition } from '@portal-core/general/models/mat-menu-position.model';
import { FileSizeService } from '@portal-core/general/services/file-size.service';
import { FileService } from '@portal-core/general/services/file.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { FlareFileTextEditorGutterComponent } from '@portal-core/project-files/components/flare-file-text-editor-gutter/flare-file-text-editor-gutter.component';
import { FlareFileTextEditorTagbarComponent } from '@portal-core/project-files/components/flare-file-text-editor-tagbar/flare-file-text-editor-tagbar.component';
import { FlareFileTextEditorToolbarComponent } from '@portal-core/project-files/components/flare-file-text-editor-toolbar/flare-file-text-editor-toolbar.component';
import { ProjectFilesAddSnippetDialogComponent, ProjectFilesAddSnippetDialogData, ProjectFilesAddSnippetDialogResult } from '@portal-core/project-files/components/project-files-add-snippet-dialog/project-files-add-snippet-dialog.component';
import { ProjectFilesEditSnippetDialogComponent } from '@portal-core/project-files/components/project-files-edit-snippet-dialog/project-files-edit-snippet-dialog.component';
import { BackgroundStylesBuilder, LoadingConditionStylesBuilder, ProjectConditionsStyleManager } from '@portal-core/project-files/conditions/util/project-conditions-style-manager';
import { AnnotationCommentChangeEvent } from '@portal-core/project-files/models/annotation-comment-change-event.model';
import { CommittedItem } from '@portal-core/project-files/models/committed-item.model';
import { MetaDataService } from '@portal-core/project-files/services/meta-data.service';
import { ReviewEditSnippetDialogComponent } from '@portal-core/reviews/components/review-edit-snippet-dialog/review-edit-snippet-dialog.component';
import { ReviewFilesService } from '@portal-core/reviews/review-files/services/review-files.service';
import { CollabFileTextEditorComponent } from '@portal-core/text-editor/components/collab-file-text-editor/collab-file-text-editor.component';
import { CutCopyPasteHelpDialogComponent, CutCopyPasteHelpDialogData } from '@portal-core/text-editor/components/cut-copy-paste-help-dialog/cut-copy-paste-help-dialog.component';
import { SoloFileTextEditorComponent } from '@portal-core/text-editor/components/solo-file-text-editor/solo-file-text-editor.component';
import { EditorContextMenuEvent } from '@portal-core/text-editor/components/text-editor/text-editor.component';
import { EditorImageExtensions } from '@portal-core/text-editor/constants/editor-image-extensions.constant';
import { InsertImageEvent } from '@portal-core/text-editor/models/insert-image.event';
import { InsertSnippetEvent } from '@portal-core/text-editor/models/insert-snippet-event';
import { TextEditorService } from '@portal-core/text-editor/services/text-editor.service';
import { CollabSessionEvent } from '@portal-core/text-editor/types/collab-session-event.type';
import { EditorChangeEvent } from '@portal-core/text-editor/types/editor-change-event.type';
import { EditorToolbarDropdownMenuClosedEvent } from '@portal-core/ui/editor/types/editor-toolbar-dropdown-menu-closed-event.type';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { Cancelable, debounce } from 'lodash';
import { selectAll } from 'prosemirror-commands';
import { redo, undo } from 'prosemirror-history';
import { ProseMirrorNode } from 'prosemirror-model';
import { Command, EditorState, NodeSelection, Plugin, TextSelection } from 'prosemirror-state';
import { isInTable } from 'prosemirror-tables';
import { EditorView, NodeViewConstructor } from 'prosemirror-view';
import { BehaviorSubject, Observable, Subject, Subscription, catchError, combineLatest, distinctUntilChanged, filter, first, forkJoin, map, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'mc-flare-text-editor',
  templateUrl: './flare-text-editor.component.html',
  styleUrls: ['./flare-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class FlareTextEditorComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(CollabFileTextEditorComponent, { static: false }) collabFileTextEditor: CollabFileTextEditorComponent;
  @ViewChild(SoloFileTextEditorComponent, { static: false }) soloFileTextEditor: SoloFileTextEditorComponent;
  @ViewChild(FlareFileTextEditorTagbarComponent, { static: true }) flareFileTextEditorTagbarComponent: FlareFileTextEditorTagbarComponent;
  @ViewChild(FlareFileTextEditorToolbarComponent, { static: false }) flareFileTextEditorToolbarComponent: FlareFileTextEditorToolbarComponent;
  @ViewChild(FlareFileTextEditorGutterComponent, { static: false }) flareFileTextEditorGutterComponent: FlareFileTextEditorGutterComponent;
  @ViewChild(MatMenuTrigger, { static: true }) editorMenuTrigger: MatMenuTrigger;
  @ViewChild('editorScrollContainer', { static: true }) editorScrollContainerRef: ElementRef;
  @ViewChild('editorScrollContainer', { static: true, read: CdkScrollable }) editorScrollable: CdkScrollable;

  @Input() branchName: string;
  @Input() commitId: string;
  @Input() content?: string;
  @Input() docExceedsMaxSize?: boolean;
  @Input() docSize?: number;
  @Input() featureSetVersion: number;
  @Input() fileId: number;
  @Input() filePath: string;
  @Input() fileType: CollabFileType;
  @Input() licenseUser: LicenseUser;
  @Input() projectId: number;
  @Input() projectLanguage: string;
  @Input() readonly?: boolean = false;
  @Input() reviewPackageId?: number;
  @Input() showAI?: boolean = true;
  @Input() showTagbar?: boolean = true
  @Input() showTrackedChanges?: boolean = true;
  @Input() trackedChanges?: boolean = false;
  @Input() showConditions?: boolean;

  @InputObservable('branchName') branchName$: Observable<string>;
  @InputObservable('commitId') commitId$: Observable<string>;
  @InputObservable('fileId') fileId$: Observable<number>;
  @InputObservable('filePath') filePath$: Observable<string>;
  @InputObservable('fileType') fileType$: Observable<CollabFileType>;
  @InputObservable('licenseUser') licenseUser$: Observable<LicenseUser>;
  @InputObservable('projectId') projectId$: Observable<number>;
  @InputObservable('content') content$: Observable<string>;
  @InputObservable('showConditions') showConditions$: Observable<boolean>;

  @Output() change: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();
  @Output() errorChange: EventEmitter<CollabSessionErrorEvent> = new EventEmitter<CollabSessionErrorEvent>();
  @Output() exceedsMaxSizeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() exceedsMongoDBMaxSizeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() syncStateChange: EventEmitter<CollabSessionSyncState> = new EventEmitter<CollabSessionSyncState>();
  @Output() createCollabFile: EventEmitter<void> = new EventEmitter<void>();
  @Output() navToFile: EventEmitter<string> = new EventEmitter<string>();
  @Output() trackedChangesChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() subEditorChange: EventEmitter<ReviewFileUserStatus> = new EventEmitter<ReviewFileUserStatus>();
  @Output() showConditionsChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  get editorState(): EditorState {
    return this.collabFileTextEditor?.editorState ?? this.soloFileTextEditor?.editorState ?? null;
  }

  get editorView(): EditorView {
    return this.collabFileTextEditor?.editorView ?? this.soloFileTextEditor?.editorView ?? null;
  }

  get viewPluginOverlay(): HTMLElement {
    return this.collabFileTextEditor?.viewPluginOverlay ?? this.soloFileTextEditor?.viewPluginOverlay ?? null;
  }

  get editable(): boolean {
    return !this.readonly && !this.serverReadonly;
  }

  set doc(doc: ProseMirrorNode) {
    if (this.soloFileTextEditor) {
      this.soloFileTextEditor.doc = doc;
    }
  }

  CollabFileType: typeof CollabFileType = CollabFileType;

  collabSession: CollabSession;
  collabSessionError: CollabSessionErrorEvent;
  debouncedReflowGutterLayout: Function & Cancelable;
  docExceedsMongoDBMaxSize: boolean;
  editorMenuPosition: MatMenuPosition = { x: '0px', y: '0px' };
  gutterItems$: Subject<GutterItem[]> = new Subject<GutterItem[]>();
  nodeViews$: Observable<Dictionary<NodeViewConstructor>>;
  offline: boolean;
  /**
   * Emits when the plugin overlay element is ready to use.
   * This is a BehaviorSubject because the editor may destroy and create its plugin during its lifecycle so this needs to re-emit each time a new plugin is created and subscribes.
   */
  private viewPluginOverlay$: BehaviorSubject<HTMLElement> = new BehaviorSubject<HTMLElement>(null);
  private metaDataSource$: Subject<MetaDataValues> = new BehaviorSubject(null);
  private metaData$: Observable<MetaDataValues> = this.metaDataSource$.asObservable();
  private metaDataSubscription: Subscription;
  plugins$: Observable<Plugin[]>;
  private reloadDataSource: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  private reload$: Observable<void> = this.reloadDataSource.asObservable();
  schema: FlareSchema = new FlareSchema();
  private flareCommands: FlareCommands = new FlareCommands(this.schema);
  selectedGutterItemId: string;
  serverReadonly: boolean = false;
  showGutter: boolean = false;
  getEditorState: () => EditorState = () => this.editorState;
  contentSubscription: Subscription;
  private conditionsStyleManager: ProjectConditionsStyleManager = new ProjectConditionsStyleManager();

  constructor(
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private fileSizeService: FileSizeService,
    private fileService: FileService,
    private ngZone: NgZone,
    private reviewFilesService: ReviewFilesService,
    private snackBar: MatSnackBar,
    private textEditorService: TextEditorService,
    private metaDataService: MetaDataService
  ) { }

  ngOnInit() {
    // Create a debounced version of reflowGutterLayout so it does not get called many times on window resize and node view loads
    this.debouncedReflowGutterLayout = debounce(this.reflowGutterLayout.bind(this), 300, { leading: true });

    this.plugins$ = this.createPlugins$();

    // If the file info (branchName, commitId, filePath, or projectId) THEN the node views need to be recreated
    // This is required because the node views need to be reconfigured for each file
    this.nodeViews$ = this.fileType$.pipe(
      switchMap(fileType => {
        if (fileType === CollabFileType.Edit) {
          return this.soloNodeViews$();
        } else if (fileType === CollabFileType.Review) {
          return this.collabNodeViews$();
        } else {
          return of(null);
        }
      })
    );

    this.metaDataSubscription = this.metaData$.subscribe((data) => {
      if (data) {
        const conditionTags = this.metaDataService.extractConditionTagsFromMetaData(data.metaData);
        this.conditionsStyleManager.setConditions(conditionTags);
      } else {
        this.conditionsStyleManager.setConditions(null);
      }
    });

    this.contentSubscription = this.content$.subscribe(() => this.scrollToTop());

    this.buildConditionalStyles();

    combineLatest([
      this.projectId$,
      this.branchName$,
      this.commitId$
    ]).subscribe(() => {
      this.conditionsStyleManager.reset();
    });
  }

  ngAfterViewInit() {
    // The view plugin overlay is not available until after the view has been initialized so emit it here
    this.viewPluginOverlay$.next(this.viewPluginOverlay);
  }

  ngOnDestroy() {
    if (this.debouncedReflowGutterLayout) {
      this.debouncedReflowGutterLayout.cancel();
      this.debouncedReflowGutterLayout = null;
    }
    this.conditionsStyleManager.destroy();
  }

  @HostListener('window:resize')
  onWindowResized() {
    // The width of the flare file document may have changed causing content to shift which means the gutter items need to be repositioned to match
    this.debouncedReflowGutterLayout();
  }

  onCollabSessionEnd(event: CollabSessionEvent) {
    this.collabSession = null;
  }

  onCollabSessionStart(event: CollabSessionEvent) {
    this.collabSession = event.session;

    this.flareFileTextEditorToolbarComponent?.updateState(null);
    this.flareFileTextEditorTagbarComponent?.updateState(null);
  }

  onCollabSessionLoaded() {
    this.loadMetaData(this.editorView, this.editorState);
  }

  onSoloEditorLoaded(event: EditorChangeEvent) {
    this.flareFileTextEditorToolbarComponent?.updateState(event.newEditorState);
    this.flareFileTextEditorTagbarComponent?.updateState(event.newEditorState);
    this.loadMetaData(event.editorView, event.newEditorState);
  }

  onDispatch(command: Command) {
    // Return focus to the editor before running a command from the toolbar
    this.editorView.focus();
    command(this.editorState, this.editorView.dispatch, this.editorView);
  }

  onTrackedChangesChanged($event: boolean) {
    this.trackedChanges = $event;
    this.trackedChangesChange.emit($event);
  }

  onToolbarDropdownMenuClosed(event: EditorToolbarDropdownMenuClosedEvent<Command>) {
    // Only give focus to the editor if an item wasn't clicked and the menu wasn't closed because the user tabbed away
    if (!event.item && event.source !== 'tab') {
      // A toolbar dropdown was closed so give focus back to the editor
      this.editorView.focus();
    }
  }

  onEditorContextMenu(editorEvent: EditorContextMenuEvent) {
    if (!this.readonly) {
      editorEvent.preventDefault();

      const editorState = this.editorState;
      const selection = editorState.selection as NodeSelection;
      const isInSnippet = selectionIsInNode(selection, editorState.schema.nodes.madcapsnippettext) || selectionIsInNode(selection, editorState.schema.nodes.madcapsnippetblock);
      let snippetSrc: string;
      if (isInSnippet) {
        snippetSrc = resolvePath(this.filePath, selection.node.attrs?.src);
      }

      let reviewFileId: number;
      if (this.fileType === CollabFileType.Review) {
        reviewFileId = this.reviewFilesService.getItemByProperties('Path', snippetSrc, 'ReviewPackageId', this.reviewPackageId)?.Id;
      }

      const menu = {
        undo: { disabled: !undo(editorState) },
        redo: { disabled: !redo(editorState) },
        delete: { disabled: !this.flareCommands.deleteSelection(editorState) },
        editTable: { disabled: !isInTable(editorState) },
        editSnippet: { disabled: !isInSnippet, snippetSrc: snippetSrc, reviewFileId: reviewFileId }
      };

      this.editorMenuPosition = {
        x: editorEvent.event.clientX + 'px',
        y: editorEvent.event.clientY + 'px'
      };

      this.editorMenuTrigger.menuData = { menu };
      this.editorMenuTrigger.openMenu();
    }

  }

  onEditorStateChanged(event: EditorChangeEvent) {
    if (this.flareFileTextEditorToolbarComponent) {
      this.flareFileTextEditorToolbarComponent.updateState(event.newEditorState);
    }

    if (this.flareFileTextEditorTagbarComponent) {
      this.flareFileTextEditorTagbarComponent.updateState(event.newEditorState);
    }
  }

  onErrorChanged(error: CollabSessionErrorEvent) {
    this.collabSessionError = error;
    this.errorChange.emit(error);
  }

  onExceedsMaxSizeChanged(exceedsMaxSize: boolean) {
    this.docExceedsMaxSize = exceedsMaxSize;
    this.exceedsMaxSizeChange.emit(exceedsMaxSize);
  }

  onExceedsMongoDBMaxSizeChanged(exceedsMongoDBMaxSize: boolean) {
    this.docExceedsMongoDBMaxSize = exceedsMongoDBMaxSize;
    this.exceedsMongoDBMaxSizeChange.emit(exceedsMongoDBMaxSize);
  }

  onOfflineChanged(offline: boolean) {
    this.offline = offline;
  }

  onReadOnlyChanged(serverReadonly: boolean) {
    this.serverReadonly = serverReadonly;
  }

  onSizeChanged(size: number) {
    this.docSize = size;
  }

  onRevertDocToSavedState() {
    this.collabSession.revertDocToSavedState();
  }

  onSyncStateChanged(syncState: CollabSessionSyncState) {
    this.syncStateChange.emit(syncState);
  }

  onGutterItemHovered(gutterItem: GutterItem) {
    this.updateGutterDecorations(gutterItem, 'hover', [{ group: 'hover' }]);
  }

  onGutterItemSelected(gutterItem: GutterItem) {
    this.updateGutterDecorations(gutterItem, 'select', [{ group: 'select' }], true);
  }

  onInsertingAnnotation(attrs: AnnotationAttributes) {
    this.selectedGutterItemId = attrs['MadCap:guid'];
  }

  onAnnotationCommentChanged(event: AnnotationCommentChangeEvent) {
    const annotation = event.annotation;

    // Find the annotation node with the same guid
    const nodeInfo = findNode(this.editorView.state.doc, node => {
      return node.type.name === 'madcapannotation' && node.attrs['MadCap:guid'] === annotation['MadCap:guid'];
    });

    if (nodeInfo) {
      // Update the node that was found
      const tr = this.editorState.tr;

      updateNodeAttrs(tr, nodeInfo.node, nodeInfo.pos, {
        'MadCap:comment': annotation['MadCap:comment'],
        'MadCap:editor': this.licenseUser.User.FullName,
        'MadCap:editDate': new Date().toISOString()
      });

      // If the transaction changed the doc then dispatch it
      if (tr.docChanged) {
        tr.setMeta('anno', 'update');
        this.editorView.dispatch(tr);
      }
    }
  }

  onInsertColumnLeftClicked() {
    addColumnBefore(this.editorState, this.editorView.dispatch);
  }

  onInsertColumnRightClicked() {
    addColumnAfter(this.editorState, this.editorView.dispatch);
  }

  onInsertRowAboveClicked() {
    addRowBefore(this.editorState, this.editorView.dispatch);
  }

  onInsertRowBelowClicked() {
    addRowAfter(this.editorState, this.editorView.dispatch);
  }

  onDeleteColumnClicked() {
    deleteColumn(this.editorState, this.editorView.dispatch);
  }

  onDeleteRowClicked() {
    deleteRow(this.editorState, this.editorView.dispatch);
  }

  onDeleteTableClicked() {
    deleteTable(this.editorState, this.editorView.dispatch);
  }

  onInsertingImage(event: InsertImageEvent, showErrorDialog: boolean = false) {
    this.updateImages([event], showErrorDialog);
  }

  onOpenEditSnippetDialog(snippetSrc: string) {
    if (this.fileType === CollabFileType.Review) {
      this.dialog.open(ReviewEditSnippetDialogComponent, {
        ...ReviewEditSnippetDialogComponent.DialogConfig,
        data: {
          featureSetVersion: this.featureSetVersion,
          reviewer: this.licenseUser,
          reviewPackageId: this.reviewPackageId,
          snippetSrc: snippetSrc,
          changeCallback: (status: ReviewFileUserStatus) => this.subEditorChange.emit(status),
          navToFileCallback: (src: string) => this.navToFile.emit(src)
        }
      });
    }
    else if (this.fileType === CollabFileType.Edit) {
      this.dialog.open(ProjectFilesEditSnippetDialogComponent, {
        ...ProjectFilesEditSnippetDialogComponent.DialogConfig,
        data: {
          licenseUser: this.licenseUser,
          projectId: this.projectId,
          filePath: snippetSrc,
          branch: this.branchName,
          showConditions: this.showConditions,
          navToFileCallback: (src: string) => this.navToFile.emit(src)
        }
      })
        .afterClosed()
        .subscribe((commitResult: CommitFileDialogResult) => {
          if (commitResult) {
            this.textEditorService.setSnippetCommitId(commitResult.newFilePath, commitResult.newCommitId);
            this.textEditorService.refreshSnippet$.next(snippetSrc);
          }
        });
    }
  }

  onInsertProjectSnippetDialog(event: InsertSnippetEvent) {
    this.dialog.open<ProjectFilesAddSnippetDialogComponent, ProjectFilesAddSnippetDialogData, ProjectFilesAddSnippetDialogResult>(ProjectFilesAddSnippetDialogComponent, {
      ...ProjectFilesAddSnippetDialogComponent.DialogConfig,
      data: {
        commitId: this.commitId,
        projectId: this.projectId,
        branchName: this.branchName,
        filePath: this.filePath,
        schema: this.schema,
        debouncedReflowGutterLayout: this.debouncedReflowGutterLayout,
        metaData$: this.metaData$,
        viewPluginOverlay$: this.viewPluginOverlay$,
        editorScrollable: this.editorScrollable
      }
    }).afterClosed().subscribe((result: ProjectFilesAddSnippetDialogResult) => {
      if (result) {
        event.insertSnippetCommandCallback(result.path, result.isInlineContent);
      }
    })
  }

  onUndoClicked() {
    this.onDispatch(undo);
  }

  onRedoClicked() {
    this.onDispatch(redo);
  }

  onCutClicked() {
    if (document.queryCommandEnabled('cut')) {
      document.execCommand('cut');
    } else {
      this.openCutCopyPasteHelpDialog('cut');
    }
  }

  onCopyClicked() {
    if (document.queryCommandEnabled('copy')) {
      document.execCommand('copy');
    } else {
      this.openCutCopyPasteHelpDialog('copy');
    }
  }

  onPasteClicked() {
    if (document.queryCommandEnabled('paste')) {
      document.execCommand('paste');
    } else {
      this.openCutCopyPasteHelpDialog('paste');
    }
  }

  onDeleteClicked() {
    this.onDispatch(this.flareCommands.deleteSelection);
  }

  onSelectAllClicked() {
    this.onDispatch(selectAll);
  }

  onEditorContextMenuClosed() {
    // Return focus to the editor
    // Needs optional chaining since navigating to a file from the menu popup can destroy the editor view
    this.editorView?.focus();
  }

  reflowGutterLayout(force: boolean = true) {
    if (force) {
      this.flareFileTextEditorGutterComponent?.forceLayout();
    } else {
      this.flareFileTextEditorGutterComponent?.layout();
    }
  }

  findImageUploadPos(editorState: EditorState, id: string | number): number {
    const decos = ImageUploadPluginKey.getState(editorState);
    const found = decos?.find(null, null, spec => spec.id === id);
    return found?.length > 0 ? found[0].from : null;
  }
  /*
   * Used to update the image placeholder with the uploaded image. If it fails, either show the error in a new error placeholder or the error dialog.
   * Use the error dialog if the image came from the insert image command.
   * Use the error placeholder if the image came from the clipboard.
  */
  updateImages(insertImageOptions: InsertImageEvent[], showErrorDialog: boolean = false) {
    const errorActions: ImagePlaceholderAction[] = [];

    // Send the images to the server
    const uploadImages$ = insertImageOptions.map(option => {
      const image = new FormData();
      image.append('reviewImage', option.file);
      return this.textEditorService.uploadImage$(this.fileId, image).pipe(
        map(res => {
          const action: ImageAction = {
            id: option.guid,
            pos: null,
            url: res.Url,
            attrs: option.attrs
          }
          return action;
        }),
        catchError(error => {
          errorActions.push({ id: option.guid, errors: this.errorService.getErrorMessages(error) })
          return of(null);
        })
      );
    });
    forkJoin(uploadImages$).pipe(
      // Filter out the empty values left by error handling
      map(actions => actions.filter(action => !!action))
    ).subscribe(actions => {
      // Insert the image nodes together to group them in one transaction
      updateImagePlaceholders(actions)(this.editorState, this.editorView.dispatch);

      if (errorActions.length) {
        // Either displays the error placeholder in the editor or shows the error dialog
        if (!showErrorDialog) {
          insertImageUploadErrors(errorActions)(this.editorState, this.editorView.dispatch);
        } else {
          removeImagePlaceholders(errorActions)(this.editorState, this.editorView.dispatch);

          this.dialog.open(ErrorDialogComponent, {
            ...ErrorDialogComponent.DialogConfig,
            data: {
              title: 'Error Uploading Image',
              message: 'An unexpected error happened while uploading an image',
              errors: errorActions[0].errors
            }
          });
        }
      }
    });
  }

  updateGutterDecorations(gutterItem: GutterItem, group: string, removes: Partial<GutterPluginTargetItem>[] = [], selectItemInDoc: boolean = false) {
    const adds: GutterPluginTargetItem[] = [];
    const tr = this.editorState.tr;

    // Find the gutter item's target in the doc in order to update the gutter plugin's decorations
    if (gutterItem) {
      // Iterate through the doc finding the targets of the gutter item
      this.editorState.doc.descendants((node, pos) => {
        if (gutterItem.type === GutterItemType.Annotation) {
          // If this node is the gutter item's target
          if (node.attrs['MadCap:guid'] === gutterItem.id) {
            adds.push({
              from: pos,
              group: group,
              id: gutterItem.id,
              targetType: GutterPluginTargetType.Annotation,
              to: pos + node.nodeSize,
              type: GutterPluginTargetDecorationType.Inline
            });

            if (selectItemInDoc) {
              selectNodeContent(tr, node, pos);
            }
          }
        } else if (gutterItem.type === GutterItemType.Change) {
          // If this node is the gutter item's target
          if (node.attrs.changeIds === gutterItem.changeIds) {
            adds.push({
              from: pos,
              group,
              id: gutterItem.id,
              targetType: GutterPluginTargetType.Change,
              to: pos + node.nodeSize,
              type: GutterPluginTargetDecorationType.Node
            });

            if (selectItemInDoc) {
              selectNodeContent(tr, node, pos);
            }
          }

          // Check the node's marks for this change
          if (Array.isArray(node.marks)) {
            node.marks.forEach(mark => {
              // If this mark is the gutter item's target
              if (mark.type.name === this.schema.madcapChangeMarkName && mark.attrs.changeIds === gutterItem.changeIds) {
                adds.push({
                  from: pos,
                  group,
                  id: gutterItem.id,
                  targetType: GutterPluginTargetType.Change,
                  to: pos + node.nodeSize,
                  type: GutterPluginTargetDecorationType.Inline
                });

                if (selectItemInDoc) {
                  tr.setSelection(TextSelection.create(tr.doc, pos, pos + node.nodeSize));
                }
              }
            });
          }
        }
      });
    }

    if (adds.length > 0 || removes.length > 0) {
      tr.setMeta('gutter', {
        adds,
        removes
      });

      this.editorView.dispatch(tr);
    }
  }

  markAsPristine() {
    this.collabFileTextEditor?.markAsPristine();
    this.soloFileTextEditor?.markAsPristine();
  }

  save(): Promise<CollabSessionSaveResult> {
    return this.collabSession.save();
  }

  reload() {
    this.reloadDataSource.next(null);
    this.collabFileTextEditor?.reload();
  }

  openCutCopyPasteHelpDialog(action: 'copy' | 'cut' | 'paste') {
    this.dialog.open<CutCopyPasteHelpDialogComponent, CutCopyPasteHelpDialogData>(CutCopyPasteHelpDialogComponent, {
      ...CutCopyPasteHelpDialogComponent.DialogConfig,
      data: {
        action
      }
    });
  }

  onCreateCollabFile() {
    this.createCollabFile.emit();
  }

  getContent(): string {
    return this.soloFileTextEditor?.getContent();
  }

  private createPlugins$(): Observable<Plugin[]> {
    // If the file info (branchName, commitId, fileId, filePath, fileType, or projectId) OR the license user changes THEN the plugins need to be recreated
    // This is required because the text editor cannot reuse plugins from another file
    return combineLatest([
      // We only care about a license user change if the license user has changed to a different license user
      this.licenseUser$.pipe(
        filter(licenseUser => !!licenseUser),
        distinctUntilChanged(this.dataService.sameIdentity)
      ),
      this.projectId$, this.commitId$, this.filePath$, this.fileId$, this.fileType$,
      this.reload$
    ]).pipe(
      tap(() => { this.metaDataSource$.next(null); }), // Reset meta data before plugins created
      map(([licenseUser, projectId, commitId, filePath, fileId, fileType]) => {
        // Project files ('edit' file type) do not have ids. But its not used in the plugins so we can ignore it.
        const hasValidId = fileType === CollabFileType.Review ? Number.isInteger(fileId) : true;

        // If all the necessary properties exist then create a new set of plugins
        if (licenseUser && Number.isInteger(projectId) && commitId && filePath && fileType && hasValidId) {
          return this.textEditorService.createFlarePlugins(this.schema, {
            gutter: {
              onGutterItemsChanged: (items: GutterItem[], removed: GutterItem[], added: GutterItem[]) => {
                this.gutterItems$.next(items);
                this.showGutter = items?.length > 0;
                // Run detect changes to make the gutter update but wait until the gutter is showing and is its full width
                this.ngZone.onStable.asObservable().pipe(first()).subscribe(() => this.cdr.detectChanges());
              },
              schema: this.schema
            },
            pasteNormalizer: {
              onError: (error: Error) => {
                if (error.name === DocSizeError) {
                  this.snackBar.open(`Changes ignored because they exceed ${this.fileSizeService.format(this.textEditorService.flareTextEditorDocMaxCharLength, 0)} file size limit.`, 'OK');
                }
              }
            },
            trackedChanges: {
              enabled: this.trackedChanges,
              user: (): ChangeTrackerUser => {
                if (this.licenseUser) {
                  return {
                    Id: this.licenseUser.User.Id,
                    Initials: this.licenseUser.User.Initials,
                    UserName: this.licenseUser.User.FullName
                  };
                } else {
                  return null;
                }
              }
            },
            keymaps: { 'Mod-k': () => this.ngZone.run(() => this.flareFileTextEditorToolbarComponent.openPropertiesLinkDialog(this.editorState)) },
            metaData: { metaData$: this.metaData$ },
            metaDataLoader: this.textEditorService.createMetaDataLoaderPluginOptions(this.getCommittedItem(), this.metaDataSource$),
            selectionNormalizer: {
              selectParentForNodeNames: [
                'mcCentralContainer' // mc-central-container is hidden to the user so select its parent instead
              ]
            },
            view: { overlay$: this.viewPluginOverlay$, scrollable: this.editorScrollable },
            appliedConditions: this.getAppliedConditionsPluginOptions(),
            clipboardImageResolver: fileType === CollabFileType.Review ? {
              onPastedImages: insertImageOptions => {
                const errorActions: ImagePlaceholderAction[] = [];
                const unpackPromises$: Observable<InsertImageEvent>[] = insertImageOptions.map(option =>
                  // Check file validity
                  this.fileService.unpackImage$(option.file, MaxAttachmentSizeBytes, EditorImageExtensions).pipe(
                    map((fileInfo) => {
                      // If it ran successfully, use the original option
                      return fileInfo ? option : null;
                    }),
                    catchError(error => {
                      errorActions.push({ id: option.guid, errors: this.errorService.getErrorMessages(error) });
                      // Return non empty value to continue the join
                      return of(null);
                    })
                  )
                );
                forkJoin(unpackPromises$).pipe(
                  // Filter out the empty values left by error handling
                  map(insertImageOptions => insertImageOptions.filter(insertImageOptions => !!insertImageOptions))
                ).subscribe(insertImageOptions => {
                  if (errorActions.length) {
                    // Run the command to remove the upload placeholders
                    insertImageUploadErrors(errorActions)(this.editorState, this.editorView.dispatch);
                  }
                  // Upload images to server
                  this.updateImages(insertImageOptions);
                });
              }
            } : undefined,
            nodeToolbarPopup: {
              onEdit: (src) => {
                const resolvedPath = resolvePath(this.filePath, src);
                this.onOpenEditSnippetDialog(resolvedPath);
              },
              onOpen: (src) => {
                const resolvedPath = resolvePath(this.filePath, src);
                this.navToFile.emit(resolvedPath);
              },
              isEditable: (src) => {
                if (this.fileType === CollabFileType.Edit) {
                  return true;
                } else if (this.fileType === CollabFileType.Review) {
                  // Make sure that the file is included in the review package
                  const resolvedPath = resolvePath(this.filePath, src);
                  return !!this.reviewFilesService.getItemByProperties('Path', resolvedPath, 'ReviewPackageId', this.reviewPackageId);
                } else {
                  return false;
                }
              },
              isReadonly: this.readonly
            }
          });
        } else { // Else there are no plugins to create
          return null;
        }
      })
    );
  }

  private getAppliedConditionsPluginOptions(): AppliedConditionsPluginOptions {
    return {
      showConditions$: this.showConditions$,
      schema: this.schema,
      processAppliedConditions: (conditions) => {
        this.conditionsStyleManager.processAppliedConditions(conditions);
      }
    }
  }

  private collabNodeViews$(): Observable<Dictionary<NodeViewConstructor>> {
    return combineLatest([
      this.commitId$, this.filePath$, this.projectId$, this.fileId$,
      this.reload$
    ]).pipe(
      map(([commitId, filePath, projectId]) => {
        // If all the necessary properties exist then create a new set of node views
        if (commitId && filePath && Number.isInteger(projectId)) {
          return this.textEditorService.createCommonNodeViews(this.schema, filePath, projectId, this.commitId, this.debouncedReflowGutterLayout, this.metaData$, this.getAppliedConditionsPluginOptions(), this.viewPluginOverlay$, this.editorScrollable);
        } else { // Else there is no node view config to create
          return null;
        }
      })
    );
  }

  private soloNodeViews$(): Observable<Dictionary<NodeViewConstructor>> {
    return combineLatest([
      this.projectId$, this.branchName$, this.filePath$,
      this.reload$
    ]).pipe(
      map(([projectId, branchName, filePath]) => {
        // If all the necessary properties exist then create a new set of node views
        if (Number.isInteger(projectId) && branchName && filePath) {
          return this.textEditorService.createCommonNodeViews(this.schema, filePath, projectId, this.commitId, this.debouncedReflowGutterLayout, this.metaData$, this.getAppliedConditionsPluginOptions(), this.viewPluginOverlay$, this.editorScrollable);
        } else { // Else there is no node view config to create
          return null;
        }
      })
    );
  }

  private buildConditionalStyles(): void {
    const builders: BackgroundStylesBuilder[] = [];
    /* Add a style builder to create the background styles for the block elements: repeated inclined wide strips with fixed width and lightened colors */
    const blockDecoration = '.mc-madcap-condition-bg-decoration-block';
    let builder = new BackgroundStylesBuilder({
      gradientAngle: 120,
      repeatable: true,
      conditionLineWidth: 20,
      definitionSuffix: '-block',
      patchSelector: (conditionSelector) => `${conditionSelector}${blockDecoration}, mc-dynamic-view${blockDecoration} > ${conditionSelector}`,
      patchColor: (hexColor) => setColorLightness(hexColor, 90),
      unknownTagColor: '#777777' // TODO: grab the color from the theme (ThemeService)
    });
    builders.push(builder);

    /* Add a style builder to create the background styles for the inline elements: repeated inclined narrow strips with fixed width and lightened colors */
    const inlineDecoration = '.mc-madcap-condition-bg-decoration-inline';
    builder = new BackgroundStylesBuilder({
      gradientAngle: 120,
      repeatable: true,
      conditionLineWidth: 10,
      definitionSuffix: '-inline',
      patchSelector: (conditionSelector) => `${conditionSelector}${inlineDecoration}, mc-dynamic-view${inlineDecoration} > ${conditionSelector}`,
      patchColor: (hexColor) => setColorLightness(hexColor, 90),
      unknownTagColor: '#777777' // TODO: grab the color from the theme (ThemeService)
    });
    builders.push(builder);

    /* Add a style builder to create the background styles for the condition markers: not repeated vertical strips with original colors */
    const markerBeforeDecoration = '.mc-madcap-condition-bg-decoration-marker';
    const markerVariableDecoration = '.mc-madcap-condition-bg-decoration-marker>.mc-node-view>.mc-madcap-variable';
    const markerImageDecoration = ' .mc-madcap-condition-bg-decoration-marker>mc-image-node-view';
    const markerAfterDecoration = '.mc-madcap-condition-bg-decoration-marker-a';
    const loadingConditionCssSelector = `${markerBeforeDecoration}:before, ${markerVariableDecoration}:before, ${markerImageDecoration}:before, ${markerAfterDecoration}:after`;
    const loadingConditionAnimationName = 'topic-editor-condition-loading-animation';
    builder = new BackgroundStylesBuilder({
      gradientAngle: 90,
      definitionSuffix: '-marker',
      patchSelector: (selector) => `${selector}${markerBeforeDecoration}:before, ${markerVariableDecoration}${selector}:before, ${markerImageDecoration}${selector}:before, ${selector}${markerAfterDecoration}:after`,
      unknownTagColor: '#ffffff', // TODO: grab the color from the theme (ThemeService)
      loadingStylesBuilder: new LoadingConditionStylesBuilder(loadingConditionCssSelector, loadingConditionAnimationName)
    });
    builders.push(builder);
    this.conditionsStyleManager.init(...builders);
  }

  private getCommittedItem(): CommittedItem {
    return {
      commitId: () => this.commitId,
      fileId: () => this.fileId,
      fileType: () => this.fileType,
      projectId: () => this.projectId
    }
  }

  private loadMetaData(editorView: EditorView, editorState: EditorState) {
    const tr = editorState.tr;
    tr.setMeta('metaDataLoader.load', true);
    editorView.dispatch(tr);
  }

  onTrackedChangesToggle() {
    this.trackedChanges = !this.trackedChanges;
    this.trackedChangesChange.emit(this.trackedChanges);
  }

  onShowConditionsToggle() {
    this.showConditions = !this.showConditions;
    this.showConditionsChange.emit(this.showConditions);
  }

  scrollToTop() {
    this.editorScrollContainerRef?.nativeElement.scroll(0, 0);
  }
}
