import { ChangeAttrName } from '@common/flare/change-attr-name.enum';
import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { FlareXMLOutputSpec } from '@common/flare/util/prosemirror-flare-xml';
import { ChangeNodeAttrs } from '@common/prosemirror/changeset/change-node-attrs.type';
import { TrackedChangeList } from '@common/prosemirror/changeset/tracked-change.type';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttr, modifyDomOutputSpec } from '@common/util/schema';
import { AttributeSpec, DOMOutputSpec, Mark, MarkSpec, NodeSpec, ProseMirrorNode } from 'prosemirror-model';

const changeMarkName = 'madcapchange';

function createChangeClassNames(changeList: TrackedChangeList, type: 'mark' | 'node'): string {
  let classNames = changeList.reduce((names, change) => {
    // Do not include orphaned changes (changes without data, aka changes without a type)
    if (change.changeType) {
      return `${names} mc-madcap-change-${change.changeType}`;
    } else {
      return names;
    }
  }, '');

  // Check if any tracked change class names were added and if so add the generic tracked change class names
  // Its possible that the change list only includes orphaned changes in which case we do not want to add the tracked change class names
  if (classNames) {
    classNames = `mc-madcap-change mc-madcap-change-${type} ${classNames}`;
  }

  return classNames;
}

export class MadCapChangeSchemaPlugin extends SchemaPlugin {
  marks: Dictionary<MarkSpec> = {
    [changeMarkName]: {
      inclusive: false,
      attrs: {
        changeIds: { default: undefined, skipExport: true },
        changeList: { default: undefined, skipExport: true },
        [ChangeAttrName.ChangeIds]: {
          default: undefined,
          toFlareXML(value: any, options: ToFlareXMLOptions, mark: Mark): string {
            return options.trackedChangesExporter.extractChanges(mark) ?? value;
          }
        }
      },
      parseDOM: [{
        tag: 'MadCap\\:change',
        preserveWhitespace: true,
        getAttrs(dom: HTMLElement): Dictionary | false {
          const changeIds = getSchemaAttr(dom, ChangeAttrName.ChangeIds);
          if (changeIds) {
            return { changeIds };
          }
        }
      }],
      toDOM(mark: Mark): DOMOutputSpec {
        const attrs: ChangeNodeAttrs = mark.attrs;
        const changeList = attrs.changeList || [];

        return ['span', {
          class: createChangeClassNames(changeList, 'mark'),
          [ChangeAttrName.ChangeIds]: attrs.changeIds,
          'madcap-changes': attrs.changeIds // Kind of a duplicate of the above attr but kept for now so that code changes do not need to happen elsewhere atm
        }];
      },
      toFlareXML(mark: Mark, options: ToFlareXMLOptions): FlareXMLOutputSpec {
        // Tracked changes are stripped when exporting for the clipboard so return nothing when that is the case
        if (!options.exportForClipboard) {
          return {
            tagName: 'MadCap:change',
            attrs: mark.attrs
          };
        }
      },
      tagName: 'MadCap:change'
    }
  };

  props: Dictionary = {
    madcapChangeMarkName: changeMarkName
  };

  modifyNodesAndMarks(nodes: Dictionary<NodeSpec>, marks: Dictionary<MarkSpec>) {
    Object.entries(nodes).forEach(([name, nodeSpec]) => {
      if (name !== 'text') {
        // Add the default attributes for tracked changes
        nodeSpec.attrs = Object.assign<Dictionary<AttributeSpec>, Dictionary<AttributeSpec>>(nodeSpec.attrs || {}, {
          changeIds: { default: undefined, skipExport: true },
          changeList: { default: undefined, skipExport: true },
          [ChangeAttrName.ChangeIds]: {
            default: undefined,
            toFlareXML(value: any, options: ToFlareXMLOptions, node: ProseMirrorNode): string {
              if (!options.exportForClipboard) {
                return options.trackedChangesExporter.extractChanges(node) ?? value;
              }
            }
          }
        });
        nodeSpec.skippedTrackingAttributes = [...(nodeSpec.skippedTrackingAttributes ?? []), 'changeIds', 'changeList'];

        // Update the node's parse rules to read in the tracked changes attributes
        if (Array.isArray(nodeSpec.parseDOM)) {
          nodeSpec.parseDOM.forEach(parser => {
            const originalGetAttrs = parser.getAttrs;

            parser.getAttrs = function (dom: Element): Dictionary | false {
              let attrs: Dictionary | false = originalGetAttrs ? originalGetAttrs.apply(this, arguments) : {};

              if (attrs === false) {
                return false;
              }

              const changeIds = getSchemaAttr(dom, ChangeAttrName.ChangeIds);
              if (changeIds) {
                attrs = attrs ?? {}; // Ensure attrs exists
                attrs.changeIds = changeIds;
              }

              return attrs;
            };
          });
        }

        // Update the toDOM method to include the tracked changes classes
        const originalToDOM = nodeSpec.toDOM;
        nodeSpec.toDOM = function (node: ProseMirrorNode): DOMOutputSpec {
          const dom: DOMOutputSpec = originalToDOM.apply(this, arguments);
          const attrs: ChangeNodeAttrs = node.attrs;
          const changeList = attrs.changeList;

          // If there are tracked changes on this node
          if (Array.isArray(changeList) && changeList.length > 0) {
            // Then add some class names and a madcap-changes attribute
            modifyDomOutputSpec(dom, {
              attrs: {
                add: {
                  class: createChangeClassNames(changeList, 'node'),
                  'madcap-changes': attrs.changeIds
                }
              }
            });
          }

          return dom;
        };
      }
    });
  }
}
