import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { AlertDialogModule } from '@portal-core/general/components/alert-dialog/alert-dialog.module';
import { DimensionPickerModule } from '@portal-core/general/components/dimension-picker/dimension-picker.module';
import { FlareFileTextEditorFeatureUnsupportedDialogModule } from '@portal-core/project-files/components/flare-file-text-editor-feature-unsupported-dialog/flare-file-text-editor-feature-unsupported-dialog.module';
import { LinkPropertiesDialogModule } from '@portal-core/project-files/components/link-properties-dialog/link-properties-dialog.module';
import { MultimediaDialogModule } from '@portal-core/project-files/components/multimedia-dialog/multimedia-dialog.module';
import { ProjectFilesImageDialogModule } from '@portal-core/project-files/components/project-files-image-dialog/project-files-image-dialog.module';
import { ProjectFilesAddSnippetDialogModule } from '@portal-core/project-files/components/project-files-add-snippet-dialog/project-files-add-snippet-dialog.module';
import { ApplyConditionsDialogModule } from '@portal-core/project-files/conditions/components/apply-conditions-dialog/apply-conditions-dialog.module';
import { InsertVariableDialogModule } from '@portal-core/project-files/variables/components/insert-variable-dialog/insert-variable-dialog.module';
import { EditorToolbarModule } from '@portal-core/ui/editor/components/editor-toolbar/editor-toolbar.module';
import { FlareFileTextEditorToolbarComponent } from './flare-file-text-editor-toolbar.component';

@NgModule({
  declarations: [
    FlareFileTextEditorToolbarComponent
  ],
  imports: [
    AlertDialogModule,
    CommonModule,
    DimensionPickerModule,
    FlareFileTextEditorFeatureUnsupportedDialogModule,
    ProjectFilesImageDialogModule,
    ProjectFilesAddSnippetDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    EditorToolbarModule,
    InsertVariableDialogModule,
    LinkPropertiesDialogModule,
    ApplyConditionsDialogModule,
    MultimediaDialogModule
  ],
  exports: [
    FlareFileTextEditorToolbarComponent
  ]
})
export class FlareFileTextEditorToolbarModule { }
