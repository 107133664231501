import { Injectable } from '@angular/core';
import { FlareSchema } from '@common/flare/flare-schema';
import { FlareFileTextEditorFeatures } from '@portal-core/project-files/util/flare-file-text-editor-features';

@Injectable({
  providedIn: 'root'
})
export class FlareFileTextEditorService {
  private features: FlareFileTextEditorFeatures = new FlareFileTextEditorFeatures();

  supported(featureName: keyof FlareFileTextEditorFeatures, featureSetVersion: number): boolean {
    return this.features[featureName](featureSetVersion);
  }

  validate(content: string): Error {
    return new FlareSchema().validate(content);
  }
}
