import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@portal-core/util/window.provider';
import { from, map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrowserHostService {
  private initialized: boolean = false;

  constructor(@Inject(WINDOW) private window: Window) {}

  isCefSharpHosted(): boolean {
    // If the Flare hook is defined then run the code to emit the login event
    return typeof this.window.CefSharp?.BindObjectAsync !== 'undefined';
  }

  browserHost$(): Observable<BrowserHost> {
    return from(this.window.CefSharp.BindObjectAsync('mcBrowserHost')).pipe(
      // Initialize mcBrowserHost
      tap(() => {
        if (!this.initialized) {
          this.window.mcBrowserHost?.init?.();
          this.initialized = true;
        }
      }),
      map(() => this.window.mcBrowserHost)
    );
  }
}