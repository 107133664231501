import { ChangeDetectionStrategy, Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { SystemMessagesService } from '@portal-core/system-messages/services/system-messages.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mc-app-gutter',
  templateUrl: './app-gutter.component.html',
  styleUrls: ['./app-gutter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class AppGutterComponent implements OnInit {
  @HostBinding('class.mc-app-gutter-has-messages')
  get _hasMessages() {
    return this.hasMessages;
  }

  hasMessages = false;
  hasMessagesSubscription: Subscription;

  constructor(private systemMessagesService: SystemMessagesService) { }

  ngOnInit() {
    this.hasMessagesSubscription = this.systemMessagesService.getItems$().subscribe(messages => {
      this.hasMessages = messages && Object.values(messages).length > 0;
    });
  }
}
