import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnlineService {
  onlineSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(navigator.onLine);
  online$: Observable<boolean> = this.onlineSource.asObservable();

  get online(): boolean {
    return this.onlineSource.value;
  }

  constructor() {
    window.addEventListener('offline', event => {
      this.onlineSource.next(false);
    });

    window.addEventListener('online', event => {
      this.onlineSource.next(true);
    });
  }
}
