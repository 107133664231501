


<div class="mc-diff-content mc-border-divider">
  <mat-accordion #accordion="matAccordion" displayMode="flat" multi="true" class="accordion-flat">
    <mat-expansion-panel [expanded]="true" [disabled] = "disabledExpand"  *ngIf = "diff; else noDiffMsg">
      <mat-expansion-panel-header class = "mc-diff-header">
        <h3 class = "mc-diff-title">
          <span class="icon icon-documentation" *ngIf="showFilePath"></span>
          <span class="mc-diff-file-path mc-text-normal" *ngIf="showFilePath && !diff?.renamed_file">{{ diff?.new_path ||diff?.old_path }}</span>
          <span class="mc-diff-file-path mc-text-normal" *ngIf="showFilePath && diff?.renamed_file">{{diff?.old_path }} &#8594; {{diff?.new_path}}</span>
          <span *ngIf = "!checkForMoved(diff?.old_path, diff?.new_path); else movedPill">
            <span class="mc-pill mc-pill-x-small mc-notice-vivid"  *ngIf="diff?.new_file">NEW FILE</span>
            <span class="mc-pill mc-pill-x-small mc-warn-vivid" *ngIf="diff?.deleted_file">DELETED</span>
            <span class="mc-pill mc-pill-x-small mc-primary-vivid" *ngIf="diff?.renamed_file" >RENAMED</span>
            <span class="mc-pill mc-pill-x-small mc-caution-vivid"  *ngIf="!diff?.new_file && !diff?.deleted_file && !diff?.renamed_file">CHANGED</span>
          </span>
          <ng-template #movedPill>
            <span class="mc-pill mc-pill-x-small mc-info-vivid">MOVED</span>
          </ng-template>
        </h3>
      </mat-expansion-panel-header>
      <!--Normal case-->
      <div [innerHTML]="diffDOM$ | async | sanitizeHtml" *ngIf = "!showDiffLoadRequest && !showNoDiffMessage"></div>

      <!--diff too big -->
      <div class="mc-alt-diff-display" *ngIf="showDiffLoadRequest">
        <button class="btn btn-inline btn-link" (click) = "showDiffLoadRequest = false;" >Load Diff</button>
        Large diffs and commits are not shown by default.
      </div>

      <!-- don't show diff. -->
      <div class="mc-alt-diff-display" *ngIf="showNoDiffMessage">
        File contents were not changed.
      </div>
    </mat-expansion-panel>

    <!-- Diff doesn't exist.  -->
    <ng-template #noDiffMsg>
      <mat-expansion-panel [expanded] = "false" [disabled] = "true" *ngIf = "diff$ | async">
        <mat-expansion-panel-header class = "mc-diff-header">
          <h3 class = "mc-diff-title">
            No diff to show.
          </h3>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </ng-template>
  </mat-accordion>
</div>

