import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { TextEditorModule } from '@portal-core/text-editor/components/text-editor/text-editor.module';
import { CollabFileTextEditorComponent } from './collab-file-text-editor.component';

@NgModule({
  declarations: [
    CollabFileTextEditorComponent
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    TextEditorModule
  ],
  exports: [
    CollabFileTextEditorComponent
  ]
})
export class CollabFileTextEditorModule { }
