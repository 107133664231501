import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { nodeToFlareXML } from '@common/flare/util/prosemirror-flare-xml';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { ProseMirrorNode } from 'prosemirror-model';

export class MadCapCentralCDataSchemaPlugin extends SchemaPlugin {
  constructor() {
    super();

    this.nodes = {
      madcapcentralcdata: {
        group: 'inline',
        inline: true,
        attrs: {
          content: { default: undefined, skipExport: true }
        },
        parseDOM: [{
          tag: 'MadCap\\:centralCData',
          getAttrs(dom: HTMLElement) {
            return {
              content: dom.textContent
            };
          }
        }],
        toDOM() { return ['span', { class: 'mc-madcap-central-cdata icon-star' }]; },
        toFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
          if (options.exportForClipboard) {
            return nodeToFlareXML(node, options, null, {
              xml: `<MadCap:centralCData>${node.attrs.content}</MadCap:centralCData>`
            });
          } else {
            return nodeToFlareXML(node, options, null, {
              xml: `<![CDATA[${node.attrs.content}]]>`
            });
          }
        },
        toDisplayName() { return '#cdata'; },
        specSkip: true
      }
    };
  }
}
