import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { SearchQueryReport } from '@portal-core/output-analytics/models/output-analytics-search-query-report.model';

@CollectionStateSelectors({
  stateType: SearchQueryReportState
})
@State<SearchQueryReportState>({
  name: SearchQueryReportState.source,
})
@Injectable()
export class SearchQueryReportState extends CollectionStateBase {
  static source = 'SearchQueryReports';
  getSource(): string {
    return SearchQueryReportState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SearchQueryReportDataService extends CollectionDataServiceBase<SearchQueryReport> {
  constructor(protected store: Store) {
    super(store, SearchQueryReportState);
  }
}
