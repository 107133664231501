<mat-nav-list class="mc-app-drawer-nav-list mc-list-bg-dark">
  <a mat-list-item href="#" routerLink="home" routerLinkActive="mc-list-item-selected" *mcIfAuthor>
    <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-home'"></mat-icon> Home
  </a>
  <a mat-list-item href="#" routerLink="projects" routerLinkActive="mc-list-item-selected" *mcIfAuthor>
    <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-folder'"></mat-icon> Projects
  </a>
  <a mat-list-item href="#" routerLink="analytics" routerLinkActive="mc-list-item-selected" *mcIfAuthor>
    <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-edit-curves'"></mat-icon> Analytics
  </a>
  <a mat-list-item href="#" (click)="onNavClick('sites')" routerLink="sites" routerLinkActive="mc-list-item-selected" *mcIfAuthor>
    <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-world-2'"></mat-icon> Sites
    <ng-template mcHasVisited="sites" [licenseId]="currentLicenseId$ | async" [clicked$]="navClicked$">
      <mc-app-nav-has-visited-chip class="mc-app-drawer-nav-list-new"></mc-app-nav-has-visited-chip>
    </ng-template>
  </a>
  <a mat-list-item href="#" routerLink="reviews" routerLinkActive="mc-list-item-selected">
    <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-review'"></mat-icon> Reviews
  </a>
  <a mat-list-item href="#" routerLink="tasks" routerLinkActive="mc-list-item-selected" *mcIfAuthor>
    <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-pin'"></mat-icon> Tasks
  </a>
  <a mat-list-item href="#" routerLink="teams" routerLinkActive="mc-list-item-selected" *mcIfAuthor>
    <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-team'"></mat-icon> Teams
  </a>
  <a mat-list-item href="#" routerLink="users" routerLinkActive="mc-list-item-selected" *mcIfAuthor>
    <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-user'"></mat-icon> Users
  </a>
</mat-nav-list>