import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { CoreService } from '@portal-client/core/core.service';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { first, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LicenseDefaultPageResolverService implements Resolve<string> {
  constructor(private coreService: CoreService, private router: Router, private authService: AuthService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    if (!this.authService.isAuthenticated()) {
      return of(null);
    }

    return this.coreService.getLicenseUser$().pipe(
      first(licenseUser => !!licenseUser),
      map(licenseUser => {
        let routePath: string;

        switch (licenseUser.SeatType) {
          case LicenseUserSeatType.SME:
            routePath = 'reviews';
            break;
          case LicenseUserSeatType.Author:
          default:
            routePath = 'home';
            break;
        }

        this.router.navigate([licenseUser.LicenseId, routePath]);
        return routePath;
      })
    );
  }
}
