<mc-sub-navbar></mc-sub-navbar>

<main class="page mc-page-fill">
  <router-outlet></router-outlet>

  <aside class="page-card page-gutter" *ngIf="(showPageGutter | async)?.matches">
    <router-outlet name="app-gutter"></router-outlet>
    <router-outlet name="page-gutter"></router-outlet>
  </aside>
</main>

<button mat-icon-button class="app-logo" (click)="onCentralLogoClicked()" *ngIf="(showAppNav | async)?.matches">
  <mat-icon [fontSet]="'project-titan'" [fontIcon]="'icon-central'" class="central-logo"></mat-icon>
</button>
<router-outlet name="app-nav" *ngIf="(showAppNav | async)?.matches"></router-outlet>