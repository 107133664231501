import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavbarHelpButtonModule } from '@portal-core/layout/components/navbar-help-button/navbar-help-button.module';
import { FullNavbarComponent } from './full-navbar.component';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    NavbarHelpButtonModule,
    RouterModule
  ],
  declarations: [
    FullNavbarComponent
  ],
  exports: [
    FullNavbarComponent
  ]
})
export class FullNavbarModule { }
