import { getSchemaAttr, hasSchemaAttr } from '@common/util/schema';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';
import { SchemaPlugin } from '../../prosemirror/model/schema-plugin';

export class ListSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    bullet_list: {
      group: 'block',
      content: 'list_item+',
      childLinkBucket: 'unordered_list',
      list: true,
      parseDOM: [{
        tag: 'ul'
      }],
      toDOM() { return ['ul', 0]; },
      tagName: 'ul',
      specContent: 'list_item'
    },

    ordered_list: {
      group: 'block',
      content: 'list_item+',
      childLinkBucket: 'ordered_list',
      list: true,
      attrs: {
        start: {
          default: 1,
          toFlareXML(value: any): string {
            return value !== 1 ? value : null;
          }
        }
      },
      parseDOM: [{
        tag: 'ol',
        getAttrs(dom: HTMLElement) {
          return {
            start: hasSchemaAttr(dom, 'start') ? +getSchemaAttr(dom, 'start') : 1
          };
        }
      }],
      toDOM(node: ProseMirrorNode) {
        return node.attrs.start === 1 ? ['ol', 0] : ['ol', { start: node.attrs.start }, 0];
      },
      tagName: 'ol',
      specContent: 'list_item'
    },

    list_item: {
      content: 'block+',
      definingForContent: true, // Not definingForContext so that list items can be pasted over
      fillIfContentDeleted: 'mcCentralContainer',
      listItem: true,
      parseDOM: [{
        tag: 'li'
      }],
      toDOM() { return ['li', 0]; },
      tagName: 'li',
      specContext: 'bullet_list'
    }
  };
}
