import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { CustomInputBase } from '@portal-core/ui/forms/util/custom-input-base.directive';
import { PopupComponent } from '@portal-core/ui/popup/components/popup/popup.component';
import { PopupTriggerDirective } from '@portal-core/ui/popup/directives/popup-trigger/popup-trigger.directive';
import { CssLengthPopupComponent } from '@portal-core/ui/style-picker/components/css-length-popup/css-length-popup.component';
import { Subscription } from 'rxjs';
@Component({
  selector: 'mc-style-picker-input',
  templateUrl: './style-picker-input.component.html',
  styleUrls: ['./style-picker-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '[class.mc-style-picker-input-disabled]': 'disabled',
    '[class.mc-text-disabled]': 'disabled'
  },
  // tslint:disable-next-line: no-inputs-metadata-property
  inputs: ['disabled', 'tabIndex'],
  providers: [
    { provide: MatFormFieldControl, useExisting: StylePickerInputComponent }
  ]
})
export class StylePickerInputComponent extends CustomInputBase<string> implements OnInit, OnDestroy {
  /** Required by CustomInputBase */
  controlType: string = 'mc-style-picker-input';

  /** The MatFormField's label. */
  @Input() label: string;
  /** The presets to select that can be used for the MatFormField's input. */
  @Input() presets: string[];

  @ViewChild('textInput', { static: true, read: ElementRef }) textInputRef: ElementRef<HTMLInputElement>;
  @ViewChild(CssLengthPopupComponent, { static: true }) cssLengthPopup: CssLengthPopupComponent;
  @ViewChild('popup', { static: true }) popup: PopupComponent;
  @ViewChild('trigger', { static: true, read: PopupTriggerDirective }) popupTrigger: PopupTriggerDirective;

  /** Binds to the input field to observe value changes and to set the value. */
  textControl = new FormControl('');
  textControlSubscription: Subscription;

  /* if ok clicked in the popup then dont reset the popup on close */
  okClicked: boolean = false;

  /** Returns true if style picker input is empty. It is considered empty if no value selected. */
  get empty(): boolean {
    return !this.value;
  }

  /** Required by MatFormFieldControl */
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.textControlSubscription.unsubscribe();
  }

  ngOnInit() {
    this.textControl.setValue(this.value);
    this.textControlSubscription = this.textControl.valueChanges.subscribe(value => this.setValueFromUI(value));
  }

  constructor(
    _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public _parentForm: NgForm,
    @Optional() public _parentFormGroup: FormGroupDirective,
    @Optional() @Self() public ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    protected focusMonitor: FocusMonitor
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, cdr, focusMonitor);
  }

  /** Gives focus to the style picker input. */
  focus() {
    this.textInputRef.nativeElement.focus();
  }

  /** Required by CustomInputBase */
  getDefaultPlaceholder(): string {
    return '';
  }

  /** Required by CustomInputBase */
  getFocusableElementRef(): ElementRef<HTMLElement> {
    return this.textInputRef;
  }

  /** Required by MatFormFieldControl */
  onContainerClick(event: MouseEvent): void {
    this.focus();
  }

  onCancelClicked() {
    this.popup.close();
  }

  onOKClicked() {
    this.textControl.setValue(this.cssLengthPopup.value);
    this.okClicked = true;
    this.popup.close();
  }

  public openPopup() {
    this.popupTrigger.open();
  }

  public onPopupClosed() {
    if (this.okClicked) {
      this.okClicked = false;
    } else {
      this.cssLengthPopup.updateCSSLengthFormControls(this.value);
    }
  }

  /** Sets the value on the style picker input and notifies Angular Forms of the changes.  */
  setValueFromUI(value: string) {
    this.value = value;
    this.onChange(this.value); // So Angular Forms know this control's value has changed
    this.onTouched(); // So Angular Forms know this control has been touched
  }
}
