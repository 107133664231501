import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ExcludeAction } from '@common/prosemirror/commands/conditions.command';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ProjectConditionSet } from '@portal-core/project-files/conditions/models/project-condition-set.model';
import { ProjectConditionTag } from '@portal-core/project-files/conditions/models/project-condition-tag.model';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { Observable, Subscription } from 'rxjs';

export interface ApplyConditionsDialogData {
  selectedConditions?: string[];
  selectedExcludeAction?: string;
  projectConditionSets$: Observable<ProjectConditionSet[]>;
}

@Component({
  selector: 'mc-apply-conditions-dialog',
  templateUrl: './apply-conditions-dialog.component.html',
  styleUrls: ['./apply-conditions-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class ApplyConditionsDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '90rem',
    height: '60rem',
    restoreFocus: false
  };

  /** The selected condition set. */
  activeConditionSet: ProjectConditionSet;
  /** Tracks the subscription to load the conditions data. */
  private conditionSetsSubscription: Subscription;
  /** The selected exclude action. */
  excludeAction: string;
  /** The exclude actions to display as radio buttons in the UI. */
  protected readonly excludeActions = Object.values(ExcludeAction);
  /** The state of loading the condition sets. */
  loadingState: LoadingState<string> = new LoadingState<string>();
  /** The condition sets. */
  projectConditionSets: ProjectConditionSet[];
  /** The selected conditions. */
  selectedConditions: string[];
  /** The display text for the set that includes all the condition tags. */
  protected readonly showAllTagsSetName: string = '(show all tags)';
  protected readonly undefinedTagsSetName: string = '(undefined tags)';
  undefinedTagsNames: string[];

  constructor(
    protected dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) protected data: ApplyConditionsDialogData,
    protected dialogRef: MatDialogRef<ApplyConditionsDialogComponent>,
    private errorService: ErrorService
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();

    this.loadingState.update(true);

    this.selectedConditions = this.data.selectedConditions ?? [];
    this.excludeAction = this.excludeActions.find(x => x === this.data.selectedExcludeAction);

    this.conditionSetsSubscription = this.data.projectConditionSets$.subscribe(sets => {
      if (sets) {
        sets.sort((a, b) => a.Name.localeCompare(b.Name));

        const allTags = sets.flatMap(x => x.ConditionTags);
        this.undefinedTagsNames = this.selectedConditions.filter(selected => !allTags.some(x => x.Id === selected));
        const undefinedTags = this.undefinedTagsNames.map(x => <ProjectConditionTag>{ Id: x });

        const showAllTagsSet: ProjectConditionSet = {
          Name: this.showAllTagsSetName,
          ConditionTags: [...undefinedTags, ...allTags]
        };
        const undefinedTagsSet: ProjectConditionSet[] = undefinedTags.length ? [{
          Name: this.undefinedTagsSetName,
          ConditionTags: [...undefinedTags]
        }] : [];

        this.projectConditionSets = [showAllTagsSet, ...undefinedTagsSet, ...sets];

        this.loadingState.update(false);
      } else {
        this.loadingState.update(false, 'The condition data for the project is still being generated. Please try again soon.');
      }

      this.onLoaded();
    }, error => {
      this.loadingState.update(false, 'Unable to load the project\'s conditions.', this.errorService.getErrorMessages(error));
    });
  }

  onApplyClicked() {
    this.closeDialog({ conditions: this.selectedConditions, action: this.excludeAction });
  }

  onConditionSelected(selectedConditionIds: string[]) {
    this.selectedConditions = [...selectedConditionIds];
  }

  onConditionSetSelected(selectedSet: ProjectConditionSet) {
    this.activeConditionSet = selectedSet;
  }
}
