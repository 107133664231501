import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { StopPropagationModule } from '@portal-core/general/directives/stop-propagation/stop-propagation.module';
import { SanitizeHtmlModule } from '@portal-core/general/pipes/html/sanitize-html.module';
import { ProjectCommitDiffComponent } from './project-commit-diff.component';

@NgModule({
  declarations: [
    ProjectCommitDiffComponent
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatButtonModule,
    MatButtonToggleModule,
    StopPropagationModule,
    SanitizeHtmlModule
  ],
  exports: [
    ProjectCommitDiffComponent
  ]
})
export class ProjectCommitDiffModule { }
