import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

/**
 * ParagraphSchemaPlugin
 */
export class ParagraphSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    paragraph: {
      group: 'block',
      linkBucket: 'paragraph',
      content: 'inline*',
      parseDOM: [{ tag: 'p' }],
      tagName: 'p',
      toDOM() { return ['p', 0]; }
    }
  };
}
