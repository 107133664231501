import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FlareSchema } from '@common/flare/flare-schema';
import { FoundNodeInfo, findNode } from '@common/prosemirror/model/node';
import { firstOrNull, makeArray } from '@common/util/array';
import { basename, dirname, isAbsoluteUrl, isRelativeUrl, relative, resolvePath } from '@common/util/path';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { DocumentBucketNode } from '@portal-core/project-files/components/document-node-selector-input/document-node-selector-input.component';
import { TopicFileFilter } from '@portal-core/project-files/constants/file-filters.constants';
import { ProjectFilesService } from '@portal-core/project-files/services/project-files.service';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { LoadingState } from '@portal-core/util/loading-state';
import { ProseMirrorNode } from 'prosemirror-model';
import { Subscription, catchError, first, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export interface LinkPropertiesDialogData {
  commitId: string;
  projectId: number;
  currentFilePath: string;
  content: string | ProseMirrorNode;
  href: string;
  target: string;
  alt: string;
  class: string;
  id: string;
  tabIndex: number;
  document: ProseMirrorNode;
  title: string;
  selectionJustText: boolean;
  usingCrossRef?: boolean;
  contentIsLink?: boolean;
}

export interface LinkPropertiesDialogResult {
  attrs: Dictionary;
  content: string | ProseMirrorNode;
  linkToNode: FoundNodeInfo;
  selectionJustText: boolean;
  foundBookmark: boolean;
}

interface TargetOption {
  text: string;
  value: string;
}

interface LinkPropertiesDialogForm {
  linkTo: FormControl<string>,
  linkText: FormControl<string>,
  externalLink: FormControl<string>,
  target: FormControl<string>,
  alt: FormControl<string>,
  class: FormControl<string>,
  ID: FormControl<string>
  tabindex: FormControl<number>,
  title: FormControl<string>,
  node: FormControl<DocumentBucketNode>
}
@Component({
  selector: 'mc-link-properties-dialog',
  templateUrl: './link-properties-dialog.component.html',
  styleUrls: ['./link-properties-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkPropertiesDialogComponent extends DialogBase implements OnInit, OnDestroy {
  static DialogConfig: MatDialogConfig = {
    width: '85rem',
    maxWidth: '85rem',
    maxHeight: '57rem'
  };

  linkForm: FormGroup<LinkPropertiesDialogForm>;
  loadingState: LoadingState<string> = new LoadingState<string>();
  link: string;
  TopicFileFilter: string = TopicFileFilter;
  linkToVal: string;
  filePaths: string[] = [];
  selectedBookmarkId: string = '';
  fileName: string;
  commitId: string;
  projectId: number;
  schema: FlareSchema = new FlareSchema();
  usingCrossRef: boolean;
  selectionJustText: boolean;
  linkToSubscription: Subscription;

  targetOptions: TargetOption[] = [
    { text: 'Parent Frame', value: '_parent' },
    { text: 'New Window/Tab', value: '_blank' },
    { text: 'Same Frame', value: '_self' },
    { text: 'Top Frame', value: '_top' },
    { text: 'Popup Window', value: '_popup' },
  ];

  constructor(
    protected dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: LinkPropertiesDialogData,
    protected dialogRef: MatDialogRef<LinkPropertiesDialogComponent>,
    private fb: UntypedFormBuilder,
    private errorService: ErrorService,
    private projectFilesService: ProjectFilesService
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.commitId = this.data.commitId;
    this.projectId = this.data.projectId;
    this.usingCrossRef = this.data.usingCrossRef;
    this.selectionJustText = this.data.selectionJustText;

    this.setLinkToValAndFilePath(this.data.href);
    this.createLinkForm();

    // sets the external input either enabled or disabled.
    this.linkToSubscription = this.linkForm.controls.linkTo.valueChanges.subscribe(linkToVal => {
      const externalLinkControl = this.linkForm.controls.externalLink;
      this.linkToVal = linkToVal;
      if (linkToVal === 'inProject' || linkToVal === 'inDocument') {
        externalLinkControl.disable();
      } else {
        externalLinkControl.enable();
      }
    });

  }

  ngOnDestroy() {
    this.linkToSubscription.unsubscribe();
  }

  get filePath(): string {
    return firstOrNull(this.filePaths) ?? '';
  }

  set filePath(value: string) {
    this.filePaths = makeArray(value);
  }

  onSubmit() {
    const linkForm = this.linkForm.controls;
    const externalLink = linkForm.externalLink.value;
    const target = linkForm.target.value;
    const alt = linkForm.alt.value;
    const Class = linkForm.class.value;
    const ID = linkForm.ID.value;
    const tabindex = linkForm.tabindex.value;
    const title = linkForm.title.value;
    const documentNode = linkForm.node.value;
    let linkText = linkForm.linkText.value;
    let foundBookmark = false;

    // assign link
    let link = '#';
    let node = documentNode?.node;
    if (this.linkToVal === 'inProject') {
      node = null;
      link = this.link;
    } else if (this.linkToVal === 'inDocument') {
      const bookmarkNode = this.getBookmarkNode(node);
      if (bookmarkNode) {
        foundBookmark = true;
        link += bookmarkNode.attrs.name;
      } else {
        link += this.createBookmarkId(node.textContent, documentNode.topOfTheDocument);
      }
    } else {
      link = isAbsoluteUrl(externalLink) ? externalLink : 'http://' + externalLink;
    }

    // assign other values
    const linkInfo: LinkPropertiesDialogResult = {
      attrs: { target, alt, tabindex, title, href: link, class: Class, id: ID },
      // linkToNode is only needed if inDocument is selected
      linkToNode: node ? documentNode : null,
      selectionJustText: this.data.selectionJustText,
      content: null,
      foundBookmark
    };

    // if selection is just text the content is just a string(for cross ref its always a string) if not then its code
    // special case is we are not editing a hyperlink then we also just get text
    if (this.usingCrossRef) {
      linkInfo.content = this.getLinkText(externalLink, linkForm.node.value);
    } else if (this.selectionJustText || (this.data.contentIsLink && !this.data.href)) {
      linkInfo.content = !linkText ? this.getLinkText(externalLink, linkForm.node.value) : linkText;
    } else {
      linkInfo.content = this.data.content;
    }

    // if using cross ref and getting the topic, instead of the fileName, we need the content of the first node in that topic
    if (this.usingCrossRef && this.linkToVal === 'inProject') {
      this.loadingState.update(true);
      this.projectFilesService.getFirstNodeText$(this.projectId, this.filePath, this.commitId).pipe(
        first(),
        catchError((error) => { this.loadingState.update(false, 'Could not load link details. File may be missing or corrupt.', this.errorService.getErrorMessages(error)); return of(null); })).
        subscribe(
          textContent => {
            //use the fileName if there is no valid first child with text in this file.
            linkInfo.content = textContent.length ? textContent : this.fileName;
            this.closeDialog(linkInfo);
          }
        )
    } else {
      this.closeDialog(linkInfo);
    }
  }

  private getLinkText(externalLink: string, bucketNode: DocumentBucketNode): string {
    switch (this.linkToVal) {
      case 'inProject':
        return this.fileName;
      case 'inDocument': {
        const topOfTheDocument = bucketNode.topOfTheDocument;
        const node = bucketNode.node;
        const parent = bucketNode.parent;
        const nodeToUse = node.type.name === 'madcapbookmark' ? parent : node;
        return topOfTheDocument && !this.usingCrossRef ? 'top' : this.formatVariablesAndRemoveOtherTags(nodeToUse);
      }
      case 'external':
        return externalLink;
    }
  }

  private formatVariablesAndRemoveOtherTags(node: ProseMirrorNode): string {
    let code = this.schema.nodeToCode(node);
    // format variables
    code = code.replace(/<MadCap:variable + name="([^"]*)" +[^>]*\/>/g, (match, varName) => `[%=${varName}%]`);
    // remove all other tags
    code = code.replace(/<[^>]*>/g, () => '');

    // use only first word if its a normal link
    if (!this.usingCrossRef) {
      code = code.split(' ')[0];
    }
    return code;
  }

  private getBookmarkNode(node: ProseMirrorNode): ProseMirrorNode {
    let bookmarkNode;

    if (node.type.spec.linkBucket === 'bookmark') {
      bookmarkNode = node;
    } else {
      node.forEach(childNode => {
        const childNodeTypeName = childNode.type.name;
        const grandChildTypeName = childNode.firstChild?.type.name;
        if (childNodeTypeName === 'madcapbookmark') {
          bookmarkNode = childNode;
        } else if (childNodeTypeName === 'mcCentralContainer' && grandChildTypeName === 'madcapbookmark') {
          bookmarkNode = childNode.firstChild;
        }
      });
    }

    return bookmarkNode;
  }

  protected createLinkForm() {
    this.linkForm = this.fb.group({
      linkTo: new FormControl(this.linkToVal),
      linkText: new FormControl({ value: typeof this.data.content === 'string' ? this.data.content : '', disabled: !this.selectionJustText || this.usingCrossRef }),
      externalLink: new FormControl({ value: this.linkToVal === 'external' ? this.data.href : '', disabled: this.linkToVal !== 'external' }, [Validators.required]),
      target: new FormControl(this.data.target),
      alt: new FormControl(this.data.alt),
      class: new FormControl({ value: this.data.class, disabled: this.usingCrossRef }),
      ID: new FormControl({ value: this.data.id, disabled: this.usingCrossRef }),
      tabindex: new FormControl({ value: this.data.tabIndex, disabled: this.usingCrossRef }),
      title: new FormControl(this.data.title),
      node: new FormControl(this.findSelectedBookmarkNode())
    });
  }

  onFilePathSelected(filePaths: string[]) {
    this.filePaths = filePaths;
    const filePath = this.filePath;
    if (filePath === this.data.currentFilePath) {
      this.link = '#';
    }
    else if (filePath) {
      this.link = relative(dirname(this.data.currentFilePath), filePath);
    }
    this.fileName = basename(filePath);
  }

  private setLinkToValAndFilePath(href: string) {
    if (href && isRelativeUrl(href)) {
      // link to bookmark
      if (href.length > 1 && href.charAt(0) === '#') {
        this.linkToVal = 'inDocument';
        // remove the #
        this.selectedBookmarkId = href.slice(1);
      }
      // link to itself
      else if (href.charAt(0) === '#') {
        this.linkToVal = 'inProject';
        this.filePath = this.data.currentFilePath;
      }
      else {
        this.linkToVal = 'inProject';
        this.filePath = resolvePath(this.data.currentFilePath, href);
      }
    } else if (href && isAbsoluteUrl(href)) {
      this.linkToVal = 'external';
    } else {
      this.linkToVal = 'inProject';
    }
  }

  private findSelectedBookmarkNode(): DocumentBucketNode {
    if (this.selectedBookmarkId) {
      const bodyNodeInfo = findNode(this.data.document, node => node.type.name === 'body');
      const topOfTheDocumentNode = bodyNodeInfo?.node?.firstChild;

      let checkedTopOfTheDocumentNodeInfo;
      // try to find the bookmark in the node that is in the top of the document.
      topOfTheDocumentNode?.forEach(node => {
        if (checkedTopOfTheDocumentNodeInfo) return;
        if (node.attrs.name === this.selectedBookmarkId) {
          checkedTopOfTheDocumentNodeInfo = { parent: bodyNodeInfo.node, node: topOfTheDocumentNode, pos: bodyNodeInfo.pos + 1, topOfTheDocument: true };
        }
      });
      // Top of the document
      if (checkedTopOfTheDocumentNodeInfo) {
        return checkedTopOfTheDocumentNodeInfo;
      } else {
        // normal node
        return findNode(this.data.document, node => node.attrs.name === this.selectedBookmarkId);
      }
    }
  }

  private createBookmarkId(nodeTextContent: string, topOfTheDocument: boolean): string {
    // [first word of content]_[five digits of uuidv4]
    return topOfTheDocument ? 'top_' + uuidv4().substring(0, 5) : nodeTextContent.split(' ')[0] + '_' + uuidv4().substring(0, 5);
  }
}

