import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { FlareFileTextEditorGutterModule } from '@portal-core/project-files/components/flare-file-text-editor-gutter/flare-file-text-editor-gutter.module';
import { FlareFileTextEditorTagbarModule } from '@portal-core/project-files/components/flare-file-text-editor-tagbar/flare-file-text-editor-tagbar.module';
import { FlareFileTextEditorToolbarModule } from '@portal-core/project-files/components/flare-file-text-editor-toolbar/flare-file-text-editor-toolbar.module';
import { CollabFileTextEditorStatusBarModule } from '@portal-core/text-editor/components/collab-file-text-editor-status-bar/collab-file-text-editor-status-bar.module';
import { CollabFileTextEditorModule } from '@portal-core/text-editor/components/collab-file-text-editor/collab-file-text-editor.module';
import { CutCopyPasteHelpDialogModule } from '@portal-core/text-editor/components/cut-copy-paste-help-dialog/cut-copy-paste-help-dialog.module';
import { DynamicViewModule } from '@portal-core/text-editor/components/dynamic-view/dynamic-view.module';
import { SoloFileTextEditorStatusBarModule } from '@portal-core/text-editor/components/solo-file-text-editor-status-bar/solo-file-text-editor-status-bar.module';
import { SoloFileTextEditorModule } from '@portal-core/text-editor/components/solo-file-text-editor/solo-file-text-editor.module';
import { FlareTextEditorComponent } from './flare-text-editor.component';
import { PathDirnameModule } from '@portal-core/general/pipes/path-dir-name/path-dir-name.module';

@NgModule({
  declarations: [
    FlareTextEditorComponent
  ],
  imports: [
    CollabFileTextEditorModule,
    CollabFileTextEditorStatusBarModule,
    CommonModule,
    DynamicViewModule,
    CutCopyPasteHelpDialogModule,
    FlareFileTextEditorGutterModule,
    FlareFileTextEditorTagbarModule,
    FlareFileTextEditorToolbarModule,
    MatDialogModule,
    MatDividerModule,
    MatMenuModule,
    MatSnackBarModule,
    PathDirnameModule,
    ScrollingModule,
    SoloFileTextEditorModule,
    SoloFileTextEditorStatusBarModule
  ],
  exports: [
    FlareTextEditorComponent
  ]
})
export class FlareTextEditorModule { }
