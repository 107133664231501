import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SystemMessagesService } from '@portal-core/system-messages/services/system-messages.service';
import { isEmpty } from 'lodash';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'mc-app-drawer-gutter',
  templateUrl: './app-drawer-gutter.component.html',
  styleUrls: ['./app-drawer-gutter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppDrawerGutterComponent implements OnInit {
  hasMessages$: Observable<boolean>;

  constructor(private systemMessagesService: SystemMessagesService) { }

  ngOnInit() {
    this.hasMessages$ = this.systemMessagesService.getItems$().pipe(
      map(messages => !isEmpty(messages))
    );
  }
}
