<button type="button" mat-icon-button #menuTrigger [matMenuTriggerFor]="dimensionPicker" [matMenuTriggerRestoreFocus]="false" [matTooltip]="tooltip" (menuOpened)="onMenuOpened()">
  <mat-icon fontSet="project-titan" [fontIcon]="icon"></mat-icon>
</button>

<mat-menu class="mc-dimension-picker" #dimensionPicker="matMenu" (closed)="onDropdownMenuClosed($event)">
  <div mat-menu-item class="mc-dimension-picker-container" tabindex="0" (keydown)="onKeyDown($event)">
    <div class="mc-dimension-picker-mousecatcher" (mousemove)="onPositionChange($event)" (click)="onPositionClicked()"></div>

    <!-- Can't reference external svg file to avoid a duplicate def, it won't let you style individual shapes because it's a separate shadow dom. -->
    <svg class="mc-dimension-picker-highlighted" [style.width]="2 * dimensions?.width + 'rem'" [style.height]="2 * dimensions?.height + 'rem'">
      <defs>
        <pattern id="highlighted-squares" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
          <rect class="mc-dimension-picker-square" x="2" y="2" width="15" height="15" stroke-width="1" />
        </pattern>
      </defs>

      <rect x="0" y="0" width="100%" height="100%" fill="url(#highlighted-squares)" />
    </svg>

    <svg class="mc-dimension-picker-unhighlighted" [style.width]="2 * dimensions?.width + 2 + 'rem'" [style.height]="2 * dimensions?.height + 2 + 'rem'">
      <defs>
        <pattern id="unhighlighted-squares" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
          <rect class="mc-dimension-picker-square" x="2" y="2" width="15" height="15" stroke-width="1" />
        </pattern>
      </defs>

      <rect x="0" y="0" width="100%" height="100%" fill="url(#unhighlighted-squares)" />
    </svg>
    <div class="mc-dimension-picker-status">{{dimensions?.width}} x {{dimensions?.height}}</div>
  </div>
</mat-menu>