import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec } from 'prosemirror-model';

export class MadCapRelatedTopicsSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcaprelatedtopics: {
      group: 'inline',
      inline: true,
      content: 'madcaprelatedtopic*',
      atom: true,
      isLink: true,
      definingForContent: true,
      parseDOM: [{
        tag: 'MadCap\\:relatedTopics'
      }],
      toDOM() { return ['span', { class: 'mc-madcap-related-topics' }, 'Related Topics']; },
      tagName: 'MadCap:relatedTopics',
      specContent: 'madcaprelatedtopic'
    },

    madcaprelatedtopic: {
      inline: true,
      attrs: {
        'src': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:relatedTopic',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['src']);
        }
      }],
      isVoid: true,
      tagName: 'MadCap:relatedTopic',
      specContext: 'madcaprelatedtopics'
    }
  };
}
