import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { TargetsState } from '@portal-core/targets/services/targets-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([TargetsState])
  ]
})
export class TargetsServiceModule { }
