<form class="mc-spring-board" [formGroup]="newCommitForm" (ngSubmit)="onSubmit()">
  <mat-toolbar class="mc-spring-board-header" color="secondary">
    Create New Commit
    <span class="mc-fill-remaining-flex-space"></span>
    <button type="button" mat-icon-button color="primary" tabindex="-1" (click)="closeDialog()">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"> </mat-icon>
    </button>
  </mat-toolbar>

  <div class="mc-spring-board-form-body">
    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>File Path</mat-label>
      <mc-project-file-path-input #projectFilePathInput formControlName="file-path" [projectId]="projectId" [branchName]="branchName"></mc-project-file-path-input>
      <button matSuffix mat-icon-button type="button" (click)="projectFilePathInput.openPopup()" color="secondary" matTooltip="Select location for file in project" [matTooltipDisabled]="projectFilePathInput.disabled" [disabled]="projectFilePathInput.disabled">
        <mat-icon fontSet="project-titan" fontIcon="icon-meatballs-solid"></mat-icon>
      </button>
      <mat-error>
        <mc-form-control-error-message [form]="newCommitForm" controlName="file-path" type="mc_file_path"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Commit Message</mat-label>
      <textarea matInput class="mc-commit-file-dialog-textarea" formControlName="commit" placeholder="Commit Message" required rows="3"></textarea>
      <mat-error>
        <mc-form-control-error-message [form]="newCommitForm" controlName="commit" type="mc_commit_message"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>
    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="closeDialog()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="!newCommitForm.valid">Commit</button>
    </div>
  </div>
  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>