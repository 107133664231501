import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProjectReportStatisticsState } from './project-report-statistics-data.service';
import { NgxsModule } from '@ngxs/store';

import { ProjectReportBrokenLinksState } from './project-reports-broken-links-data.service';
import { ProjectReportsUndefinedConditionTagsDataState } from './project-reports-undefined-condition-tags-data.service';
import { ProjectReportsUndefinedFileTagsState } from './project-reports-undefined-file-tags-data.service';
import { ProjectReportsUndefinedGlossaryTermsState } from './project-reports-undefined-glossary-terms-data.service';
import { ProjectReportsUndefinedVariablesState } from './project-reports-undefined-variables-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      ProjectReportBrokenLinksState,
      ProjectReportsUndefinedConditionTagsDataState,
      ProjectReportsUndefinedFileTagsState,
      ProjectReportsUndefinedGlossaryTermsState,
      ProjectReportsUndefinedVariablesState,
      ProjectReportStatisticsState])
  ]
})
export class ReportsServicesModule { }
