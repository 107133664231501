<div class="mc-project-files-tree-item-preview-project-tree-container">
  <p class="mc-form-label">Project {{fileTypeLabel}}</p>
  <mc-project-files-tree class="mc-project-files-tree-item-preview-project-tree mc-border-divider" [treeData]="{projectId, branchName: commitId}" rootFolder="Content" [fileFilter]="treeFileFilter" [defaultPath]="defaultPath" [filePath]="initialFilePath" (fileSelected)="onProjectFileSelected($event)"></mc-project-files-tree>
</div>
<div class="mc-project-files-tree-item-preview-container">
  <p class="mc-form-label">Preview</p>
  <div class="mc-project-files-tree-item-preview mc-border-divider" [ngSwitch]="fileType">
    <mc-solo-file-text-editor *ngSwitchCase="ProjectFileType.Flare" class="mc-project-files-tree-item-preview-scroll-container" [class.mc-visibly-hidden-out-of-flow]="!previewSrc && (previewLoadingState.loading$ | async) || (previewLoadingState.generalError$ | async)" [content]="content$ | async" [readonly]="true" [schema]="schema" [plugins]="metaDataPlugins" [nodeViews]="nodeViews"></mc-solo-file-text-editor>

    <img *ngSwitchCase="ProjectFileType.Image" [class.mc-visibly-hidden-out-of-flow]="!previewSrc && (previewLoadingState.loading$ | async) || (previewLoadingState.generalError$ | async)" [src]="previewSrc" alt="Image Preview" (load)="onPreviewLoad()" (error)="onPreviewError()">

    <div *ngSwitchDefault [class.mc-hidden]="!previewSrc">No Preview</div>

    <mc-loader *ngIf="previewLoadingState.loading$ | async"></mc-loader>
    <mc-error-code-expansion-panel *ngIf="previewLoadingState.generalError$ | async as generalErrorCode" [generalErrorCode]="generalErrorCode"></mc-error-code-expansion-panel>
  </div>
</div>