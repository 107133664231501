import { Injectable } from '@angular/core';
import { CoreService } from '@portal-client/core/core.service';
import { Observable } from 'rxjs';
/**
 * UserSettingService
 * Used by portal-core components to get data from local storage.
 */
@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  constructor(private coreService: CoreService) { }

  // Settings
  getSettingByName<T>(name: string): T {
    return this.coreService.getSettingByName(name);
  }

  getSettingByName$<T = any>(name: string): Observable<T> {
    return this.coreService.getSettingByName$(name);
  }

  setSettingByName$(name: string, value: any): Observable<any> {
    return this.coreService.setSettingByName$(name, value);
  }
}
