import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { LicenseStorageState } from '@portal-core/license-storage/services/license-storage-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([LicenseStorageState])
  ]
})
export class LicenseStorageServicesModule { }
