import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { nodeToFlareXML } from '@common/flare/util/prosemirror-flare-xml';
import { NodeType } from '@common/html/enums/node-type.enum';
import { getAttrs } from '@common/html/util/dom';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';
import { tableNodes } from 'prosemirror-tables';

export interface TableStructureItem {
  nodeName: string;
  id?: string;
  data?: string | { name: string, attrs: Dictionary<string> };
}

export const MadCapTableStructureAttrName = 'MadCap:tableStructure';
export const MadCapTableRowGroupIdAttrName = 'MadCap:tableRowGroupId';
export const MadCapTableRowGroupTypeAttrName = 'MadCap:tableRowGroupType';
export const MadCapTableCaptionIdAttrName = 'MadCap:tableCaptionId';
export const MadCapTableColumnIdAttrName = 'MadCap:tableColumnId';

export interface TableColumnsAttrs {
  [MadCapTableStructureAttrName]: TableStructureItem[];
}

export class TableSchemaPlugin extends SchemaPlugin {
  constructor() {
    super();

    // Default to central container over paragraph
    const cellContent = 'mcCentralContainer | block+';
    const tableNodeSpecs = tableNodes({
      cellAttributes: null,
      cellContent: cellContent,
      tableGroup: 'block'
    });

    // Do not delete empty table cells
    tableNodeSpecs.table_cell.defining = true;
    tableNodeSpecs.table_cell.linkBucket = 'table_content';
    tableNodeSpecs.table_cell.defaultContentToNbsp = true;
    tableNodeSpecs.table_header.defining = true;
    tableNodeSpecs.table_header.linkBucket = 'table_content';
    tableNodeSpecs.table_header.defaultContentToNbsp = true;

    // Customize the cells
    tableNodeSpecs.table_cell.attrs.colspan.toFlareXML = function (value: number): string {
      return value !== 1 ? value.toString() : undefined;
    };
    tableNodeSpecs.table_cell.attrs.rowspan.toFlareXML = function (value: number): string {
      return value !== 1 ? value.toString() : undefined;
    };
    tableNodeSpecs.table_cell.tagName = 'td';
    tableNodeSpecs.table_cell.specContext = 'table_row';

    tableNodeSpecs.table_header.attrs.colspan.toFlareXML = function (value: number): string {
      return value !== 1 ? value.toString() : undefined;
    };
    tableNodeSpecs.table_header.attrs.rowspan.toFlareXML = function (value: number): string {
      return value !== 1 ? value.toString() : undefined;
    };
    tableNodeSpecs.table_header.tagName = 'th';
    tableNodeSpecs.table_header.specContext = 'table_row';

    this.nodes = Object.assign<Dictionary<NodeSpec>, Dictionary<NodeSpec>>(tableNodeSpecs, {
      table: {
        group: 'block',
        tableRole: 'table',
        isolating: true,
        // Modify the table node to support more tags
        content: '(thead | tbody | table_row | tfoot | caption | colgroup | col)*',
        attrs: {
          [MadCapTableStructureAttrName]: { default: undefined, skipExport: true }
        },
        parseDOM: [{
          tag: 'table',
          getAttrs(dom: HTMLElement) {
            const tableStructure: TableStructureItem[] = [];

            const rows: Node[] = [];
            let generatedId = 0;

            // Direct children of the html node are removed from the document (except for the body) and added to the tableStructure array
            // Loop in reverse order because we are removing nodes
            for (let i = dom.childNodes.length - 1; i >= 0; i -= 1) {
              const node = dom.childNodes[i];

              if (node.nodeName === 'tbody' || node.nodeName === 'tfoot' || node.nodeName === 'thead') {
                const id = (++generatedId).toString();
                tableStructure.push({ nodeName: node.nodeName, id, data: { name: node.nodeName, attrs: getAttrs(node as Element) } });
                // Grab each row and add its group's id to it as an attribute so that the row can be put back into the correct group in toFlareXML
                // Do this in reverse because the row sections are pushed in reverse as well. This keeps the rows in the correct order once they are reversed below
                for (let j = node.childNodes.length - 1; j >= 0; j -= 1) {
                  const row = node.childNodes[j];
                  if (row.nodeName === 'tr') {
                    (row as Element).setAttribute(MadCapTableRowGroupIdAttrName, id);
                    (row as Element).setAttribute(MadCapTableRowGroupTypeAttrName, node.nodeName);
                    rows.push(row);
                  }
                }
                node.remove();
              } else if (node.nodeName === 'tr') {
                // Grab the row and add its group's id to it as an attribute so that the row can be put back into the correct location in toFlareXML
                const id = (++generatedId).toString();
                tableStructure.push({ nodeName: node.nodeName, id });
                rows.push(node);
                (node as Element).setAttribute(MadCapTableRowGroupIdAttrName, id);
                (node as Element).setAttribute(MadCapTableRowGroupTypeAttrName, node.nodeName);
                node.remove();
              } else if (node.nodeName === 'caption') {
                // Add an id to the caption so that it can be put back in the correct location in toFlareXML
                const id = (++generatedId).toString();
                tableStructure.push({ nodeName: node.nodeName, id });
                (node as Element).setAttribute(MadCapTableCaptionIdAttrName, id);
              } else if (node.nodeName === 'colgroup' || node.nodeName === 'col') {
                // Add an id to the colgroup/col so that it can be put back in the correct location in toFlareXML
                const id = (++generatedId).toString();
                tableStructure.push({ nodeName: node.nodeName, id });
                (node as Element).setAttribute(MadCapTableColumnIdAttrName, id);
              } else if (node.nodeType === NodeType.ELEMENT_NODE) {
                tableStructure.push({ nodeName: node.nodeName, data: (node as Element).outerHTML });
                node.remove();
              } else if (node.nodeType === NodeType.TEXT_NODE) {
                // Only add the text node if it more than just whitespace
                if (node.nodeValue.trim().length > 0) {
                  tableStructure.push({ nodeName: node.nodeName, data: node.nodeValue });
                }
                node.remove();
              } else if (node.nodeType === NodeType.COMMENT_NODE || node.nodeType === NodeType.CDATA_SECTION_NODE) {
                tableStructure.push({ nodeName: node.nodeName, data: node.nodeValue });
                node.remove();
              }
            }

            // Add the rows to the table in a new tbody
            const tbody = dom.ownerDocument.createElement('tbody');
            if (rows.length > 0) {
              tbody.append(...rows.reverse()); // Reverse the array so that the order is correct (since they were processed in reverse to begin with)
            }
            dom.append(tbody);

            return {
              [MadCapTableStructureAttrName]: tableStructure.reverse() // Reverse the array so that the order is correct (since they were processed in reverse to begin with)
            };
          }
        }],
        toDOM() { return ['table', 0]; }, // Original ['table', ['tbody', 0]];
        toFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
          const schema = node.type.schema;
          const tableGroupRowsById: Dictionary<ProseMirrorNode[]> = {};
          const captionsById: Dictionary<ProseMirrorNode> = {};
          const columnsById: Dictionary<ProseMirrorNode> = {};
          let strayRowsAtStartOfTable: ProseMirrorNode[] = [];
          let lastTableGroup: ProseMirrorNode[] = strayRowsAtStartOfTable;

          // Loop through the children mapping them to the correct group
          for (let i = 0; i < node.childCount; i += 1) {
            const tableChild = node.child(i);

            if (tableChild.type.name === 'tbody') {
              for (let rowIndex = 0; rowIndex < tableChild.childCount; rowIndex += 1) {
                const child = tableChild.child(rowIndex);

                if (child.type.name === 'table_row') {
                  const tableRowGroupId: string = child.attrs[MadCapTableRowGroupIdAttrName];

                  if (tableRowGroupId) {
                    let tableRowGroup = tableGroupRowsById[tableRowGroupId];
                    if (!tableRowGroup) {
                      tableRowGroup = tableGroupRowsById[tableRowGroupId] = [];
                    }
                    tableRowGroup.push(child);
                    lastTableGroup = tableRowGroup;
                  } else {
                    lastTableGroup.push(child);
                  }
                }
              }
            } else if (tableChild.type.name === 'caption') {
              captionsById[tableChild.attrs[MadCapTableCaptionIdAttrName]] = tableChild;
            } else if (tableChild.type.name === 'colgroup' || tableChild.type.name === 'col') {
              columnsById[tableChild.attrs[MadCapTableColumnIdAttrName]] = tableChild;
            }
          }

          const tableStructure: TableStructureItem[] = node.attrs[MadCapTableStructureAttrName];

          // Pushes the stray rows onto the children array and then clears out the stray rows.
          // We want these rows to be the first rows in the table so they need to be inserted right before the rows from the table structure.
          // We could just put them at the start of the children array but we want new rows to appear next to existing rows instead of before colgroups, cols, or whatever the table structure is.
          const pushStrayRowsAtStartOfTable = () => {
            // If there are stray table rows, then add them in a new tbody
            if (strayRowsAtStartOfTable?.length > 0) {
              children.push(schema.nodes['tbody'].create(null, strayRowsAtStartOfTable));
              // Clear out the strays now that they have been added. This function can be called multiple times so we don't want to add the rows again
              strayRowsAtStartOfTable = null;
            }
          };

          const children: (string | ProseMirrorNode)[] = [];
          tableStructure?.forEach(item => {
            if (item.nodeName === 'tbody' || item.nodeName === 'tfoot' || item.nodeName === 'thead') {
              // Add the stray rows right before the first set of rows
              pushStrayRowsAtStartOfTable();
              // Create the table group with the rows as its content
              const node = schema.nodes[item.nodeName].create(null, tableGroupRowsById[item.id]);
              (node as any).attrs = (item.data as any).attrs;
              children.push(node);
            } else if (item.nodeName === 'tr') {
              // Add the stray rows right before the first set of rows
              pushStrayRowsAtStartOfTable();
              children.push(tableGroupRowsById[item.id]?.[0]);
            } else if (item.nodeName === 'caption') {
              children.push(captionsById[item.id]);
            } else if (item.nodeName === 'colgroup' || item.nodeName === 'col') {
              children.push(columnsById[item.id]);
            } else {
              children.push(item.data as string);
            }
          });

          // In case the table started empty we need to add the stray rows now because the loop above didn't add them
          pushStrayRowsAtStartOfTable();

          return nodeToFlareXML(node, options, 'table', { children });
        }
      },
      colgroup: {
        group: 'block',
        tableRole: 'table',
        content: 'col*',
        attrs: {
          span: { default: undefined },
          [MadCapTableColumnIdAttrName]: { default: undefined, skipExport: true }
        },
        parseDOM: [{
          tag: 'colgroup',
          getAttrs(dom: HTMLElement) {
            const attrs: Dictionary = getSchemaAttrs(dom, ['span']);

            if (typeof attrs['span'] === 'string') {
              attrs['span'] = parseInt(attrs['span'], 10);
            }

            return attrs;
          }
        }],
        toDOM() { return ['colgroup', { class: 'mc-pm-colgroup' }, 0]; },
        specContext: 'table',
        atom: true,
        selectable: false
      },
      col: {
        group: 'block',
        content: 'inline*',
        attrs: {
          span: { default: undefined },
          [MadCapTableColumnIdAttrName]: { default: undefined, skipExport: true }
        },
        parseDOM: [{
          tag: 'col',
          getAttrs(dom: HTMLElement) {
            const attrs: Dictionary = getSchemaAttrs(dom, ['span']);

            if (typeof attrs['span'] === 'string') {
              attrs['span'] = parseInt(attrs['span'], 10);
            }

            return attrs;
          }
        }],
        toDOM() { return ['col', { class: 'mc-pm-col' }, 0]; },
        specContext: 'colgroup',
        isVoid: true,
        selectable: false
      },
      caption: {
        group: 'block',
        tableRole: 'table',
        linkBucket: 'table_content',
        content: cellContent,
        attrs: {
          [MadCapTableCaptionIdAttrName]: { default: undefined, skipExport: true }
        },
        parseDOM: [{
          tag: 'caption',
          context: 'table'
        }],
        toDOM() { return ['caption', { class: 'mc-pm-caption' }, 0]; },
        specContext: 'table'
      },
      thead: { // Keep for use in tag info path
        group: 'block',
        tableRole: 'table',
        content: 'table_row*',
        parseDOM: [{
          tag: 'thead',
          context: 'table'
        }],
        toDOM() { return ['thead', { class: 'mc-pm-thead' }, 0]; },
        specContext: 'table'
      },
      tbody: {
        group: 'block',
        tableRole: 'table',
        content: 'table_row*',
        parseDOM: [{
          tag: 'tbody',
          context: 'table'
        }],
        toDOM() { return ['tbody', { class: 'mc-pm-tbody' }, 0]; },
        specContext: 'table'
      },
      tfoot: { // Keep for use in tag info path
        group: 'block',
        tableRole: 'table',
        content: 'table_row*',
        linkBucket: 'table_content',
        parseDOM: [{
          tag: 'tfoot',
          context: 'table'
        }],
        toDOM() { return ['tfoot', { class: 'mc-pm-tfoot' }, 0]; },
        specContext: 'table'
      },
      table_row: {
        content: '(table_cell | table_header)*',
        tableRole: 'row',
        attrs: {
          [MadCapTableRowGroupIdAttrName]: { default: undefined, skipExport: true },
          [MadCapTableRowGroupTypeAttrName]: { default: undefined, skipExport: true }
        },
        parseDOM: [{
          tag: 'tr',
          getAttrs(dom: HTMLElement) {
            return getSchemaAttrs(dom, [MadCapTableRowGroupIdAttrName]);
          }
        }],
        toDOM() { return ['tr', 0]; },
        tagName: 'tr',
        specContext: 'tbody'
      }
    });
  }
}
