import { Injectable } from '@angular/core';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { Observable, Subject, fromEvent, map, merge, of, startWith, switchMap, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdleLogoutService {
  private idleTimerSubject = new Subject<boolean>();

  maxIdleTimeMilliseconds: number;
  warningOffsetMilliseconds = 1000 * 60; // One Minute

  idleTimer$ = this.idleTimerSubject.asObservable();

  // Expose a method which enables us to explicity emit next from this.activityEvents$, effectivly extending the timer manually
  extendIdleTimer: Function;

  activityEvents$ = merge(
    fromEvent(window, 'mousemove', { capture: true, passive: true }),
    fromEvent(document, 'mousedown', { capture: true, passive: true }),
    fromEvent(window, 'resize', { capture: true, passive: true }),
    fromEvent(document, 'keydown', { capture: true, passive: true }),
    fromEvent(document, 'touchstart', { capture: true, passive: true }),
    Observable.create((observer) => this.extendIdleTimer = () => observer.next()) // When this.extendIdleTimer is called it will reset this.idleTimer$
  ).pipe(
    startWith(true)
  );

  constructor(private authService: AuthService) { }

  /**
   * Verifies that the user is logged in and that there is a valid maxIdleTime
   * If this is the case creates an Observable.timer which is reset every time this.activityEvents$ emits
   * If the timer runs out before the above happens signal subscribers of this.idleTimer of this.
   *
   * If the user is logged in and there is no valid maxIdleTime cancel the Observable, this means
   * the user is not subject to maxIdleTime
   */
  initIdleTimer() {
    this.authService.isAuthenticated$().pipe(
      // need to be logged in in order to get maxIdleTime. Ether way combine both values for next step
      map(isAuthenticated => {
        if (isAuthenticated) {
          return { isAuthenticated: isAuthenticated, maxIdleTimeMinutes: this.authService.getMaxIdleTimeMinutes() };
        } else {
          return { isAuthenticated: false, maxIdleTimeMinutes: 0 };
        }
      }),
      switchMap(configInfo => {
        // if not logged in don't test maxIdleTime, it wont be an actual value
        if (!configInfo.isAuthenticated) {
          return of(null);
          // if logged in and maxIdleTime, then set this.maxIdleTime and pass this.activityEvents to the next step where they will be needed
        } else if (typeof configInfo.maxIdleTimeMinutes === 'number' && configInfo.maxIdleTimeMinutes > 0) {
          this.maxIdleTimeMilliseconds = 1000 * 60 * configInfo.maxIdleTimeMinutes;
          return this.activityEvents$;
          // If the user is logged in and maxIdleTime doesn't pass the conditions in the else if, then the user isn't subject to idleLogout.
          // in this case complete the idleTimerSubject so any external subscriptions to this.idleTimer$ are cancelled
        } else {
          return of(null);
        }
      }),
      switchMap(activityEvents => {
        // Need to set up a timer, make it maxIdleTime less warningOffset because this timer only counts down to when the user
        // is warned they will be logged out. The timer emits the number 0 if it counts all the way down
        if (activityEvents) {
          return timer(this.maxIdleTimeMilliseconds - this.warningOffsetMilliseconds);
        } else {
          return of(null);
        }
      }),
      // Finally, if the timer has expired the above switch map will return 0, emit idleTimerSubject
      map((timerExists) => {
        if (typeof timerExists === 'number') {
          this.idleTimerSubject.next(true);
        }
      })
    ).subscribe();
  }
}
