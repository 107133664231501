<div class="mc-spring-board">
  <mat-toolbar class="mc-spring-board-header" color="secondary">
    Insert Snippet
    <span class="mc-fill-remaining-flex-space"></span>
    <button mat-icon-button color="primary" tabindex="-1" (click)="closeDialog()" [mat-dialog-close]="false">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
    </button>
  </mat-toolbar>

  <div class="mc-spring-board-form-body mc-project-files-add-snippet-dialog-container">
    <mc-project-files-tree-item-preview #snippetPreview [commitId]="commitId" [projectId]="projectId" [branchName]="branchName" [treeFileFilter]="treeFileFilter" [schema]="schema" [debouncedReflowGutterLayout]="debouncedReflowGutterLayout" [metaData$]="metaData$" [viewPluginOverlay$]="viewPluginOverlay$" [editorScrollable]="editorScrollable" [defaultPath]="ProjectFolder.Snippets + '/'"></mc-project-files-tree-item-preview>
    <div class="mat-form-field-subscript-wrapper">
      <span class="mat-error" [class.mc-hidden]="snippetPreview.selectedFilePath !== filePath">Selected snippet file is the same as the file opened.</span>
    </div>
  </div>

  <div class="mc-spring-board-footer">
    <button mat-stroked-button (click)="closeDialog()">Cancel</button>
    <button mat-raised-button (click)="onInsertClicked(snippetPreview.selectedFilePath)" color="primary" [disabled]="!snippetPreview.previewSrc || snippetPreview.selectedFilePath === filePath || (snippetPreview.previewLoadingState.loading$ | async) || (snippetPreview.previewLoadingState.generalError$ | async)">Insert</button>
  </div>
</div>