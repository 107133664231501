<form class="mc-spring-board" [formGroup]="AIForm" #formDirective="ngForm" mcStopPropagation="click">
  <mat-toolbar class="mc-spring-board-header" color="secondary">
    AI Assist
    <span class="mc-fill-remaining-flex-space"></span>
    <button type="button" mat-icon-button color="primary" tabindex="-1" (click)="closeDialog()">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"> </mat-icon>
    </button>

  </mat-toolbar>
  <ng-container *ngIf="license.OpenAIKey; else instructions">
    <div class="mc-spring-board-form-body mc-project-files-ai-dialog-messages-list" #messagesList>

      <div class="mc-project-files-ai-dialog-messages-instructions">
        <ng-container *ngIf="content && messages.length === 1">
          <p>
            Use the field below to tell AI Assist how to edit the selected text.
          </p>
        </ng-container>
        <ng-container *ngIf="!content && !messages.length">
          <p>
            Use the field below to generate content using AI Assist.
          </p>
        </ng-container>
      </div>

      <div *ngFor="let message of messages; let first = first ; let last = last" class="mc-project-files-ai-dialog-messages-item">
        <mat-toolbar class="mc-toolbar-normal mc-project-files-ai-dialog-messages-toolbar" color="secondary-invert">
          <ng-container *ngIf="(content && !first) || !content ; else selectedText">
            <span class="mc-toolbar-text" [matTooltip]="message.AI ? null : licenseUser.User.FullName" matTooltipClass="mc-tooltip-snug">
              <span class="mc-toolbar-text-truncate text-heavy-weight">{{message.AI ? 'AI Assist' : licenseUser.User.FullName}}</span>
            </span>
            <span class="mc-toolbar-spacer-x-small"></span>
            <span class="text-light-weight text-small">{{message.createdOn}}</span>
          </ng-container>

          <ng-template #selectedText>
            <span class="text-heavy-weight">Selected Text</span>
          </ng-template>

        </mat-toolbar>

        <!--Only appears on the last message -->
        <mat-progress-bar *ngIf="(loadingState.loading$ | async) && last" mode="indeterminate" color="task-low-priority"></mat-progress-bar>

        <!--Only appears on everything but the last message -->
        <ng-container *ngIf="!((loadingState.loading$ | async) && last)">
          <mat-tab-group #messageTabGroup *ngIf="message.AI; else userMessage" color="basic-muted" class="mc-arrow-tabs" [ngClass]="{'mc-arrow-tabs-border': !messageTabGroup.selectedIndex}">
            <mat-tab label="Content">
              <div class="mc-tab-container">
                <mc-flare-text-editor #flareTextEditor class="mc-project-files-ai-dialog-editor" [branchName]="branchName" [commitId]="commitId" [content]="message.body | mcMarkdownToFlareXML | mcNormalizeFlareXML" [filePath]="filePath" [fileType]="collabFileType.Edit" [licenseUser]="licenseUser" [projectId]="projectId" [projectLanguage]="projectLanguage" [showAI]="false" [showTrackedChanges]="false" [showConditions]="showConditions" (showConditionsChange)="onShowConditionsChanged($event)"></mc-flare-text-editor>
              </div>
            </mat-tab>

            <mat-tab *ngIf="content" label="Diff">
              <div class="mc-tab-container mc-project-files-ai-dialog-editor">
                <mc-project-commit-diff [diff]="flareTextEditor.getEditorState() | mcFlareCode | mcDiff : content" view="vertical" autoLoad="all"></mc-project-commit-diff>
              </div>
            </mat-tab>

            <mat-tab disabled>
              <ng-template mat-tab-label>
                <button type="button" mat-flat-button color="primary-vivid" (click)="onInsertOrReplace(flareTextEditor)">
                  {{content ? 'Replace' : 'Insert' }}
                </button>
              </ng-template>
            </mat-tab>
          </mat-tab-group>

          <ng-template #userMessage>
            <mc-flare-text-editor class="mc-project-files-ai-dialog-editor-user mc-border-divider" *ngIf="first && content" [showTagbar]="false" [branchName]="branchName" [commitId]="commitId" [content]="message.body | mcNormalizeFlareXML" [filePath]="filePath" [fileType]="collabFileType.Edit" [readonly]="true" [licenseUser]="licenseUser" [projectId]="projectId" [projectLanguage]="projectLanguage" [showAI]="false" [showTrackedChanges]="false"></mc-flare-text-editor>
            <span *ngIf="(content && !first) || !content" class="text-light-weight mc-project-files-ai-dialog-user-message">
              {{message.body}}
            </span>
          </ng-template>
        </ng-container>

      </div>
    </div>

    <div class="mc-spring-board-form-footer">

      <mat-form-field class="mc-form-field" color="primary-vivid" appearance="outline">
        <mat-label>Write a Message</mat-label>
        <textarea class="mc-project-files-ai-dialog-input" formControlName="prompt" matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" (keydown.enter)="fetchOpenAIResponse($event)"></textarea>
        <a matSuffix mat-icon-button type="button" color="primary-vivid" matTooltip="Help" target="_blank" href="https://documentation.madcapsoftware.com/central/Default.htm#cshid=AI_Assist_Examples">
          <mat-icon fontSet="project-titan" fontIcon="icon-circle-help"></mat-icon>
        </a>

        <button matSuffix mat-icon-button color="primary-vivid" type="button" [matTooltip]="(loadingState.loading$ | async) ? 'Cancel' : 'Send'" (click)="fetchOpenAIResponse()" [disabled]="!AIForm?.value?.prompt && !(loadingState.loading$ | async)">
          <mat-icon fontSet="project-titan" [fontIcon]="(loadingState.loading$ | async )? 'icon-close' : 'icon-submit'"></mat-icon>
        </button>

      </mat-form-field>

      <mc-errors-expansion-panel compact [showIcon]="false" [generalError]="loadingState.generalError$ | async" [detailedErrors]="loadingState.detailedErrors$ | async" class="mc-error-message"></mc-errors-expansion-panel>
    </div>


  </ng-container>

  <ng-template #instructions>
    <div class="mc-spring-board-form-body mc-project-files-ai-dialog-instructions">
      <p>
        Click <span class="text-heavy-weight">License Settings</span> then select <span class="text-heavy-weight">AI Assist</span> to setup API key
      </p>
      <p class="mc-project-files-ai-dialog-instructions-link">
        <a target="_blank" href="https://documentation.madcapsoftware.com/central/Default.htm#cshid=AI_Assist">AI Assist Documentation</a>
      </p>
    </div>
  </ng-template>
</form>