import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { cache } from '@common/util/cache.operator';
import { CommitFileDialogComponent } from '@portal-core/commits/components/commit-file-dialog/commit-file-dialog.component';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { ProjectFlareFileComponent } from '@portal-core/project-files/components/project-flare-file/project-flare-file.component';
import { ProjectFile } from '@portal-core/project-files/models/project-file.model';
import { ProjectFilesService } from '@portal-core/project-files/services/project-files.service';
import { FlareXmlEditorMode } from '@portal-core/project-files/types/flare-xml-editor-mode.type';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { EditorChangeEvent } from '@portal-core/text-editor/types/editor-change-event.type';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { LoadingState } from '@portal-core/util/loading-state';
import { BehaviorSubject, Observable, catchError, distinctUntilChanged, of, switchMap, tap } from 'rxjs';

export interface ProjectFilesEditSnippetDialogData {
  branch: string;
  licenseUser: LicenseUser;
  projectId: number;
  filePath: string;
  navToFileCallback: (src: string) => void;
  showConditions: boolean;
}

@Component({
  selector: 'mc-project-files-edit-snippet-dialog',
  templateUrl: './project-files-edit-snippet-dialog.component.html',
  styleUrls: ['./project-files-edit-snippet-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProjectFilesEditSnippetDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '85rem',
    height: '45rem'
  };

  @ViewChild('projectFlareFileEditor', { static: false }) projectFlareFileEditor: ProjectFlareFileComponent;

  // Dialog data
  branch: string;
  licenseUser: LicenseUser;
  projectId: number;
  filePath: string;
  navToFileCallback: (src: string) => void;
  showConditions: boolean;

  // Defaults
  trackedChanges: boolean = false;
  fileMode: FlareXmlEditorMode = 'text';
  projectLanguage$: Observable<string>;
  wrapLines: boolean = true;
  readonly: boolean = false;

  // Assigned at initialization
  projectFile$: Observable<ProjectFile>;
  lastCommitId: string;

  snippetIsDirty: boolean = false;
  loadingState: LoadingState<string> = new LoadingState();
  reload$: BehaviorSubject<void> = new BehaviorSubject(undefined);

  constructor(
    protected dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ProjectFilesEditSnippetDialogData,
    protected dialogRef: MatDialogRef<ProjectFilesEditSnippetDialogComponent>,
    private projectFilesService: ProjectFilesService,
    private projectsService: ProjectsService,
    private errorService: ErrorService
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();

    // Fill in dialog data
    this.branch = this.data.branch;
    this.licenseUser = this.data.licenseUser;
    this.projectId = this.data.projectId;
    this.filePath = this.data.filePath;
    this.navToFileCallback = this.data.navToFileCallback;
    this.showConditions = this.data.showConditions;

    this.loadingState.update(true);

    // Create an observable for the project file
    this.projectFile$ = this.reload$.pipe(
      switchMap(() => {
        this.loadingState.update(true);
        return this.projectFilesService.getProjectFile$(this.projectId, this.filePath, this.branch).pipe(
          tap(projectFile => {
            this.lastCommitId = projectFile.LastCommitId;
            this.loadingState.update(false);
          })
        );
      }),
      catchError(error => {
        this.loadingState.update(false, 'Unable to load the snippet file.', this.errorService.getErrorMessages(error));
        return of(null);
      })
    );

    // Create an observable for the project language
    this.projectLanguage$ = this.projectFile$.pipe(
      switchMap(projectFile => this.projectsService.getProjectLanguage$(this.projectId, projectFile.LastCommitId)),
      distinctUntilChanged(),
      cache()
    );
  }

  get requirePromptOnClose(): boolean {
    return this.snippetIsDirty;
  }

  onFlareEditModeClicked(event: MouseEvent, mode: FlareXmlEditorMode) {
    event.preventDefault();

    if (this.projectFlareFileEditor.canChangeMode(mode)) {
      this.fileMode = mode;
    }
  }

  onFlareFileModeChanged(mode: FlareXmlEditorMode) {
    this.fileMode = mode;
  }

  // Content has been changed so now you can commit
  onContentChange($event: EditorChangeEvent) {
    // !$event is for events from project-code-file, they are null and only run when a user changes contents, which is not true for mc-project-flare-file hence why !$event and $event.dirty  are needed.
    if (!$event || $event.dirty) {
      this.setSnippetIsDirty(true);
    }
  }

  private setSnippetIsDirty(snippetIsDirty: boolean) {
    this.snippetIsDirty = snippetIsDirty;
  }

  onCommitClicked() {
    const content = this.getFileContent();

    if (typeof content === 'string') {
      // Open commit dialog
      this.dialog.open(CommitFileDialogComponent, {
        ...CommitFileDialogComponent.DialogConfig,
        data: {
          projectId: this.projectId,
          branchName: this.branch,
          lastCommitId: this.lastCommitId,
          filePath: this.filePath,
          newFile: false,
          content: content,
          licenseId: this.licenseUser.LicenseId
        }
      }).afterClosed().subscribe(result => {
        if (result) {
          this.closeDialog(result, true);
        }
      });
    }
  }

  private getFileContent(): string {
    return this.projectFlareFileEditor.getFormattedContent();
  }

  onRetryLoadFile() {
    this.reload$.next();
  }

  protected onNavToFile(src: string) {
    this.closeDialog();
    this.navToFileCallback(src);
  }
}

