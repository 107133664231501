import { NodeSpec } from 'prosemirror-model';
import { SchemaPlugin } from '../../prosemirror/model/schema-plugin';

export class PreSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    pre: {
      group: 'block',
      content: 'inline*',
      code: true,
      parseDOM: [{
        tag: 'pre',
        preserveWhitespace: 'full'
      }],
      toDOM() { return ['pre', { class: 'editor-pre' }, 0]; }
    }
  };
}
