import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CreateDocumentBucketNodeTitlePipe } from '@portal-core/project-files/pipes/create-document-bucket-node-title/create-document-bucket-node-title.pipe';



@NgModule({
    declarations: [
        CreateDocumentBucketNodeTitlePipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        CreateDocumentBucketNodeTitlePipe
    ]
})
export class CreateDocumentBucketNodeTitlePipeModule { }