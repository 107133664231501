import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ErrorCode } from '@common/http/enums/error-code.enum';

@Component({
  selector: 'mc-error-code-expansion-panel',
  templateUrl: './error-code-expansion-panel.component.html',
  styleUrls: ['./error-code-expansion-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorCodeExpansionPanelComponent implements OnInit {
  @Input() detailedErrorCodes: ErrorCode[];
  @Input() generalErrorCode: ErrorCode;
  @Input() showIcon: boolean = true;
  @Output() retry: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('detailedErrorsExpansionPanel') detailedErrorsExpansionPanel: MatExpansionPanel;

  showRetry: boolean = false;

  ngOnInit() {
    this.showRetry = this.retry.observers.length > 0;
  }

  toggleDetailErrorsExpansionPanel() {
    this.detailedErrorsExpansionPanel.toggle();
  }
}
