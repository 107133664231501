<div class="mc-vertical-split-dialog">
  <div matDialogTitle>
    <mat-icon [fontSet]="'project-titan'" [fontIcon]="'icon-alert'" class="mc-image-icon mc-image-icon-normal" color="primary"></mat-icon>
  </div>

  <mat-dialog-content>
    <h2 class="mc-vertical-split-dialog-title">Session Time Out Warning</h2>
    <p class="mc-vertical-split-dialog-body">In one minute, your session will time out. You can continue your session or log out now.</p>
  </mat-dialog-content>

  <mat-dialog-actions class="mc-dialog-actions-align-center">
      <button mat-stroked-button (click)="onSignOutClicked()">Log out</button>
    <button mat-raised-button color="primary" (click)="onExtendIdleTimerClicked()">Continue</button>
  </mat-dialog-actions>
</div>
