import { ChangeAttrName } from '@common/flare/change-attr-name.enum';
import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { attrsToFlareXML } from '@common/flare/util/prosemirror-flare-xml';
import { ChangeType } from '@common/prosemirror/changeset/change-type.enum';
import { TrackedChange, TrackedChangeList } from '@common/prosemirror/changeset/tracked-change.type';
import { makeChangeIdsString } from '@common/prosemirror/changeset/tracked-changes';
import { stringIsPositiveInteger } from '@common/util/string';
import { sortBy } from 'lodash';
import { Mark, ProseMirrorNode } from 'prosemirror-model';

/**
 * Extracts tracked changes from a ProseMirror document so they can be exported as Flare XML.
 */
export class TrackedChangesExporter {
  changesForHead: TrackedChange[] = [];
  changesInHead: Dictionary<boolean> = {};
  convertedChangeIds: Dictionary<number> = {};
  maxChangeId: number = 0;

  /**
   * Clears all the tracked changes stored in the extractor.
   */
  clear() {
    this.changesForHead = [];
    this.changesInHead = {};
    this.convertedChangeIds = {};
    this.maxChangeId = 0;
  }

  /**
   * Extracts the tracked changes from the node and stores them to be inserted into the <head> later.
   * Builds and return the `MadCap:changes` attribute for the node/mark
   * @param nodeOrMark The node or mark to extract the changes from.
   * @returns The change ids attribute for the node which can be used when exporting to Flare XML.
   */
  extractChanges(nodeOrMark: ProseMirrorNode | Mark): string {
    // mcCentralContainer nodes do not have tracked changes because they transfer changes to their children
    if (nodeOrMark.type.name === 'mcCentralContainer') {
      return;
    }

    const changeList: TrackedChangeList = nodeOrMark.attrs.changeList;

    // If there are tracked changes on this node/mark
    if (changeList?.length > 0) {
      const newChangeIds = [];

      // Gather all the changes for the <head> and convert the change ids to integers
      changeList.forEach(trackedChange => {
        // Untracked changes do not have a corresponding element in <MadCap:changeData> so simply store the change id (which is always "untracked")
        if (trackedChange.changeType === ChangeType.Untracked) {
          newChangeIds.push(ChangeType.Untracked);
        } else {
          // Convert the id to an integer id
          const changeId = this.getIntegerId(trackedChange.id);
          newChangeIds.push(changeId);

          // If the change is not already extracted for the <head>
          if (!this.changesInHead[changeId]) {
            this.changesForHead.push({
              ...trackedChange,
              id: changeId.toString()
            });
            this.changesInHead[changeId] = true;
          }
        }
      });

      // Return change ids attribute for the node/mark
      return makeChangeIdsString(newChangeIds);
    }
  }

  /**
   * Converts the extracted tracked changes to <MadCap:changeData> markup for inserting into the <head> of the Flare XML.
   * @param state The state object used for Flare serialization.
   * @returns The <MadCap:changeData> markup for the tracked changes.
   */
  changeDataToFlareXML(options: ToFlareXMLOptions): string {
    let xml = '';

    if (this.changesForHead?.length > 0) {
      const changesForHead = sortBy(this.changesForHead, trackedChange => parseInt(trackedChange.id, 10));
      const changeXML = changesForHead.map(change => this.trackedChangeToFlareXML(change, options)).join('');

      if (changeXML) {
        xml = `<MadCap:changeData>${changeXML}</MadCap:changeData>`;
      }
    }

    return xml;
  }

  /**
   * Converts the change id to an integer id and caches the conversion.
   * If the change id is already an integer then it is returned as is.
   * @param changeId The change id to convert to an integer.
   * @returns The integer id for the change.
   */
  private getIntegerId(changeId: number | string): number {
    // If changeId is already an integer then just use it
    if (typeof changeId === 'number' && Number.isInteger(changeId)) {
      return changeId;
    }

    // If changeId is an integer then just use it
    if (stringIsPositiveInteger(changeId as string)) {
      return Number(changeId);
    }

    // If this changeId has already been converted to an integer then use the cached value
    if (this.convertedChangeIds[changeId]) {
      return this.convertedChangeIds[changeId];
    }

    // Set the new change id equal to the next available integer change id value
    const newChangeId = this.maxChangeId + 1;

    this.convertedChangeIds[changeId] = newChangeId;
    this.maxChangeId = newChangeId;

    return newChangeId;
  }

  /**
   * Converts a tracked change to Flare XML.
   * @param trackedChange The tracked change to convert.
   * @param state The state object used for Flare serialization.
   * @returns The Flare XML for the tracked change.
   */
  private trackedChangeToFlareXML(trackedChange: TrackedChange, options: ToFlareXMLOptions): string {
    let tagName: string;
    let content: string;
    const attributes: Dictionary<string> = {
      'MadCap:id': trackedChange.id,
      [ChangeAttrName.UserName]: trackedChange.userName,
      [ChangeAttrName.Initials]: trackedChange.initials,
      [ChangeAttrName.Timestamp]: trackedChange.timestamp,
      [ChangeAttrName.CreatorCentralUserId]: trackedChange.creatorCentralUserId
    };

    // Create a change element based on the change type
    switch (trackedChange.changeType) {
      case ChangeType.Add:
        tagName = 'MadCap:AddChange';
        break;
      case ChangeType.Remove:
        tagName = 'MadCap:RemoveChange';
        break;
      case ChangeType.Replace:
        tagName = 'MadCap:ReplaceChange';
        content = trackedChange.changeContent;
        break;
      case ChangeType.Bind:
        tagName = 'MadCap:BindChange';
        break;
      case ChangeType.Unbind:
        tagName = 'MadCap:UnbindChange';
        content = trackedChange.changeContent;
        break;
      case ChangeType.Attributes:
        tagName = 'MadCap:AttributesChange';
        attributes[ChangeAttrName.Attribute] = trackedChange.attribute;
        attributes[ChangeAttrName.Value] = trackedChange.value;
        break;
    }

    let xml = '';
    if (tagName) {
      xml = `<${tagName}`;
      xml += attrsToFlareXML(attributes, null, options, tagName, null);
      xml += `>${content ?? ''}</${tagName}>`;
    }

    return xml;
  }
}
