import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

export class MadCapSlideshowSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapslideshow: {
      group: 'block',
      content: 'madcapslide+',
      parseDOM: [{
        tag: 'MadCap\\:slideshow'
      }],
      toDOM: function () { return ['div', { class: 'mc-madcap-slideshow' }, 0]; },
      tagName: 'MadCap:slideshow',
      specContent: 'madcapslide'
    },
    madcapslide: {
      content: 'block+',
      fillIfContentDeleted: 'mcCentralContainer',
      parseDOM: [{ tag: 'MadCap\\:slide' }],
      toDOM: function () { return ['div', { class: 'mc-madcap-slide' }, 0]; },
      tagName: 'MadCap:slide',
      specContext: 'madcapslideshow'
    }
  };
}
