<mc-grid [columns]="columns" [items]="items$ | async" [itemCount]="itemCount$ | async" [orderBy]="defaultSort.active" [orderDirection]="defaultSort.direction" [hideEmptyPagination]="true" [pageIndex]="pageIndex" [pageSize]="pageSize" [visibleColumns]="visibleColumns" (pageChanged)="onPageChanged($event)" (sortChanged)="onSortChange($event)">
  <ng-template mcGridCell="Select" let-variable>
    <mat-radio-group *ngIf="!multiple" name="selectVariableGroup">
      <mat-radio-button class="mat-primary-vivid" [value]="variable.Data.LongName" [checked]="variable.Data.LongName === selectedVariable?.Data.LongName" (change)="onVariableSelected(variable)"></mat-radio-button>
    </mat-radio-group>

    <mat-checkbox *ngIf="multiple" color="primary-vivid" (change)="onVariableSelected(variable, $event)" [checked]="variable.Checked">
    </mat-checkbox>
  </ng-template>

  <ng-template mcGridCell="Name" let-variable>
    <button type="button" matTooltip="{{variable.Data.Name}}" class="mc-variables-grid-name-item mc-inline-button mc-bg-none" (click)="onVariableSelected(variable)">{{variable.Data.Name}}</button>
  </ng-template>
</mc-grid>