import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { DropdownArrowModule } from '@portal-core/ui/forms/components/dropdown-arrow/dropdown-arrow.module';
import { CssLengthPopupComponent } from '@portal-core/ui/style-picker/components/css-length-popup/css-length-popup.component';

@NgModule({
  declarations: [
    CssLengthPopupComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    DropdownArrowModule
  ],
  exports: [
    CssLengthPopupComponent
  ]
})
export class CssLengthPopupModule { }