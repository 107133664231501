import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { BrowserHostService } from '@portal-core/auth/services/browser-host.service';
import { map, Observable, of, switchMap, tap } from 'rxjs';

/**
 * Checks the browser host (Flare) version to determine if multiple backend Central instances are supported.
 */
@Injectable({
  providedIn: 'root'
})
export class BrowserHostVersionGuardService implements CanActivate {
  constructor(private browserHostService: BrowserHostService, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // If the browser host is defined then run the code to check the browser host version
    if (this.browserHostService.isCefSharpHosted()) {
      return this.browserHostService.browserHost$().pipe(
        switchMap(browserHost => browserHost?.version?.() ?? of(-1)),
        tap(version => {
          // enable multiple Central instances if browser host version greater than or equal to two
          this.authService.supportsMultipleCentralInstances = version >= 2;
        }),
        map(() => true)
      );
    }

    return of(true);
  }
}
