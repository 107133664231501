import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { ChecklistTemplate } from '@portal-core/checklist-templates/models/checklist-template.model';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';

@CollectionStateSelectors({
  stateType: ChecklistTemplatesState
})
@State<ChecklistTemplatesState>({
  name: ChecklistTemplatesState.source
})
@Injectable()
export class ChecklistTemplatesState extends CollectionStateBase {
  static source = 'ChecklistTemplates';
  getSource(): string {
    return ChecklistTemplatesState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ChecklistTemplatesDataService extends CollectionDataServiceBase<ChecklistTemplate> {

  constructor(protected store: Store) {
    super(store, ChecklistTemplatesState);
  }
}
