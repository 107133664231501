import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { SelfTruncatingPathModule } from '@portal-core/general/components/self-truncating-path/self-truncating-path.module';
import { RemoveLeadingSlashModule } from '@portal-core/project-files/pipes/remove-leading-slash/remove-leading-slash.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FilePickerComponent } from './file-picker.component';

@NgModule({
  declarations: [
    FilePickerComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    RemoveLeadingSlashModule,
    SelfTruncatingPathModule,
  ],
  exports: [
    FilePickerComponent
  ]
})
export class FilePickerModule { }
