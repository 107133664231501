import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthRoute } from '@portal-core/auth/enums/auth-route.enum';
import { AuthRouteService } from '@portal-core/auth/services/auth-route.service';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubdomainLoginGuardService implements CanActivate {
  constructor(private authService: AuthService, private authRouteService: AuthRouteService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // Route guards can be called multiple times as navigation occurs. Check if there was already an attempt to auto login the user before doing it again
    if (this.authService.getAutoLoginAttempted()) {
      return of(true);
    }

    return this.authService.loginToLicense$().pipe(
      map(() => true),
      catchError(() => {
        this.authRouteService.navigateToAuthRoute$(AuthRoute.AuthLoginGuardError).subscribe();
        return of(false);
      })
    );
  }
}
