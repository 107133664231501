import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';

export class MadCapSnippetSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapsnippettext: {
      group: 'inline',
      inline: true,
      attrs: {
        src: { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:snippetText',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['src']);
        }
      }, {
        tag: 'span',
        getAttrs(dom: HTMLElement) {
          if (!dom.classList.contains('mc-madcap-snippet-text')) {
            return false;
          }

          return getSchemaAttrs(dom, ['src']);
        }
      }],
      toDOM(node: ProseMirrorNode) {
        return ['span', {
          class: 'mc-madcap-snippet mc-madcap-snippet-text',
          src: node.attrs.src
        }, ''];
      },
      tagName: 'MadCap:snippetText',
      isVoid: true
    },

    madcapsnippetblock: {
      group: 'block',
      attrs: {
        src: { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:snippetBlock',
        getAttrs(dom: Element) {
          return getSchemaAttrs(dom, ['src']);
        }
      }, {
        tag: 'div',
        getAttrs(dom: HTMLElement) {
          if (!dom.classList.contains('mc-madcap-snippet-block')) {
            return false;
          }

          return getSchemaAttrs(dom, ['src']);
        }
      }],
      toDOM(node: ProseMirrorNode) {
        return ['div', {
          class: 'mc-madcap-snippet mc-madcap-snippet-block',
          src: node.attrs.src
        }, ''];
      },
      tagName: 'MadCap:snippetBlock',
      isVoid: true
    }
  };
}
