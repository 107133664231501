import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacySelectionList as MatSelectionList, MatLegacySelectionListChange as MatSelectionListChange } from '@angular/material/legacy-list';
import { ProjectConditionSet } from '@portal-core/project-files/conditions/models/project-condition-set.model';
import { Focusable } from '@portal-core/ui/focus/interfaces/focusable';
import { MC_FOCUSABLE } from '@portal-core/ui/focus/util/focusable.token';

@Component({
  selector: 'mc-condition-sets-list',
  templateUrl: './condition-sets-list.component.html',
  styleUrls: ['./condition-sets-list.component.scss'],
  providers: [{ provide: MC_FOCUSABLE, useExisting: ConditionSetsListComponent }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConditionSetsListComponent implements Focusable, OnInit {
  @Input() conditionSets: ProjectConditionSet[];
  @Input() initialSetName: string;

  @Output() conditionSetSelect = new EventEmitter<ProjectConditionSet>();

  @ViewChild(MatSelectionList, { read: ElementRef, static: true }) selectionList: ElementRef;

  selectedSet: ProjectConditionSet;

  ngOnInit() {
    this.initialSelect();
  }

  onConditionSetSelected(event: MatSelectionListChange) {
    const [selectedOption] = event.options;
    this.selectSet(selectedOption.value);
  }

  focus() {
    this.selectionList?.nativeElement?.focus();
  }

  private initialSelect() {
    let selectedSet = this.conditionSets
      .find(conditionSet => conditionSet.Name === this.initialSetName)
      || this.conditionSets?.[0];
    if (selectedSet) {
      this.selectSet(selectedSet);
    }
  }

  private selectSet(selectedSet: ProjectConditionSet) {
    this.selectedSet = selectedSet;
    this.conditionSetSelect.emit(this.selectedSet);
  }
}
