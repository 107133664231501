import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { AppLicenseDropdownModule } from '@portal-client/app/components/app-license-dropdown/app-license-dropdown.module';
import { StopPropagationModule } from '@portal-core/general/directives/stop-propagation/stop-propagation.module';
import { NavbarHelpButtonModule } from '@portal-core/layout/components/navbar-help-button/navbar-help-button.module';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { LicenseProfileDialogModule } from '@portal-core/licenses/components/license-profile-dialog/license-profile-dialog.module';
import { AlertCenterDialogCardModule } from '@portal-core/notifications/components/alert-center/alert-center-dialog-card.module';
import { IfAuthorModule } from '@portal-core/user-access/directives/if-author/if-author.module';
import { AppNavbarUserLaunchPadComponent } from './app-navbar-user-launch-pad.component';

@NgModule({
  imports: [
    AlertCenterDialogCardModule,
    AppLicenseDropdownModule,
    CommonModule,
    FormsModule,
    IfAuthorModule,
    LicenseProfileDialogModule,
    MatBadgeModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatToolbarModule,
    MatTooltipModule,
    NavbarHelpButtonModule,
    RouterModule,
    StopPropagationModule,
    LicenseUserAvatarModule
  ],
  declarations: [
    AppNavbarUserLaunchPadComponent
  ],
  exports: [
    AppNavbarUserLaunchPadComponent
  ]
})
export class AppNavbarUserLaunchPadModule { }
