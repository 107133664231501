<mat-dialog-content class="mc-app-about-dialog-content mc-brand-gradient">
  <mat-icon fontSet="project-titan" fontIcon="icon-central" color="secondary-invert" class="mc-image-icon"></mat-icon>
  <img class="mc-app-about-dialog-central-text-logo" alt="Central Logo" src="assets/images/madcap-central-text-logo.svg">
  <p class="mc-app-about-dialog-version mc-text-invert">{{ appVersionAndBuildDate$ | async }}</p>
  <p class="mc-app-about-dialog-copyright mc-text-invert">Copyright &copy; {{ currentYear }} MadCap Software Inc. All Rights Reserved.</p>
  <p class="mc-app-about-dialog-licenses"><a class="mc-text-invert" href="assets/text/disclosure.txt" target="_blank">Third Party Software and Notices</a></p>

  <button mat-icon-button color="secondary-invert" class="mc-app-about-dialog-close-button" tabindex="-1" matDialogClose>
    <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
  </button>
</mat-dialog-content>
