import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { TopicViewsReport } from '@portal-core/output-analytics/models/output-analytics-topic-views-report.model';

@CollectionStateSelectors({
  stateType: TopicViewsReportState
})
@State<TopicViewsReportState>({
  name: TopicViewsReportState.source,
})
@Injectable()
export class TopicViewsReportState extends CollectionStateBase {
  static source = 'TopicViewsReports';
  getSource(): string {
    return TopicViewsReportState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TopicViewsReportDataService extends CollectionDataServiceBase<TopicViewsReport> {
  constructor(protected store: Store) {
    super(store, TopicViewsReportState);
  }
}
