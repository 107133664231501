import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { TranslationQuotesState } from '@portal-core/translation-quotes/services/translation-quotes-data.service';

@NgModule({
  imports: [
    NgxsModule.forFeature([TranslationQuotesState])
  ]
})
export class TranslationQuotesServicesModule { }
