import { Pipe, PipeTransform } from '@angular/core';
import { createTwoFilesPatch } from 'diff';

/*
 * Changes a flare code string into a diff between an original and this flare string
 * Usage:
 *   New Code String | Diff String: Old Code String
 *  Example:
 *   {{ 'Delete this text and replace it with your own content.' |  mcDiff: 'Hello! How can I assist you today?' }}
 *  Returns the diff string between these two strings:
 *  '
 *   ===============================================
 *   --- old	
 *   +++ new	
 *   @@ -1,1 +1,1 @@
 *   -Delete this text and replace it with your own content.
 *   \ No newline at end of file
 *   +Hello! How can I assist you today?
 *   \ No newline at end of file
 *  '
*/
@Pipe({
    name: 'mcDiff'
})
export class DiffPipe implements PipeTransform {
    constructor() { }

    transform(newCode: string, oldCode: string): string {
        return createTwoFilesPatch('old', 'new', oldCode, newCode, '', '', { ignoreWhitespace: false });
    }
}
