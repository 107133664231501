import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { OutputAnalyticsKey } from '@portal-core/output-analytics/models/output-analytics-key.model';

@CollectionStateSelectors({
  stateType: OutputAnalyticsKeyState
})
@State<OutputAnalyticsKeyState>({
  name: OutputAnalyticsKeyState.source,
})
@Injectable()
export class OutputAnalyticsKeyState extends CollectionStateBase {
  static source = 'OutputAnalyticsKeys';
  getSource(): string {
    return OutputAnalyticsKeyState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class OutputAnalyticsKeyDataService extends CollectionDataServiceBase<OutputAnalyticsKey> {
  constructor(protected store: Store) {
    super(store, OutputAnalyticsKeyState);
  }
}
