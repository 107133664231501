import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { NotificationsService } from '@portal-core/notifications/services/notifications.service';

export enum AlertCenterDialogMode {
  MyNotifications = 1, // Start at 1 for easy existence checks
  TaskNotifications
}

@Component({
  selector: 'mc-alert-center-dialog-card',
  templateUrl: './alert-center-dialog-card.component.html',
  styleUrls: ['./alert-center-dialog-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertCenterDialogCardComponent implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '50rem',
    height: '85rem',
    panelClass: 'mc-dialog-card-container',
  };

  AlertCenterDialogMode: typeof AlertCenterDialogMode = AlertCenterDialogMode;

  dialogMode: AlertCenterDialogMode;
  licenseId: number;
  taskBoardId: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private notificationsService: NotificationsService) { }

  ngOnInit() {
    this.dialogMode = this.data.dialogMode || AlertCenterDialogMode.MyNotifications;
    this.licenseId = this.data.licenseId;
    this.taskBoardId = this.data.taskBoardId;

    // If viewing my notifications then mark all notifications as read
    if (this.dialogMode === AlertCenterDialogMode.MyNotifications) {
      this.notificationsService.markAllMyNotificationsAsRead$().subscribe();
    }
  }
}
