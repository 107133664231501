import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { PopupModule } from '@portal-core/ui/popup/popup.module';
import { CssLengthPopupModule } from '@portal-core/ui/style-picker/components/css-length-popup/css-length-popup.module';
import { StylePickerInputComponent } from '@portal-core/ui/style-picker/components/style-picker/style-picker-input.component';

@NgModule({
  declarations: [
    StylePickerInputComponent
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    PopupModule,
    ReactiveFormsModule,
    CssLengthPopupModule
  ],
  exports: [
    StylePickerInputComponent
  ]
})
export class StylePickerInputModule { }
