export enum AuditCategoryName {
  Builds = 'Builds',
  BuildAborted = 'BuildAborted',
  BuildCompleted = 'BuildCompleted',
  BuildDeleted = 'BuildDeleted',
  BuildSetLive = 'BuildSetLive',
  BuildStarted = 'BuildStarted',
  BuildStopped = 'BuildStopped',
  Checklists = 'Checklists',
  ChecklistCreated = 'ChecklistCreated',
  ChecklistUpdated = 'ChecklistUpdated',
  ChecklistDeleted = 'ChecklistDeleted',
  GlobalCategory = 'GlobalCategory',
  LicenseActivatedUser = 'LicenseActivatedUser',
  LicenseBulkUserImportCompleted = 'LicenseBulkUserImportCompleted',
  LicenseExpirationWarning = 'LicenseExpirationWarning',
  LicenseExpired = 'LicenseExpired',
  LicenseSeatsOrStorageLow = 'LicenseSeatsOrStorageLow',
  LicenseUpdated = 'LicenseUpdated',
  LicenseUserAdded = 'LicenseUserAdded',
  LicenseUserDeleted = 'LicenseUserDeleted',
  LicenseUserManagement = 'LicenseUserManagement',
  LicenseUserSeatTypeChanged = 'LicenseUserSeatTypeChanged',
  LicenseUserStatusChanged = 'LicenseUserStatusChanged',
  PermissionsChanged = 'PermissionsChanged',
  ProfileChanged = 'ProfileChanged',
  ProjectDeleted = 'ProjectDeleted',
  ProjectManagement = 'ProjectManagement',
  ProjectPushedOrUploaded = 'ProjectPushedOrUploaded',
  ProjectUserAddedorRemoved = 'ProjectUserAddedorRemoved',
  PublicBroadcast = 'PublicBroadcast',
  ReviewersFinished = 'ReviewersFinished',
  Reviews = 'Reviews',
  ReviewFileAssigned = 'ReviewFileAssigned',
  ReviewFileAuthorAssigned = 'ReviewFileAuthorAssigned',
  ReviewFileDeleted = 'ReviewFileDeleted',
  ReviewFileDocumentFailed = 'ReviewFileDocumentFailed',
  ReviewAssigned = 'ReviewAssigned',
  Scans = 'Scans',
  ScanCancelled = 'ScanCancelled',
  ScanCompleted = 'ScanCompleted',
  ScanDeleted = 'ScanDeleted',
  ScanFailed = 'ScanFailed',
  ScanStarted = 'ScanStarted',
  ScanStopped = 'ScanStopped',
  StatusChanged = 'StatusChanged',
  SiteCreated = 'SiteCreated',
  SiteDeleted = 'SiteDeleted',
  SiteSetLive = 'SiteSetLive',
  SiteTeamAddedOrRemoved = 'SiteTeamAddedOrRemoved',
  SiteUpdated = 'SiteUpdated',
  Tasks = 'Tasks',
  TaskBoardCreated = 'TaskBoardCreated',
  TaskBoardEdited = 'TaskBoardEdited',
  TaskBoardDeleted = 'TaskBoardDeleted',
  TaskCommentsorFileActions = 'TaskCommentsorFileActions',
  TaskDeleted = 'TaskDeleted',
  TaskEdited = 'TaskEdited',
  TaskNewOrAssigned = 'TaskNewOrAssigned',
  TaskStatusChanged = 'TaskStatusChanged',
  TeamAddedorRemovedToProject = 'TeamAddedorRemovedToProject',
  TeamDeleted = 'TeamDeleted',
  TeamEdited = 'TeamEdited',
  TeamManagement = 'TeamManagement',
  TeamUserAddedorRemoved = 'TeamUserAddedorRemoved',
  UsersCategory = 'UsersCategory',
  UserLogin = 'UserLogin',
  UserLogout = 'UserLogout',
  UserProfileChanged = 'UserProfileChanged',
}
