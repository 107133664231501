import { CustomError } from '@common/util/custom-error';
import { Socket } from 'socket.io-client';

interface Emission {
  retries: number;
  reject: (reason?: any) => void;
}

export enum SocketEmitterError {
  AlreadyEmittingMessage = 'Already Emitting Message',
  EmissionCanceled = 'Emission Canceled'
}

/**
 * SocketEmitter
 */
export class SocketEmitter {
  private emissions: Map<string, Emission> = new Map<string, Emission>();
  private maxRetries: number = 3;
  private retryPeriod: number = 1000;

  constructor(private socket: Socket) { }

  /**
   * Emits a message over the socket.
   * @param msg The message to send.
   * @param data The data to send with the message.
   * @param retry A function that is called when the message fails to send. If the function returns true then the message will be emitted again. It will retry no more than this.maxRetries times.
   * @returns A promise that is resolved after a successful response from the server. The promise is rejected if any errors happen, all retires fail, or the emission is canceled.
   */
  emit<R=any>(msg: string, data: any, retry: (error: Error) => boolean): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      if (this.emitting(msg)) {
        reject(new CustomError(SocketEmitterError.AlreadyEmittingMessage, 'Message is waiting for a response from the server.'));
        return;
      }

      // Create the emission for this message
      const emission: Emission = { retries: 0, reject };
      this.addEmission(msg, emission);

      // Define a send function that can schedule retries on unexpected errors
      const send = () => {
        try {
          // Send the message to the server
          this.socket.emit(msg, data, res => {
            // Only process and resolve the response if the emission hasn't been canceled.
            if (this.emitting(msg)) {
              // If there was a server error
              if (res?.error) {
                // Call the retry handler to see if we should retry sending the message
                if (emission.retries < this.maxRetries && retry(res.error)) {
                  // Increment the retry count and schedule the retry
                  emission.retries += 1;
                  setTimeout(send, this.retryPeriod * emission.retries);
                } else {
                  // Clear out the emission now that it is done trying
                  this.cancelEmission(msg, res.error);
                }
                // Else the message was received successfully
              } else {
                // Clear out the emission now that it is done
                this.removeEmission(msg);
                resolve(res);
              }
            }
          });
        } catch (ex) {
          // On catastrophic failure make sure to clear out this emission
          this.cancelEmission(msg, ex);
        }
      };

      // Send off the first try
      send();
    });
  }

  /** Returns true if the message is currently being emitted. */
  emitting(msg: string): boolean {
    return this.emissions.has(msg);
  }

  /** Cancels all emissions rejecting promises returned from emit. */
  cancelAllEmissions() {
    this.emissions.forEach(emission => emission.reject(SocketEmitterError.EmissionCanceled));
    this.emissions.clear();
  }

  /** Cancel an emission and rejects the promise returned from emit. */
  cancelEmission(msg: string, reason: any = SocketEmitterError.EmissionCanceled) {
    if (this.emissions.has(msg)) {
      this.emissions.get(msg).reject(reason);
      this.emissions.delete(msg);
    }
  }

  private addEmission(msg: string, emission: Emission) {
    this.emissions.set(msg, emission);
  }

  private removeEmission(msg: string) {
    this.emissions.delete(msg);
  }
}
