import { Injectable } from '@angular/core';
import { OnOpenAboutDialog } from '@portal-core/layout/services/layout-event-handler.interface';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppAboutDialogComponent } from '@portal-client/app/components/app-about-dialog/app-about-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class LayoutEventHandlerService implements OnOpenAboutDialog {
  constructor(private dialog: MatDialog) { }

  onOpenAboutDialog() {
    this.dialog.open(AppAboutDialogComponent, {
      width: '52rem',
      panelClass: 'mc-dialog-card-container'
    });
  }
}
