import { FlareMadCapXMLNSName, FlareMadCapXMLNSValue } from '@common/flare/constants/flare-madcap-xmlns.constant';
import { FlareXMLDeclaration } from '@common/flare/constants/flare-xml-declaration.constant';
import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { defaultToFlareXML, nodeToFlareXML } from '@common/flare/util/prosemirror-flare-xml';
import { NodeType } from '@common/html/enums/node-type.enum';
import { isRtl } from '@common/html/util/dom';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';

export interface HTMLStructureItem {
  nodeName: string;
  data?: string;
}

export const MadCapHtmlStructureAttrName = 'MadCap:htmlStructure';

export class FlareDocSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    doc: {
      content: 'html',
      toFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
        let xml = FlareXMLDeclaration;

        for (let i = 0; i < node.childCount; i += 1) {
          const child = node.child(i);
          xml += (child.type.spec.toFlareXML ?? defaultToFlareXML)(child, options)
        }

        return xml;
      }
    },

    html: {
      content: 'body',
      defining: true,
      attrs: {
        [FlareMadCapXMLNSName]: { default: FlareMadCapXMLNSValue },
        'MadCap:lastBlockDepth': { default: undefined },
        'MadCap:lastHeight': { default: undefined },
        'MadCap:lastWidth': { default: undefined },
        'xml:lang': { default: undefined },
        'MadCap:htmlStructure': { default: undefined, skipExport: true }
      },
      parseDOM: [{
        tag: 'html',
        getAttrs(dom: HTMLElement) {
          const htmlStructure: HTMLStructureItem[] = [];

          // Direct children of the html node are removed from the document (except for the body) and added to the htmlStructure array
          // Loop in reverse order because we are removing nodes
          for (let i = dom.childNodes.length - 1; i >= 0; i -= 1) {
            const node = dom.childNodes[i];

            if (node.nodeName === 'body') {
              htmlStructure.push({ nodeName: node.nodeName });
            } else if (node.nodeName === 'head') {
              htmlStructure.push({ nodeName: node.nodeName, data: (node as Element).outerHTML });
              node.remove();
            } else if (node.nodeType === NodeType.ELEMENT_NODE) {
              htmlStructure.push({ nodeName: node.nodeName, data: (node as Element).outerHTML });
              node.remove();
            } else if (node.nodeType === NodeType.TEXT_NODE || node.nodeType === NodeType.COMMENT_NODE || node.nodeType === NodeType.CDATA_SECTION_NODE) {
              htmlStructure.push({ nodeName: node.nodeName, data: (node as Element).outerHTML });
              node.remove();
            }
          }

          return {
            ...getSchemaAttrs(dom, ['xmlns:MadCap', 'MadCap:lastBlockDepth', 'MadCap:lastHeight', 'MadCap:lastWidth', 'xml:lang']),
            'MadCap:htmlStructure': htmlStructure.reverse() // Reverse the array so that the order is correct (since they were processed in reverse to begin with)
          };
        }
      }],
      toDOM(node: ProseMirrorNode) {
        return ['div', {
          class: 'html-node',
          'xml:lang': node.attrs['xml:lang'],
          dir: isRtl(node.attrs['xml:lang']) ? 'rtl' : null
        }, 0];
      },
      toFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
        const htmlStructure: HTMLStructureItem[] = node.attrs[MadCapHtmlStructureAttrName];

        // Build the body xml first so that all the tracked changes gets processed
        const body = node.firstChild;
        const bodyToFlareXML = body.type.spec.toFlareXML ?? defaultToFlareXML;
        const bodyXML = bodyToFlareXML(body, options);

        // Build the head xml from the imported head and the tracked changes
        let headXML: string = '';

        // Do not add the head when exporting for the clipboard because the head tag will get parsed as an unknown tag and added to the body on paste
        // The head information is also not needed when pasting since tracked changes are not included in a copy/paste
        if (!options.exportForClipboard) {
          headXML = htmlStructure?.find(extraElement => extraElement.nodeName === 'head')?.data;
          const changeDataXML = options?.trackedChangesExporter.changeDataToFlareXML(options);
          if (changeDataXML) {
            if (!headXML) {
              headXML = `<head>${changeDataXML}</head>`;
            } else {
              headXML = headXML.substring(0, headXML.length - '</head>'.length) + changeDataXML + '</head>';
            }
          }
        }

        let insertedBody = false;
        let insertedHead = false;

        // Build the children array using the structure from htmlStructure
        const children: string[] = htmlStructure?.map(extraElement => {
          if (extraElement.nodeName === 'body') {
            insertedBody = true;
            return bodyXML;
          } else if (extraElement.nodeName === 'head') {
            insertedHead = true;
            return headXML;
          } else {
            return extraElement.data;
          }
        }) ?? [];

        // If the body or head wasn't in the htmlStructure then add them to the children.
        if (!insertedHead && headXML) {
          children.push(headXML);
        }
        if (!insertedBody && bodyXML) {
          children.push(bodyXML);
        }

        // Render the html node with its children
        return nodeToFlareXML(node, options, 'html', {
          children
        });
      },
      tagName: 'html'
    },

    body: {
      content: 'block+',
      defining: true,
      isolating: true,
      parseDOM: [{
        tag: 'body'
      }],
      toDOM() { return ['div', { class: 'mc-pm-body-node' }, 0]; }
    }
  };
}
