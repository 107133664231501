import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { nodeToFlareXML } from '@common/flare/util/prosemirror-flare-xml';
import { nbsp } from '@common/html/util/dom';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { defaultParseRulePriority } from '@common/util/schema';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';

/**
 * @deprecated remains for backwards compatibility when exporting documents from Redis.
 */
export class MadCapCentralNbspSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapcentralnbspblock: {
      group: 'block',
      inlineAndBlock: true,
      unchangeable: true, // Tracked changes cannot be put on this node forcing a tracked change mark to be placed around the node instead
      parseDOM: [{
        tag: 'MadCap\\:centralNbsp'
      }, {
        tag: 'span',
        getAttrs(dom: HTMLElement) {
          if (!dom.classList.contains('mc-madcap-central-nbsp')) {
            return false;
          }
        }
      }],
      toDOM() { return ['span', { class: 'mc-madcap-central-nbsp mc-madcap-central-nbsp-block' }, nbsp]; },
      toFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
        return nodeToFlareXML(node.type.schema.text(nbsp, node.marks), options, null);
      },
      toDisplayName() { return 'nbsp'; },
      specSkip: true
    },

    madcapcentralnbspinline: {
      group: 'inline',
      inline: true,
      inlineAndBlock: true,
      unchangeable: true, // Tracked changes cannot be put on this node forcing a tracked change mark to be placed around the node instead
      parseDOM: [{
        tag: 'MadCap\\:centralNbsp',
        context: 'block/|inline/',
        priority: defaultParseRulePriority + 1 // For preference over madcapCentralCommentBlock
      }, {
        tag: 'span',
        context: 'block/|inline/',
        priority: defaultParseRulePriority + 1, // For preference over madcapCentralCommentBlock
        getAttrs(dom: HTMLElement) {
          if (!dom.classList.contains('mc-madcap-central-nbsp')) {
            return false;
          }
        }
      }],
      toDOM() { return ['span', { class: 'mc-madcap-central-nbsp mc-madcap-central-nbsp-inline' }, nbsp]; },
      toFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
        return nodeToFlareXML(node.type.schema.text(nbsp, node.marks), options, null);
      },
      toDisplayName() { return 'nbsp'; },
      specSkip: true
    }
  };
}
