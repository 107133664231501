import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TableGroupType } from '@common/enums/table-group-type.enum';
import { FlareCommands } from '@common/flare/flare-commands';
import { FlareSchema } from '@common/flare/flare-schema';
import { MadCapTableRowGroupIdAttrName, MadCapTableRowGroupTypeAttrName } from '@common/flare/schema/table-schema-plugin';
import { ChangeType } from '@common/prosemirror/changeset/change-type.enum';
import { TrackedChangeList } from '@common/prosemirror/changeset/tracked-change.type';
import { getMark } from '@common/prosemirror/model/mark';
import { resolvedPosFindNode, resolvedPosForEachNode } from '@common/prosemirror/model/resolved-pos';
import { getNodeContainingSelection, selectionIsInNode } from '@common/prosemirror/state/selection';
import { orderBy } from 'lodash';
import { ProseMirrorNode } from 'prosemirror-model';
import { AllSelection, Command, EditorState, NodeSelection, TextSelection } from 'prosemirror-state';
import { CellSelection } from 'prosemirror-tables';
import { EditorView } from 'prosemirror-view';

interface Annotation {
  'MadCap:createDate': ISO8601DateString;
  'MadCap:creator': string;
  'MadCap:editDate': ISO8601DateString;
  'MadCap:editor': string;
}

interface NodeTag {
  text: string;
  pos: number;
  to?: number; // Use for table groups as the cell selection range
}

interface TagDetail {
  label: string;
  text: string;
}

enum SelectionSize {
  All,
  Multiple,
  None,
  Single
}

@Component({
  selector: 'mc-flare-file-text-editor-tagbar',
  templateUrl: './flare-file-text-editor-tagbar.component.html',
  styleUrls: ['./flare-file-text-editor-tagbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlareFileTextEditorTagbarComponent implements OnChanges {
  static IgnoredTagNames: string[] = ['doc', 'html', 'mcCentralContainer'];
  static TagNamesTreatedAsAllSelection: string[] = ['doc', 'html'];

  @Input() schema: FlareSchema;

  @Output() dispatch: EventEmitter<Command> = new EventEmitter<Command>();

  SelectionSize: typeof SelectionSize = SelectionSize;

  annotation: Annotation;
  changeList: TrackedChangeList;
  flareCommands: FlareCommands;
  selectionSize: SelectionSize = SelectionSize.None;
  tagPath: NodeTag[];
  tagDetails: TagDetail[];

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.schema) {
      this.flareCommands = this.schema ? new FlareCommands(this.schema) : null;
    }
  }

  onTagClicked(tag: NodeTag) {
    if (this.flareCommands) {
      this.dispatch.emit((editorState: EditorState, dispatch?: ProsemirrorDispatcher, editorView?: EditorView) => {
        return this.flareCommands.selectNode(editorState, dispatch, editorView, tag.pos, tag.to);
      });
    }
  }

  updateState(editorState: EditorState) {
    if (editorState) {
      const selectedLeafNodes = this.getSelectedLeafNodes(editorState);

      if (editorState.selection instanceof AllSelection || this.leafNodeIsAllSelection(selectedLeafNodes)) {
        this.selectionSize = SelectionSize.All;
        this.tagPath = null;
        this.changeList = null;
        this.annotation = null;
        this.tagDetails = null;
      } else if (selectedLeafNodes.length > 1) {
        this.selectionSize = SelectionSize.Multiple;
        this.tagPath = null;
        this.changeList = null;
        this.annotation = null;
        this.tagDetails = null;
      } else if (selectedLeafNodes.length === 1) {
        this.selectionSize = SelectionSize.Single;
        this.tagPath = this.buildTagPath(editorState);
        this.changeList = this.buildChangeList(editorState, selectedLeafNodes[0]);
        this.annotation = this.buildAnnotation(editorState);
        this.tagDetails = this.buildTagDetails(editorState);
      }
    } else {
      this.selectionSize = SelectionSize.None;
      this.tagPath = null;
      this.changeList = null;
      this.annotation = null;
      this.tagDetails = null;
    }

    this.changeDetectorRef.detectChanges();
  }

  private getSelectedLeafNodes(editorState: EditorState): ProseMirrorNode[] {
    const selection = editorState.selection;
    const leafNodes: ProseMirrorNode[] = [];

    // If everything is selected through the special AllSelection class THEN do nothing
    if (selection instanceof AllSelection) {
      // Do nothing
    }
    // If the selection is a CellSelection THEN the leaf nodes are all the selected cells
    else if (selection instanceof CellSelection) {
      selection.forEachCell(cell => leafNodes.push(cell));
    }
    // If the selection is a NodeSelection THEN the currently selected node is simply the selection's node
    else if (selection instanceof NodeSelection) {
      leafNodes.push(selection.node);
    }
    // Else the selection needs to be examined to determine which node is selected
    else {
      // Get all the leaf nodes within the selection
      editorState.doc.nodesBetween(selection.from, selection.to, (node, pos, parent) => {
        if (node.isLeaf) {
          leafNodes.push(node);
        }

        // Since we only care if there are 0, 1, or more than 1 node return false here to reduce the amount of work done
        if (leafNodes.length > 1) {
          return false;
        }
      });
    }

    if (leafNodes.length === 0 && selection instanceof TextSelection && selection.$cursor) {
      leafNodes.push(selection.$cursor.parent);
    }

    return leafNodes;
  }

  private leafNodeIsAllSelection(selectedLeafNodes: ProseMirrorNode[]): boolean {
    return selectedLeafNodes.length === 1 && FlareFileTextEditorTagbarComponent.TagNamesTreatedAsAllSelection.includes(selectedLeafNodes[0].type.name);
  }

  private buildTagPath(editorState: EditorState): NodeTag[] {
    const selection = editorState.selection;
    const nodePath: { node: ProseMirrorNode, pos: number, to?: number }[] = [];

    resolvedPosForEachNode(selection.$from, (node, depth, $pos) => {
      nodePath.push({ node, pos: depth > 0 ? $pos.before(depth) : 0 });
    }, true);

    if (selection instanceof NodeSelection) {
      nodePath.push({ node: selection.node, pos: selection.from });
    }

    // Convert tbody to table group cell selection if row has given attribute
    for (let i = 0; i < nodePath.length; i++) {
      const node = nodePath[i].node;
      if (node.type.name === this.schema.nodes.table_row.name && node.attrs[MadCapTableRowGroupTypeAttrName]) {
        if (node.attrs[MadCapTableRowGroupTypeAttrName] === TableGroupType.THeader || node.attrs[MadCapTableRowGroupTypeAttrName] === TableGroupType.TBody || node.attrs[MadCapTableRowGroupTypeAttrName] === TableGroupType.TFooter) {
          const tableGroupId = node.attrs[MadCapTableRowGroupIdAttrName];
          const tbody = nodePath[i - 1];

          let from, to: number;
          tbody.node.descendants((n, pos) => {
            // Update the position of the first and last cell of the group
            if (n.type.name === 'table_cell' || n.type.name === 'table_header') {
              const cellPos = tbody.pos + 1 + pos;
              // Get the smallest position
              from = from || cellPos;
              // Update to the new larger position
              to = cellPos;
            }

            // Only use the children of the matching table row group id.
            // This will skip over rows that don't match and skip all cells so that only the rows are looped over.
            if (n.attrs[MadCapTableRowGroupIdAttrName] !== tableGroupId) {
              return false;
            }
          })

          const tableGroupNode = this.schema.nodes[node.attrs[MadCapTableRowGroupTypeAttrName]].create();
          nodePath[i - 1] = { node: tableGroupNode, pos: from, to: to };
        } else if (node.attrs[MadCapTableRowGroupTypeAttrName] === TableGroupType.TRow) {
          nodePath.splice(i - 1, 1); /* Has no tbody. Remove from path */
        }
      }
    }

    return nodePath
      .filter(tag => !FlareFileTextEditorTagbarComponent.IgnoredTagNames.includes(tag.node.type.name))
      .map(tag => {
        return {
          text: this.schema.getTagDisplayName(tag.node),
          pos: tag.pos,
          to: tag.to
        };
      });
  }

  private buildChangeList(editorState: EditorState, selectedLeafNode: ProseMirrorNode): TrackedChangeList {
    const selection = editorState.selection;
    let changeList: TrackedChangeList;

    if (selection instanceof NodeSelection) {
      // If the node has a tracked change THEN grab the attrs from it for the tag info
      if (selection.node.attrs.changeIds) {
        changeList = selection.node.attrs.changeList;
      }
    } else if (selectedLeafNode.isText) {
      // Look for a change mark and grab the attrs from it for the tag info
      changeList = getMark<TrackedChangeList>(selectedLeafNode, this.schema.marks.madcapchange, 'changeList');
    }

    // If no changes were found THEN check for change attrs
    if (!changeList) {
      // Find the first node with changes
      const changeNode = resolvedPosFindNode(selection.$from, node => node.attrs.changeIds);

      // If there is a node with a tracked change THEN grab the attrs from it for the tag info
      if (changeNode) {
        changeList = changeNode.attrs.changeList;
      }
    }

    // If the change list includes and untracked change
    if (Array.isArray(changeList) && changeList.some(change => change.changeType === ChangeType.Untracked)) {
      // Then ignore the changes
      changeList = null;
    }

    return Array.isArray(changeList) ? orderBy(changeList, 'timestamp', 'desc') : changeList;
  }

  private buildTagDetails(editorState: EditorState): TagDetail[] {
    const selection = editorState.selection as NodeSelection;
    const details: TagDetail[] = [];

    // Test for slide
    const slideNode = getNodeContainingSelection(editorState.selection, this.schema.nodes.madcapslide, true);
    if (slideNode) {
      const slideshowNode = resolvedPosFindNode(editorState.selection.$from, node => node.type === this.schema.nodes.madcapslideshow);
      if (slideshowNode) {
        for (let i = 0; i < slideshowNode.content.childCount; i += 1) {
          const child = slideshowNode.content.child(i);
          if (child === slideNode) {
            details.push({ label: 'Slide', text: `#${i + 1}` });
            break;
          }
        }
      }
    }

    // Test for link
    const link = getNodeContainingSelection(editorState.selection, node => node.type === this.schema.nodes.link || node.type === this.schema.nodes.madcapcrossreference, true);
    if (link) {
      details.push({ label: 'Link:', text: link.attrs.href });
    }
    // Test for snippet
    else if (selectionIsInNode(selection, this.schema.nodes.madcapsnippettext) || selectionIsInNode(selection, this.schema.nodes.madcapsnippetblock)) {
      details.push({ label: 'Snippet:', text: selection.node.attrs?.src });
    }
    // Test for image
    else if (selectionIsInNode(selection, this.schema.nodes.image)) {
      details.push({ label: 'Image:', text: selection.node.attrs?.src });
    }
    // Test for variable
    else if (selectionIsInNode(selection, this.schema.nodes.madcapvariable)) {
      details.push({ label: 'Variable:', text: selection.node.attrs?.name });
    }
    // Test for multimedia
    else if (selectionIsInNode(selection, this.schema.nodes.madcapmultimedia)) {
      details.push({ label: 'Multimedia:', text: selection.node.attrs?.src });
    }
    // Test for 3D model
    else if (selectionIsInNode(selection, this.schema.nodes.madcapmodel3d)) {
      details.push({ label: '3D Model:', text: selection.node.attrs?.src });
    }
    // Test for script
    else if (selectionIsInNode(selection, this.schema.nodes.script)) {
      details.push({ label: 'Script:', text: selection.node.attrs?.src });
    }

    // Test for target name
    const targetNode = getNodeContainingSelection(editorState.selection, node => !!node.attrs['MadCap:targetName']);
    if (targetNode) {
      details.push({ label: 'Target:', text: targetNode.attrs['MadCap:targetName'] });
    }

    return details.length > 0 ? details : null;
  }

  private buildAnnotation(editorState: EditorState): Annotation {
    const annotationNode = resolvedPosFindNode(editorState.selection.$from, node => node.type.name === 'madcapannotation');

    if (annotationNode) {
      return {
        'MadCap:createDate': annotationNode.attrs['MadCap:createDate'],
        'MadCap:creator': annotationNode.attrs['MadCap:creator'],
        'MadCap:editDate': annotationNode.attrs['MadCap:editDate'],
        'MadCap:editor': annotationNode.attrs['MadCap:editor']
      };
    }
  }
}
