import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PathDirnamePipe } from './path-dir-name.pipe';

@NgModule({
  declarations: [PathDirnamePipe],
  imports: [CommonModule],
  exports: [PathDirnamePipe]
})
export class PathDirnameModule { }
