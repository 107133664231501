import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CollabFileType } from '@common/collab/enums/collab-file-type.enum';
import { CollabSchema } from '@common/prosemirror/model/collab-schema';
import { CollabSession, CollabSessionTransactionTooLargeEvent } from '@common/sockets/collab-session';
import { CollabSessionSyncState } from '@common/sockets/enums/collab-session-sync-state.enum';
import { CollabSessionErrorEvent } from '@common/sockets/types/collab-session-error-event.type';
import { SubscriptionProperty } from '@common/util/subscription-property.decorator';
import { environment } from '@env/environment';
import { ApiService } from '@portal-core/auth/services/api.service';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { DataService } from '@portal-core/data/common/services/data.service';
import { FileSizeService } from '@portal-core/general/services/file-size.service';
import { OnlineService } from '@portal-core/general/services/online.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { EditorContextMenuEvent, TextEditorComponent } from '@portal-core/text-editor/components/text-editor/text-editor.component';
import { CollabSessionEventType } from '@portal-core/text-editor/enums/collab-session-event-type.enum';
import { CollabSessionEvent } from '@portal-core/text-editor/types/collab-session-event.type';
import { EditorChangeEvent } from '@portal-core/text-editor/types/editor-change-event.type';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { EditorState, Plugin } from 'prosemirror-state';
import { DirectEditorProps, EditorView, NodeViewConstructor } from 'prosemirror-view';
import { BehaviorSubject, Observable, Subscription, combineLatest, debounceTime, distinctUntilChanged, filter, map } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

/**
 * A text editor that connects to the collaborative server for editing files.
 */
@Component({
  selector: 'mc-collab-file-text-editor',
  templateUrl: './collab-file-text-editor.component.html',
  styleUrls: ['./collab-file-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class CollabFileTextEditorComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(TextEditorComponent, { static: true }) private textEditor: TextEditorComponent;

  @Input() branchName: string;
  @Input() commitId: string;
  @Input() fileId: number;
  @Input() filePath: string;
  @Input() fileType: CollabFileType;
  @Input() licenseUser: LicenseUser;
  @Input() nodeViews: Dictionary<NodeViewConstructor>;
  @Input() plugins: Plugin[];
  @Input() projectId: number;
  @Input() projectLanguage: string;
  @Input() schema: CollabSchema;

  @InputObservable('branchName') private branchName$: Observable<string>;
  @InputObservable('commitId') private commitId$: Observable<string>;
  @InputObservable('fileId') private fileId$: Observable<number>;
  @InputObservable('filePath') private filePath$: Observable<string>;
  @InputObservable('fileType') private fileType$: Observable<CollabFileType>;
  @InputObservable('licenseUser') private licenseUser$: Observable<LicenseUser>;
  @InputObservable('projectId') private projectId$: Observable<number>;
  @InputObservable('nodeViews') private nodeViews$: Observable<Dictionary<NodeViewConstructor>>;
  @InputObservable('plugins') private plugins$: Observable<Plugin[]>;
  @InputObservable('schema') private schema$: Observable<CollabSchema>;

  @Output() change: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();
  @Output() collabSessionEnd: EventEmitter<CollabSessionEvent> = new EventEmitter<CollabSessionEvent>();
  @Output() collabSessionStart: EventEmitter<CollabSessionEvent> = new EventEmitter<CollabSessionEvent>();
  @Output() editorContextMenu: EventEmitter<EditorContextMenuEvent> = new EventEmitter<EditorContextMenuEvent>();
  @Output() editorStateChange: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();
  @Output() errorChange: EventEmitter<CollabSessionErrorEvent> = new EventEmitter<CollabSessionErrorEvent>();
  @Output() exceedsMaxSizeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() exceedsMongoDBMaxSizeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() load: EventEmitter<void> = new EventEmitter<void>();
  @Output() offlineChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonlyChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sizeChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() syncStateChange: EventEmitter<CollabSessionSyncState> = new EventEmitter<CollabSessionSyncState>();
  @Output() selectionChange: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();

  @SubscriptionProperty() private collabSessionErrorSubscription: Subscription;
  @SubscriptionProperty() private collabSessionExceedsMaxSizeSubscription: Subscription;
  @SubscriptionProperty() private collabSessionExceedsMongoDBMaxSizeSubscription: Subscription;
  @SubscriptionProperty() private collabSessionOfflineSubscription: Subscription;
  @SubscriptionProperty() private collabSessionReadonlySubscription: Subscription;
  @SubscriptionProperty() private collabSessionSizeSubscription: Subscription;
  @SubscriptionProperty() private collabSessionSyncStateSubscription: Subscription;

  private collabSessionSubscription: Subscription;
  private collabSession: CollabSession;
  private onlineSubscription: Subscription;
  private reloadDataSource: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  private reload$: Observable<void> = this.reloadDataSource.asObservable();

  editorProps: Partial<DirectEditorProps> = {};
  readonly$: Observable<boolean>;

  get editorState(): EditorState {
    return this.textEditor ? this.textEditor.editorState : null;
  }

  get editorView(): EditorView {
    return this.textEditor ? this.textEditor.editorView : null;
  }

  get viewPluginOverlay(): HTMLElement {
    return this.textEditor?.viewPluginOverlay;
  }

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private dataService: DataService,
    private fileSizeService: FileSizeService,
    private ngZone: NgZone,
    private onlineService: OnlineService,
    private snackBar: MatSnackBar
  ) { }

  /**
   * Initializes the component by configuring the editor for a collaborative session.
   * Also sets up a listener for keeping track of the online status of the user.
   */
  ngOnInit() {
    this.editorProps.dispatchTransaction = (transaction) => {
      if (this.collabSession) {
        this.collabSession.dispatch(transaction);
      }
    };

    // Keep the collab session up to date on the online status of the user
    this.onlineSubscription = this.onlineService.online$.subscribe(online => {
      if (this.collabSession) {
        this.collabSession.offline = !online;
      }
    });
  }

  /**
   * Makes an observable subscription for creating and destroying the collaborative session based on the component's Inputs.
   * This is done in ngAfterViewInit instead of ngOnInit because mc-text-editor is ready to be used here but not in ngOnInit.
   */
  ngAfterViewInit() {
    // Subscribe in ngAfterViewInit because mc-text-editor is now ready to be used
    this.collabSessionSubscription = combineLatest([
      // We only care about a license user change if the license user has changed to a different license user
      this.licenseUser$.pipe(
        filter(licenseUser => !!licenseUser),
        distinctUntilChanged(this.dataService.sameIdentity)
      ),
      this.branchName$, this.commitId$, this.fileId$, this.filePath$, this.fileType$, this.projectId$, this.plugins$, this.schema$,
      this.reload$
    ]).pipe(
      debounceTime(5) // Debounce because these properties can change at the same time and we don't want to run through the pipe for every single one.
    ).subscribe(([licenseUser, branchName, commitId, fileId, filePath, fileType, projectId, plugins, schema]) => {
      // If all the necessary properties exist then create a collab session
      if (licenseUser && branchName && commitId && Number.isInteger(fileId) && filePath && fileType && Number.isInteger(projectId) && plugins && schema) {
        this.createCollabSession();
      } else { // Else destroy a collab session if it exist
        this.destroyCollabSession();
      }
    });
  }

  /**
   * Cleans up the component by destroying the collaborative session.
   */
  ngOnDestroy() {
    this.destroyCollabSession();
  }

  onEditorContextMenu(editorEvent: EditorContextMenuEvent) {
    this.editorContextMenu.emit(editorEvent);
  }

  /**
   * Sets the dirty state of the ChangePlugin to false.
   * Any subsequent change events will only be dirty if the user makes a new change.
   * */
  markAsPristine() {
    this.textEditor.markAsPristine();
  }

  /**
   * Forces an existing collaborative session to be destroyed and a new one created.
   * A new session is created only if the required Inputs are provided.
   */
  reload() {
    this.reloadDataSource.next(null);
  }

  /**
   * Stops a collaborative session if it exists.
   * Cleans up all resources and unsubscribes from all events associated with the session.
   */
  private destroyCollabSession() {
    if (this.collabSession) {
      this.collabSessionErrorSubscription = null;
      this.collabSessionOfflineSubscription = null;
      this.collabSessionReadonlySubscription = null;
      this.collabSessionSyncStateSubscription = null;

      this.collabSession.stop();
      this.collabSession = null;
      this.collabSessionEnd.emit({
        session: null,
        type: CollabSessionEventType.End
      });
    }
  }

  /**
   * Creates a new connection to the collaborative server.
   * If an existing session exists it will be destroyed first.
   */
  private createCollabSession() {
    // Stop an existing collab session
    this.destroyCollabSession();

    // Create a new collab session
    this.collabSession = new CollabSession({
      authToken: this.authService.getAccessToken(),
      clientID: uuidv4(),
      collabAuthorityUrl: this.apiService.collabAuthorityUrl,
      editorState: this.textEditor.editorState,
      fileId: this.fileId,
      fileType: this.fileType,
      offline: !this.onlineService.online,
      plugins: this.plugins,
      schema: this.schema,
      socketMaxHttpBufferSizeBytes: environment.collabSocketMaxHttpBufferSizeBytes ? parseInt(environment.collabSocketMaxHttpBufferSizeBytes, 10) : undefined,
      userID: this.licenseUser?.User?.Id ?? null
    });

    this.collabSession.on('load', session => this.load.emit());
    this.collabSession.on('update', session => this.textEditor.editorView.updateState(session.editorState));
    this.collabSession.on('transactionTooLarge', event => {
      this.ngZone.run(() => {
        this.showTransactionTooLargeError(event);
      })
    });

    this.collabSessionErrorSubscription = this.collabSession.error$.subscribe(error => this.errorChange.emit(error));
    this.collabSessionExceedsMaxSizeSubscription = this.collabSession.exceedsMaxSize$.subscribe(exceedsMaxSize => this.exceedsMaxSizeChange.emit(exceedsMaxSize));
    this.collabSessionExceedsMongoDBMaxSizeSubscription = this.collabSession.exceedsMongoDBMaxSize$.subscribe(exceedsMongoDBMaxSize => this.exceedsMongoDBMaxSizeChange.emit(exceedsMongoDBMaxSize));
    this.collabSessionOfflineSubscription = this.collabSession.offline$.subscribe(offline => this.offlineChange.emit(offline));
    this.collabSessionReadonlySubscription = this.collabSession.readOnly$.subscribe(readonly => this.readonlyChange.emit(readonly));
    this.collabSessionSizeSubscription = this.collabSession.size$.subscribe(size => this.sizeChange.emit(size));
    this.collabSessionSyncStateSubscription = this.collabSession.syncState$.subscribe(syncState => this.syncStateChange.emit(syncState));

    // Put the editor into readonly mode if the document can't be saved to MongoDB because its too large for MongoDB
    this.readonly$ = combineLatest([
      this.collabSession.exceedsMongoDBMaxSize$,
      this.collabSession.readOnly$,
    ]).pipe(
      map(([exceedsMongoDBMaxSize, readOnly]) => exceedsMongoDBMaxSize || readOnly)
    );

    // Start the collab session
    this.collabSession.start();

    this.collabSessionStart.emit({
      session: this.collabSession,
      type: CollabSessionEventType.Start
    });
  }

  private showTransactionTooLargeError(event: CollabSessionTransactionTooLargeEvent) {
    this.snackBar.open(`Changes ignored because they exceed ${this.fileSizeService.format(event.maxSize, 0)} limit.`, 'OK');
  }
}
