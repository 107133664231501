import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavbarHelpButtonComponent } from './navbar-help-button.component';

@NgModule({
  declarations: [
    NavbarHelpButtonComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule
  ],
  exports: [
    NavbarHelpButtonComponent
  ]
})
export class NavbarHelpButtonModule { }
