import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { SiteUrlsState } from '@portal-core/sites/services/site-urls/site-urls-data.service';

@NgModule({
  imports: [
    NgxsModule.forFeature([SiteUrlsState])
  ]
})
export class SiteUrlsServicesModule { }
