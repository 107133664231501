import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs, toAttributeBoolean, toAttributeBooleanString } from '@common/util/schema';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';

export class MadCapMultipleChoiceSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapmultiplechoice: {
      group: 'block',
      content: '(madcapquestion | madcapmultiplechoiceitem | madcapcorrectfeedback | madcapincorrectfeedback | madcapsubmitquestionbutton | madcapsnippetblock | mcCentralContainer | div | bullet_list | ordered_list | blockquote | fieldset | form)*',
      attrs: {
        'MadCap:isMultiResponse': {
          default: undefined,
          toFlareXML(value: any): string {
            return toAttributeBooleanString(value);
          }
        },
        'MadCap:questionID': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:multipleChoice',
        getAttrs(dom: HTMLElement) {
          const attrs = getSchemaAttrs(dom, ['MadCap:questionID', 'MadCap:isMultiResponse']);

          return {
            ...attrs,
            'MadCap:isMultiResponse': toAttributeBoolean(attrs['MadCap:isMultiResponse'])
          };
        }
      }],
      toDOM(node: ProseMirrorNode) {
        return ['div', {
          class: `mc-madcap-multiple-choice mc-madcap-multiple-choice-${node.attrs['MadCap:isMultiResponse'] ? 'multi' : 'single'}-answer`
        }, 0];
      },
      tagName: 'MadCap:multipleChoice'
    },

    madcapquestion: {
      group: 'block',
      content: 'block+',
      fillIfContentDeleted: 'mcCentralContainer',
      defining: true,
      parseDOM: [{ tag: 'MadCap\\:question' }],
      toDOM() { return ['div', { class: 'mc-madcap-question' }, 0]; },
      tagName: 'MadCap:question'
    },

    madcapmultiplechoiceitem: {
      group: 'block',
      content: 'block+',
      fillIfContentDeleted: 'mcCentralContainer',
      defining: true,
      attrs: {
        'MadCap:correctAnswer': {
          default: undefined,
          toFlareXML(value: any): string {
            return toAttributeBooleanString(value);
          }
        }
      },
      parseDOM: [{
        tag: 'MadCap\\:multipleChoiceItem',
        getAttrs(dom: HTMLElement) {
          const attrs = getSchemaAttrs(dom, ['MadCap:correctAnswer']);

          return {
            ...attrs,
            'MadCap:correctAnswer': toAttributeBoolean(attrs['MadCap:correctAnswer'])
          };
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-multiple-choice-item' }, 0]; },
      tagName: 'MadCap:multipleChoiceItem'
    },

    madcapcorrectfeedback: {
      group: 'block',
      content: 'block+',
      fillIfContentDeleted: 'mcCentralContainer',
      isolating: true,
      defining: true,
      parseDOM: [{ tag: 'MadCap\\:correctFeedback' }],
      toDOM() { return ['div', { class: 'mc-madcap-correct-feedback' }, 0]; },
      tagName: 'MadCap:correctFeedback'
    },

    madcapincorrectfeedback: {
      group: 'block',
      content: 'block+',
      fillIfContentDeleted: 'mcCentralContainer',
      isolating: true,
      defining: true,
      parseDOM: [{ tag: 'MadCap\\:incorrectFeedback' }],
      toDOM() { return ['div', { class: 'mc-madcap-incorrect-feedback' }, 0]; },
      tagName: 'MadCap:incorrectFeedback'
    },

    madcapsubmitquestionbutton: {
      group: 'block',
      parseDOM: [{ tag: 'MadCap\\:submitQuestionButton' }],
      toDOM() { return ['div', { class: 'mc-madcap-submit-question-button' }, 'Submit']; },
      tagName: 'MadCap:submitQuestionButton',
      isVoid: true
    }
  };
}
