import { ChangeNodeAttrs } from '@common/prosemirror/changeset/change-node-attrs.type';
import { ChangeType } from '@common/prosemirror/changeset/change-type.enum';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';

export class BrSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    hard_break: {
      inline: true,
      group: 'inline',
      parseDOM: [
        { tag: 'br' },
        {
          tag: 'span',
          getAttrs(dom: HTMLElement) {
            if (!dom.classList.contains('mc-html-br')) {
              return false;
            }
          }
        }
      ],
      toDOM(node: ProseMirrorNode) {
        const attrs: ChangeNodeAttrs = node.attrs;
        const changeList = attrs.changeList;

        // If this is a remove change then render the <br> as a <span> so that there is no actual break in the editor's content
        if (Array.isArray(changeList) && changeList.find(trackedChange => trackedChange.changeType === ChangeType.Remove)) {
          return ['span', { class: 'mc-html-br' }, ' '];
        } else {
          return ['br'];
        }
      },
      tagName: 'br',
      isVoid: true
    }
  };
}
