import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FlareFileTextEditorFeatures } from '@portal-core/project-files/util/flare-file-text-editor-features';

export interface FlareFileTextEditorFeatureUnsupportedDialogData {
  feature: keyof FlareFileTextEditorFeatures;
}

@Component({
  selector: 'mc-flare-file-text-editor-feature-unsupported-dialog',
  templateUrl: './flare-file-text-editor-feature-unsupported-dialog.component.html',
  styleUrls: ['./flare-file-text-editor-feature-unsupported-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlareFileTextEditorFeatureUnsupportedDialogComponent {
  static DialogConfig: MatDialogConfig = {
    width: '36rem'
  };

  feature: keyof FlareFileTextEditorFeatures;

  constructor(@Inject(MAT_DIALOG_DATA) private data: FlareFileTextEditorFeatureUnsupportedDialogData) {
    this.feature = data?.feature;
  }
}
