import { Injectable } from '@angular/core';
import { MetaDataValues } from '@common/meta-data/types/meta-data-values.type';
import { ProjectConditionSet } from '@portal-core/project-files/conditions/models/project-condition-set.model';
import { ProjectConditionTag } from '@portal-core/project-files/conditions/models/project-condition-tag.model';
import { CommittedItem } from '@portal-core/project-files/models/committed-item.model';
import { MetaDataApiService } from '@portal-core/project-files/services/meta-data-api.service';
import { ProjectVariableSet } from '@portal-core/project-files/variables/models/project-variable-set.model';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MetaDataService {
  variablesMetaDataGroupName: string = 'variables';
  conditionTagsMetaDataGroupName: string = 'conditionTags';

  constructor(private metaDataApiService: MetaDataApiService) { }

  getMetaData$(groupNames: string[], committedItem: CommittedItem): Observable<MetaDataValues> {
    return this.metaDataApiService.getMetaData$(groupNames, committedItem).pipe(
      // If no metadata generated yet return null.
      map(data => data.metaData ? data : null)
    );
  }

  getProjectVariableSets$(committedItem: CommittedItem): Observable<ProjectVariableSet[]> {
    return this.getMetaData$([this.variablesMetaDataGroupName], committedItem).pipe(
      // If no data then return null. It means that metadata is not generated yet.
      map(data => data ? this.extractVariableSetsFromMetaData(data.metaData) : null)
    );
  }

  extractVariableSetsFromMetaData(metaData: Dictionary<Dictionary>): ProjectVariableSet[] {
    const variablesMetaData = metaData[this.variablesMetaDataGroupName];
    if (!variablesMetaData) {
      return;
    }

    const setDict: Dictionary = {};
    const variableSets: ProjectVariableSet[] = [];

    for (const key of Object.keys(variablesMetaData)) {
      const metaDataVariable = variablesMetaData[key];
      let set: ProjectVariableSet = setDict[metaDataVariable.set];
      if (!set) {
        set = setDict[metaDataVariable.set] = { Name: metaDataVariable.set, Definitions: [] };
        variableSets.push(set);
      }
      const definitions = [metaDataVariable].concat(Object.values(metaDataVariable.definitions ?? []))
        .map(item => { return { 'Definition': item.definition, 'Comment': item.comment } });
      set.Definitions.push({
        SetName: metaDataVariable.set,
        Name: metaDataVariable.name,
        LongName: key,
        Definition: metaDataVariable.definition,
        Comment: metaDataVariable.comment,
        AllDefinitions: definitions
      });
    };

    return variableSets;
  }

  getProjectConditionSets$(committedItem: CommittedItem): Observable<ProjectConditionSet[]> {
    return this.getMetaData$([this.conditionTagsMetaDataGroupName], committedItem).pipe(
      // If no data then return null. It means that metadata is not generated yet.
      map(data => data ? this.extractConditionSetsFromMetaData(data.metaData) : null)
    );
  }

  extractConditionSetsFromMetaData(metaData): ProjectConditionSet[] {
    const conditionsTags = this.extractConditionTagsFromMetaData(metaData);
    if (!conditionsTags) {
      return;
    }

    const setDict = {};
    const conditionSets: ProjectConditionSet[] = [];

    conditionsTags.forEach(tag => {
      const setName = tag.Set;
      let set: ProjectConditionSet = setDict[setName];
      if (!set) {
        set = setDict[setName] = { Name: setName, ConditionTags: [] };
        conditionSets.push(set);
      }
      set.ConditionTags.push(tag);
    });

    return conditionSets;
  }

  extractConditionTagsFromMetaData(metaData): ProjectConditionTag[] {
    const conditionsMetaData = metaData[this.conditionTagsMetaDataGroupName];
    if (!conditionsMetaData) {
      return;
    }

    const tags: ProjectConditionTag[] = [];
    for (const key of Object.keys(conditionsMetaData)) {
      const tag = conditionsMetaData[key];
      tags.push({ Id: tag.longName, Set: tag.set, Name: tag.name, BackgroundColor: tag.backgroundColor, Comment: tag.comment });
    };
    return tags;
  }
}
