import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { UndefinedConditionTag } from '@portal-core/reports/models/undefined-condition-tags.model';

@CollectionStateSelectors({
  stateType: ProjectReportsUndefinedConditionTagsDataState
})
@State<ProjectReportsUndefinedConditionTagsDataState>({
  name: ProjectReportsUndefinedConditionTagsDataState.source
})
@Injectable()
export class ProjectReportsUndefinedConditionTagsDataState extends CollectionStateBase {
  static source = 'ProjectReportsUndefinedConditionTags';
  getSource(): string {
    return ProjectReportsUndefinedConditionTagsDataState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectReportsUndefinedConditionTagsDataService extends CollectionDataServiceBase<UndefinedConditionTag> {
  constructor(protected store: Store) {
    super(store, ProjectReportsUndefinedConditionTagsDataState);
  }
}
