import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { TaskAnalyticsState } from '@portal-core/task-analytics/services/task-analytics-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([TaskAnalyticsState])
  ]
})
export class TaskAnalyticsServicesModule { }
