<input class="mc-hidden" type="file" [accept]="accept" [disabled]="disabled" [multiple]="multiple" #fileInput (change)="onFileChanged($event)">

<ng-container *ngIf="layout === 'button'">
  <button #inputButton type="button" mat-raised-button [color]="color" [disabled]="disabled" (click)="fileInput.click()">
    <mat-icon fontSet="project-titan" fontIcon="icon-upload" class="mc-file-picker-button-icon"></mat-icon>

    <span *ngIf="value !== null && value[0]; else placeholderTemplate" class="mc-file-picker-name-label">{{ value[0]?.name }}</span>
    <ng-template #placeholderTemplate>
      <span *ngIf="placeholder" class="mc-file-picker-placeholder-label">{{ placeholder }} {{ required ? '*' : '' }}</span>
    </ng-template>
  </button>

  <button *ngIf="value?.length" type="button" mat-button [color]="color" class="mc-button-small" (click)="onRemoveFileClicked(0)">Remove</button>
</ng-container>

<ng-container *ngIf="layout === 'field'">
  <ng-container *ngIf="!empty || !placeholder; else emptyPlaceholder">
    <mat-chip-list class="mc-file-picker-chip-list">
      <mat-chip *ngFor="let file of value; let index = index" class="mc-file-picker-chip" [removable]="true" [selectable]="false" (removed)="onFileChipRemoved(index)">
        <mc-self-truncating-path class="mc-file-picker-value" [filePath]="isPathWithLeadingSlash ? (file.filePath | mcRemoveLeadingSlash) : file.filePath"></mc-self-truncating-path>
        <mat-icon matChipRemove fontSet="project-titan" fontIcon="icon-close"></mat-icon>
      </mat-chip>
    </mat-chip-list>
  </ng-container>
  <ng-template #emptyPlaceholder>
    <input class="mc-file-picker-empty-placeholder" matInput [placeholder]="placeholder" readonly="true" type="text">
  </ng-template>
</ng-container>
