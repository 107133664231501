import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { nodeToFlareXML } from '@common/flare/util/prosemirror-flare-xml';
import { voidTags } from '@common/html/util/xml';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { defaultParseRulePriority } from '@common/util/schema';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';

// List of all possible html tags. Commented ones are either not inline or handled elsewhere
const htmlTags = [
  'abbr',
  'acronym',
  'applet',
  'area',
  'audio',
  'b',
  'basefont',
  'bgsound',
  'big',
  'blink',
  'canvas',
  'cite',
  'code',
  'datalist',
  'del',
  'dfn',
  'dialog',
  'em',
  'embed',
  'font',
  'frame', // TODO: define frame in its own schema because it is a void tag
  'frameset', // TODO: define frameset in its own schema because frameset can only contain <frameset> or <frame> or <noframes>
  'i',
  'ilayer',
  'ins',
  'isindex',
  'kbd',
  'keygen',
  'label',
  'layer',
  'legend',
  'node',
  'mark',
  'menuitem',
  'multicol',
  'nobr',
  'noembed',
  'optgroup',
  'option',
  'output',
  'param',
  'picture',
  'plaintext',
  'q',
  'rp',
  'rt',
  'ruby',
  's',
  'spacer',
  'small',
  'source',
  'span',
  'strike',
  'strong',
  'sub',
  'sup',
  'time',
  'title',
  'track',
  'tt',
  'u',
  'wbr',
  'xmp',
];

// Display tags as a span instead
const inlineTagsAsSpan = [
  'bdi',
  'bdo'
];

// Display tags as a block
const blockTags = [
  'address',
  'base',
  'center',
  'comment',
  'map',
  'marquee',
  'menu',
  'meter',
  'progress',
  'samp',
  'select',
  'textarea',
  'var',
];

// Display tags as a div instead
const blockTagsAsDiv = [
  'noframes',
  'noscript',
];

export class HtmlTagsSchemaPlugin extends SchemaPlugin {
  constructor() {
    super();

    this.nodes = {
      // Display as gray box with label
      button: {
        group: 'block',
        parseDOM: [{
          tag: 'button',
          priority: defaultParseRulePriority - 1 // Lower the priority from the default 50 so that nodes that match with particular classes or styles have precedence
        }],
        toDOM() { return ['span', { class: 'mc-pm-button' }, 'Button']; }
      },

      // Deprecated. Children are <li>
      dir: {
        group: 'block',
        content: 'list_item+',
        parseDOM: [{
          tag: 'dir',
          priority: defaultParseRulePriority - 1 // Lower the priority from the default 50 so that nodes that match with particular classes or styles have precedence
        }],
        toDOM() { return ['ul', { class: 'mc-pm-dir' }, 0]; },
        specContent: 'list_item'
      },

      template: {
        group: 'inline',
        inline: true,
        attrs: {
          content: { default: undefined }
        },
        parseDOM: [{
          tag: 'template',
          priority: defaultParseRulePriority - 1, // Lower the priority from the default 50 so that nodes that match with particular classes or styles have precedence
          getAttrs(dom: HTMLElement) {
            return {
              content: dom.outerHTML
            };
          }
        }],
        toDOM() { return ['span', { class: 'mc-pm-template' }, 'Template']; },
        toFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
          return nodeToFlareXML(node, options, 'template', {
            xml: node.attrs.content
          });
        }
      }
    };

    htmlTags.forEach(tag => {
      this.nodes[tag] = this.createNodeSpec(tag, 'inline');
    });

    inlineTagsAsSpan.forEach(tag => {
      this.nodes[tag] = this.createNodeSpec(tag, 'inline', 'span');
    });

    blockTags.forEach(tag => {
      this.nodes[tag] = this.createNodeSpec(tag, 'block');
    });

    blockTagsAsDiv.forEach(tag => {
      this.nodes[tag] = this.createNodeSpec(tag, 'block', 'div');
    });
  }

  // Creates a NodeSpec with a custom class to style in mc-text-editor
  createNodeSpec(tagName: string, group: string, editorTag?: string, content: string = 'inline*'): NodeSpec {
    const spec: NodeSpec = {
      group: group,
      content: content,
      parseDOM: [{
        tag: tagName,
        preserveWhitespace: group === 'inline',
        priority: defaultParseRulePriority - 1 // Lower the priority from the default 50 so that nodes that match with particular classes or styles have precedence
      }],
      toDOM() { return [editorTag ? editorTag : tagName, { class: 'mc-pm-' + tagName }, 0]; },
      isVoid: voidTags.includes(tagName)
    };

    if (group === 'inline') {
      spec.inline = true;
      spec.definingForContent = true;
    }

    return spec;
  }
}
