import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { BrokenLink } from '@portal-core/reports/models/broken-link.model';

@CollectionStateSelectors({
  stateType: ProjectReportBrokenLinksState
})
@State<ProjectReportBrokenLinksState>({
  name: ProjectReportBrokenLinksState.source
})
@Injectable()
export class ProjectReportBrokenLinksState extends CollectionStateBase {
  static source = 'ProjectReportBrokenLinks';
  getSource(): string {
    return ProjectReportBrokenLinksState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectReportsBrokenLinksDataService extends CollectionDataServiceBase<BrokenLink> {
  constructor(protected store: Store) {
    super(store, ProjectReportBrokenLinksState);
  }
}
