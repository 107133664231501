import { Injectable } from '@angular/core';
import { BuildsDataService } from '@portal-core/builds/services/builds-data.service';
import { CollectionActionsService } from '@portal-core/data/collection/services/collection-actions.service';
import { ModelIds } from '@portal-core/data/common/types/mc-model.type';
import { ProjectsState } from '@portal-core/projects/services/projects-data.service';

/**
 * Monitors the Projects data store for delete events and automatically removes corresponding project builds from the Builds data store.
 */
@Injectable({
  providedIn: 'root'
})
export class ProjectDeleterEffect {
  constructor(private collectionActionsService: CollectionActionsService, private buildsDataService: BuildsDataService) {
    this.collectionActionsService.deleteItems$(ProjectsState.source).subscribe(projectIds => this.deleteProject(projectIds));
  }

  private deleteProject(projectIds: ModelIds): void {
    const buildsItems = this.buildsDataService.getItems();
    const result = this.getIdsByProjectIds(projectIds, buildsItems);
    if (result.length) {
      this.buildsDataService.deleteItems$(result);
    }
  }

  private getIdsByProjectIds(projectIds: ModelIds, buildsItems): ModelIds {
    const result = [];
    for (const item in buildsItems) {
      let projectId = buildsItems[item].ProjectId;

      if (projectIds.includes(projectId)) {
        result.push(buildsItems[item].Id);
      }
    }

    return result;
  }
}
