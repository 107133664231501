<mat-toolbar color="secondary">
  <button *ngIf="currentLicenseUser$ | async as licenseUser" class="mc-toolbar-item-full" color="secondary-invert" mat-button type="button" (click)="onUserSettingsClicked(licenseUser)">
    <mc-license-user-avatar class="mc-app-navbar-user-launch-pad-license-user-avatar" [disabled]="true" [licenseUser]="licenseUser" [showAdmin]="true" [showOnlineStatus]="true" size="small"></mc-license-user-avatar>
  </button>

  <button mat-icon-button color="secondary-invert" matTooltip="Open message center" *mcIfAuthor (click)="onMessagesClicked()">
    <mat-icon fontSet="project-titan" fontIcon="icon-mail" matBadge="&ZeroWidthSpace;" matBadgeColor="primary" matBadgeSize="small" class="mc-badge-empty" [matBadgeHidden]="!(currentLicenseUser$ | async)?.HasNewMessages"></mat-icon>
  </button>
  <button mat-icon-button color="secondary-invert" matTooltip="View notifications" *mcIfAuthor (click)="onAlertsClicked()">
    <mat-icon fontSet="project-titan" fontIcon="icon-bell" matBadge="&ZeroWidthSpace;" matBadgeColor="warn" matBadgeSize="small" class="mc-badge-empty" [matBadgeHidden]="!(currentLicenseUser$ | async)?.HasNewNotifications"></mat-icon>
  </button>
  <button *ngIf="showTaskSearchBox" mat-icon-button color="secondary-invert" matTooltip="Search" [matMenuTriggerFor]="searchBox">
    <mat-icon fontSet="project-titan" fontIcon="icon-search"></mat-icon>
  </button>

  <mc-navbar-help-button [appVersionAndBuildDate]="appVersionAndBuildDate$ | async"></mc-navbar-help-button>

  <mc-app-license-dropdown></mc-app-license-dropdown>
</mat-toolbar>

<mat-menu #searchBox="matMenu" class="popup-card" [overlapTrigger]="false">
  <ng-template matMenuContent>
    <div class="mc-navbar-user-launch-pad-search-container">
      <mat-form-field class="mc-navbar-user-launch-pad-search-box" color="primary-vivid" (click)="$event.stopPropagation();">
        <input autofocus matInput placeholder="Search Tasks" [(ngModel)]="taskSearchValue" (keyup.enter)="onSearchEntered()">
      </mat-form-field>
      <button mat-icon-button (click)="onSearchClearClicked()">
        <mat-icon [fontSet]="'project-titan'" [fontIcon]="'icon-close'"></mat-icon>
      </button>
    </div>
  </ng-template>
</mat-menu>