import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ErrorCodeModule } from '@portal-core/errors/components/error-code/error-code.module';
import { ErrorCodeExpansionPanelComponent } from './error-code-expansion-panel.component';

@NgModule({
  declarations: [
    ErrorCodeExpansionPanelComponent
  ],
  imports: [
    CommonModule,
    ErrorCodeModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule
  ],
  exports: [
    ErrorCodeExpansionPanelComponent
  ]
})
export class ErrorCodeExpansionPanelModule { }
