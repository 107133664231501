import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { UndefinedVariable } from '@portal-core/reports/models/undefined-variables.model';

@CollectionStateSelectors({
  stateType: ProjectReportsUndefinedVariablesState
})
@State<ProjectReportsUndefinedVariablesState>({
  name: ProjectReportsUndefinedVariablesState.source
})
@Injectable()
export class ProjectReportsUndefinedVariablesState extends CollectionStateBase {
  static source = 'ProjectReportsUndefinedVariables';
  getSource(): string {
    return ProjectReportsUndefinedVariablesState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectReportsUndefinedVariablesDataService extends CollectionDataServiceBase<UndefinedVariable> {
  constructor(protected store: Store) {
    super(store, ProjectReportsUndefinedVariablesState);
  }
}
