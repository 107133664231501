<ul class="mc-flare-file-text-editor-gutter-list">
  <li *ngFor="let item of items; trackBy: trackGutterItemsBy" #gutterItem [attr.data-id]="item.id" class="mc-flare-file-text-editor-gutter-item" (mouseenter)="onItemMouseEnter(item)" (mouseleave)="onItemMouseLeave(item)" (click)="onItemClicked(item)">
    <div *ngIf="item.type === GutterItemType.Annotation" class="mc-flare-file-text-editor-gutter-item-body mc-flare-file-text-editor-gutter-annotation-item-body mc-border-divider">
      <div class="mc-flare-file-text-editor-gutter-item-avatar">
        <div class="mc-flare-file-text-editor-gutter-item-initials mc-bg-avatar mc-text-avatar" [matTooltip]="(item.data['MadCap:creatorCentralUserId'] ? '' : 'Flare Author ') + item.data['MadCap:creator']">
          {{ item.data['MadCap:initials'] }}
        </div>
      </div>

      <textarea class="mc-flare-file-text-editor-gutter-annotation-comment" [disabled]="readonly || licenseUser?.User?.Id !== item.data['MadCap:creatorCentralUserId']" placeholder="Click to comment" rows="1" [ngClass]="readonly ? 'mc-bg-component' : ''" [(ngModel)]="item.data['MadCap:comment']" (blur)="onAnnotationCommentBlurred(item)" (change)="onAnnotationCommentChanged(item)" (focus)="onAnnotationCommentFocused(item)" (input)="onAnnotationCommentInput(item)"></textarea>
    </div>

    <div *ngIf="item.type === GutterItemType.Change" class="mc-flare-file-text-editor-gutter-item-body mc-flare-file-text-editor-gutter-change-item-body mc-border-divider">
      <div class="mc-flare-file-text-editor-gutter-item-avatar">
        <div class="mc-flare-file-text-editor-gutter-item-initials mc-bg-avatar mc-text-avatar" [matTooltip]="(item.data?.creatorCentralUserId ? '' : 'Flare Author ') + item.data?.userName">
          {{ item.data?.initials }}
        </div>
      </div>

      <div *ngIf="item.data.changeType === ChangeType.Add" class="mc-flare-file-text-editor-gutter-change-type">Added</div>
      <div *ngIf="item.data.changeType === ChangeType.Remove" class="mc-flare-file-text-editor-gutter-change-type">Deleted</div>
      <div *ngIf="item.data.changeType === ChangeType.Replace" class="mc-flare-file-text-editor-gutter-change-type">Modified</div>
      <div *ngIf="item.data.changeType === ChangeType.Bind" class="mc-flare-file-text-editor-gutter-change-type">Wrapped</div>
      <div *ngIf="item.data.changeType === ChangeType.Unbind" class="mc-flare-file-text-editor-gutter-change-type">Unbound</div>
      <div *ngIf="item.data.changeType === ChangeType.Attributes" class="mc-flare-file-text-editor-gutter-change-type">Attribute Changed</div>
    </div>
  </li>
</ul>