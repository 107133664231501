<input #textInput matInput type="text" [formControl]="textControl">
<!-- this button exists just to run openPopup() and be a trigger -->
<button type="button" #trigger mat-icon-button mcPopupClass="mc-style-picker-input-popup" class="mc-hidden" [disabled]="disabled" [mcPopupTriggerDisabled]="disabled" [mcPopupTriggerFor]="popup" [tabindex]="tabIndex">
  <mat-icon fontSet="project-titan"></mat-icon>
</button>

<mc-popup #popup (closed)="onPopupClosed()">
  <mc-css-length-popup [label]="label" [measurement]="value" [presets]="presets"></mc-css-length-popup>
  <div class="mc-form-buttons">
    <button type="button" mat-stroked-button color="warn" (click)="onCancelClicked()">Cancel</button>
    <button type="button" mat-raised-button color="primary" (click)="onOKClicked()">OK</button>
  </div>
</mc-popup>