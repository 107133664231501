import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { parseStyles, stringifyStyles } from '@common/html/util/style';
import { ErrorCode } from '@common/http/enums/error-code.enum';
import { firstOrNull, makeArray } from '@common/util/array';
import { dirname, isAbsoluteUrl, isCentralUrl, isDataUrl, relative, resolvePath } from '@common/util/path';
import { MaxAttachmentSizeBytes } from '@portal-core/data/common/constants/max-attachment-size.constant';
import { FormsService } from '@portal-core/forms/services/forms.service';
import { FileWithPath } from '@portal-core/general/classes/file-with-path';
import { FileService } from '@portal-core/general/services/file.service';
import { StringService } from '@portal-core/general/services/string.service';
import { ImagesFilter } from '@portal-core/project-files/constants/file-filters.constants';
import { ProjectFolder } from '@portal-core/project-files/enums/project-folder.enum';
import { FileWithError } from '@portal-core/project-files/models/file-with-error.model';
import { EditorImageExtensions } from '@portal-core/text-editor/constants/editor-image-extensions.constant';
import { EditorImageFileTypes } from '@portal-core/text-editor/constants/editor-image-types.constant';
import { TextEditorService } from '@portal-core/text-editor/services/text-editor.service';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { UserSettings } from '@portal-core/users/interfaces/user-settings';
import { USER_SETTINGS_SERVICE } from '@portal-core/users/tokens/user-settings-service.token';
import { LoadingState } from '@portal-core/util/loading-state';
import { Attrs } from 'prosemirror-model';
import { Observable, of, Subscription } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DimensionStylePresets } from '@portal-core/project-files/constants/dimension-style-presets.constant';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';

export interface ProjectFilesImageDialogData {
  commitId: string;
  projectId: number;
  editorFilePath: string;
  width: string;
  height: string;
  alt: string;
  isReview: boolean;
  branch: string;
  initialSrc?: string;
}

export interface ProjectFilesImageDialogResult {
  attrs?: {
    src?: string;
    alt?: string;
    style?: Attrs;
  }
  file?: FileWithError;
}

interface ProjectFilesImageDialogForm {
  from: FormControl<string>,
  webLink: FormControl<string>,
  projectImagePath: FormControl<string[]>,
  dataUri: FormControl<string>,
  newFile: FormControl<FileWithError[]>,
  alt: FormControl<string>,
  width: FormControl<string>,
  height: FormControl<string>
}

@Component({
  selector: 'mc-project-files-image-dialog',
  templateUrl: './project-files-image-dialog.component.html',
  styleUrls: ['./project-files-image-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class ProjectFilesImageDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '50rem',
  };

  public get isTif() {
    return this.previewSrc?.endsWith('.tif') || this.previewSrc?.endsWith('.tiff');
  }

  // Dialog data
  commitId: string;
  projectId: number;
  editorFilePath: string;
  width: string;
  height: string;
  alt: string;
  initialSrc: string;
  isReview: boolean;
  branch: string;

  previewSrc: string;
  imageForm: FormGroup<ProjectFilesImageDialogForm>;
  imageFilter: string = ImagesFilter;
  ProjectFolder: typeof ProjectFolder = ProjectFolder;
  loadingState: LoadingState<ErrorCode> = new LoadingState<ErrorCode>();
  previewLoadingState: LoadingState<ErrorCode> = new LoadingState<ErrorCode>();
  imageAcceptAttr: string = EditorImageExtensions.map(ext => '.' + ext).join(', ');
  initialFiles: FileWithPath[];

  fromSubscription: Subscription;
  valueSubscription: Subscription;
  loadFileSubscription: Subscription;

  presets = DimensionStylePresets;

  constructor(
    protected dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ProjectFilesImageDialogData,
    @Inject(USER_SETTINGS_SERVICE) private userSettingsService: UserSettings,
    protected dialogRef: MatDialogRef<ProjectFilesImageDialogComponent>,
    private fb: UntypedFormBuilder,
    private formsService: FormsService,
    private fileService: FileService,
    private textEditorService: TextEditorService,
    private stringService: StringService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.commitId = this.data.commitId;
    this.projectId = this.data.projectId;
    this.editorFilePath = this.data.editorFilePath;
    this.isReview = this.data.isReview || false;
    this.branch = this.data.branch;
    this.initialSrc = this.data.initialSrc;
    this.alt = this.data.alt;

    // set width and height, if user is not editing use what they did before.
    if (this.data.width || this.data.height) {
      this.width = this.data.width;
      this.height = this.data.height;
    } else {
      const settingStyle = parseStyles(this.userSettingsService.getSettingByName('flare-text-editor:image:style'));
      this.width = settingStyle?.width;
      this.height = settingStyle?.height;
    }

    let loadFile$: Observable<Blob> = of(null);
    let resourceUrl: string;

    this.previewSrc = isDataUrl(this.initialSrc) ? this.initialSrc : this.getResourceUrl(this.initialSrc);
    // Create the load image promise
    if (this.isReview && this.initialSrc) {
      resourceUrl = isCentralUrl(this.previewSrc) ? this.textEditorService.resolveCentralUrl(this.previewSrc) : this.previewSrc;
      loadFile$ = this.http.get(resourceUrl, { responseType: 'blob' });
    }

    // Start loading symbol
    this.loadingState.update(true);
    this.loadFileSubscription = loadFile$.subscribe((imageFile: Blob) => {
      if (imageFile) {
        this.initialFiles = this.createFileValue(imageFile);
      }
      this.createImageForm(this.initialFiles);

      if (!this.isReview) {
        this.createFormSubscriptions();
      }

      // Update the preview image
      this.cdr.markForCheck();
      // Stop loading symbol
      this.onInitialFileLoad();
    }, (error: HttpErrorResponse) => {
      this.createImageForm();
      // Update the preview image
      this.cdr.markForCheck();
      this.onInitialFileError();
    });
  }

  createFileValue(imageFile: Blob): FileWithPath[] {
    let fileName = this.stringService.getFilenameFromUrl(this.initialSrc);

    const imageIsCentralUrl = isCentralUrl(this.initialSrc);
    if (imageIsCentralUrl) {
      // Uploaded files append a _guid to the filename
      const guidIndex = fileName.lastIndexOf('_');
      // Strip the guid from the blob if file is uploaded
      if (guidIndex > 0) {
        fileName = fileName.substring(0, guidIndex) + this.fileService.getFileExtension(fileName);
      }
    } else if (isDataUrl(this.initialSrc)) {
      fileName = `Data URI${imageFile.type.replace('image\/', '.')}`;
    }

    // Save the relative file path when the image is a project file
    let relativePath: string;
    // Check that image is not an uploaded resource, a data url or a web link
    if (!imageIsCentralUrl && !isDataUrl(this.initialSrc) && !isAbsoluteUrl(this.initialSrc)) {
      // This is the display path for the project file
      relativePath = dirname(`Content/${this.initialSrc}`, true);
    }
    const fileWithPath = new FileWithPath([imageFile], fileName, { type: imageFile.type }, relativePath );
    // stop loading symbol
    return makeArray(fileWithPath);
  }

  createFormSubscriptions() {
    this.valueSubscription = this.imageForm.valueChanges.subscribe(values => {
      if (values.from === 'fromProject' && values.projectImagePath) {
        this.previewSrc = this.getResourceUrl(
          relative(dirname(this.editorFilePath), firstOrNull(values.projectImagePath))
        );
      } else if (values.from === 'fromWeb' && values.webLink) {
        if (isDataUrl(values.webLink)) {
          this.imageForm.patchValue({ from: 'fromDataUri', webLink: null, dataUri: values.webLink});
          return;
        }
        this.previewSrc = values.webLink;
      } else if (values.from === 'fromDataUri' && values.dataUri) {
        if (isAbsoluteUrl(values.dataUri)) {
          this.imageForm.patchValue({ from: 'fromWeb', dataUri: null, webLink: values.dataUri});
          return;
        }
        this.previewSrc = values.dataUri;
      } else {
        this.previewSrc = '';
      }
      // Mark the view as changed
      this.cdr.markForCheck();
    });

    // sets the web src input either enabled or disabled.
    this.fromSubscription = this.imageForm.controls.from.valueChanges.subscribe(from => {
      const webLinkControl = this.imageForm.controls.webLink;
      const projectFileControl = this.imageForm.controls.projectImagePath;
      const dataUriControl = this.imageForm.controls.dataUri;
      if (from === 'fromWeb') {
        webLinkControl.enable();
        projectFileControl.disable();
        dataUriControl.disable();
      } else if (from === 'fromProject') {
        projectFileControl.enable();
        webLinkControl.disable();
        dataUriControl.disable();
      } else if (from === 'fromDataUri') {
        dataUriControl.enable();
        webLinkControl.disable();
        projectFileControl.disable();
      }
    });
  }

  onNewImageFileSelected(files: FileWithError[]) {
    // File removed
    if (!files?.length) {
      this.previewSrc = '';
      // Mark the view as changed
      this.cdr.markForCheck();
      return;
    }

    // Update the image preview
    this.fileService.readAsDataURL$(firstOrNull(files)).subscribe(result => {
      this.previewSrc = result;
      // Mark the view as changed
      this.cdr.markForCheck();
    });
  }

  onSubmit() {
    const imageForm = this.imageForm.controls;
    const from = imageForm.from.value;
    const projectImagePath: string = firstOrNull(imageForm.projectImagePath.value);
    const relativeProjectPath = relative(dirname(this.editorFilePath), projectImagePath);
    const webPath = imageForm.webLink.value;
    const dataUri = imageForm.dataUri.value;
    const width = imageForm.width.value;
    const height = imageForm.height.value;
    const alt = imageForm.alt.value;
    const newFile: FileWithError = firstOrNull(imageForm.newFile.value);

    let result: ProjectFilesImageDialogResult = {attrs: {
      alt: alt,
      style: { width, height }
    }};

    if (!this.isReview) {
      result.attrs.src =
        from === 'fromProject' ? relativeProjectPath
        : from === 'fromWeb' ? webPath
        : dataUri;
    } else if (newFile !== firstOrNull(this.initialFiles)) {
      result.file = newFile;
    }

    // save defaults for next time
    this.userSettingsService.setSettingByName$('flare-text-editor:image:style', stringifyStyles(result.attrs.style));

    this.closeDialog(result);
  }

  protected createImageForm(files?: FileWithPath[]) {
    let fromVal: string = 'fromProject';
    if (isDataUrl(this.initialSrc)) {
      fromVal = 'fromDataUri';
    } else if (isAbsoluteUrl(this.initialSrc)) {
      fromVal = 'fromWeb'
    }

    if (this.initialSrc && fromVal === 'fromProject') {
      // Update to the full path from the relative src
      this.initialSrc = resolvePath(this.editorFilePath, this.initialSrc);
    }

    this.imageForm = this.fb.group({
      from: new FormControl(fromVal),
      projectImagePath: new FormControl({ value: fromVal === 'fromProject' && !!this.initialSrc ? makeArray(this.initialSrc) : null, disabled: !(fromVal === 'fromProject') || this.isReview }, [Validators.required]),
      webLink: new FormControl({ value: fromVal === 'fromWeb' ? this.initialSrc : null, disabled: !(fromVal === 'fromWeb') || this.isReview }, {validators: Validators.required, updateOn: 'blur'}),
      dataUri: new FormControl({ value: fromVal === 'fromDataUri' ? this.initialSrc : null, disabled: !(fromVal === 'fromDataUri') || this.isReview }, {validators: Validators.required, updateOn: 'blur'}),
      newFile: new FormControl({ value: files, disabled: !this.isReview }, [Validators.required, this.formsService.createFileTypeValidator(EditorImageFileTypes, EditorImageExtensions), this.formsService.createFileSizeValidator(MaxAttachmentSizeBytes)]),
      alt: new FormControl(this.alt),
      width: new FormControl(this.width),
      height: new FormControl(this.height),
    });
  }

  getResourceUrl(src: string = ''): string {
    // If it's a project file, get the location of the stored image
    if (src && !isAbsoluteUrl(src)) {
      return this.textEditorService.buildFlareContentApiUri(this.projectId, this.commitId ?? this.branch, this.editorFilePath, src);
    }

    return src;
  }

  onInitialFileLoad() {
    this.loadingState.update(false);
  }

  onInitialFileError() {
    this.loadingState.update(false, ErrorCode.ValidationImageFileMissing);
  }

  onPreviewLoad() {
    this.previewLoadingState.update(false);
  }

  onPreviewError() {
    this.previewLoadingState.update(false, ErrorCode.ValidationImageFileMissing);
  }

}
