import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormControlErrorMessageModule } from '@portal-core/forms/components/form-control-error-message/form-control-error-message.module';
import { ProjectFilePickerInputModule } from '@portal-core/project-files/components/project-file-picker-input/project-file-picker-input.module';
import { StylePickerInputModule } from '@portal-core/ui/style-picker/components/style-picker/style-picker-input.module';
import { MultimediaDialogComponent } from './multimedia-dialog.component';

@NgModule({
  declarations: [
    MultimediaDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatIconModule,
    FormControlErrorMessageModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    StylePickerInputModule,
    ProjectFilePickerInputModule
  ],
  exports: [
    MultimediaDialogComponent
  ]
})
export class MultimediaDialogModule { }
