import { Pipe, PipeTransform } from '@angular/core';
import { MarkdownService } from '@portal-core/general/services/markdown.service';

/*
 * Changes a string that can be complied into xml
 * Usage:
 *   string | mcMarkdownToFlareXML
 * Example:
 *   {{'Sure! Here's an example of a simple HTML table:

```html
<!DOCTYPE html>
<html>
<head>
    <title>HTML Table Example</title>
    <style>
        table {
            border-collapse: collapse;
            width: 100%;
        }
        
        th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
        }
        
        th {
            background-color: #f2f2f2;
        }
    </style>
</head>
<body>
    <table>
        <thead>
            <tr>
                <th>Column 1</th>
                <th>Column 2</th>
                <th>Column 3</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Row 1, Cell 1</td>
                <td>Row 1, Cell 2</td>
                <td>Row 1, Cell 3</td>
            </tr>
            <tr>
                <td>Row 2, Cell 1</td>
                <td>Row 2, Cell 2</td>
                <td>Row 2, Cell 3</td>
            </tr>
        </tbody>
    </table>
</body>
</html>
```

In this example, we have a table with a header row (`<thead>`) and body rows (`<tbody>`). The table has three columns and two rows. The table is styled using CSS to have borders, padding, and background color for the header row.

You can customize this table by adding more rows or columns, changing the content of the cells, and modifying the CSS styles to suit your needs.' | mcMarkdownToFlareXML }}
 * Returns a string that will work as flare xml
 * '<p>Sure! Here&#39;s an example of a simple HTML table:</p>
<MadCap:codeSnippet> <MadCap:codeSnippetBody>&lt;!DOCTYPE html&gt;
&lt;html&gt;
&lt;head&gt;
    &lt;title&gt;HTML Table Example&lt;/title&gt;
    &lt;style&gt;
        table {
            border-collapse: collapse;
            width: 100%;
        }
        
        th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
        }
        
        th {
            background-color: #f2f2f2;
        }
    &lt;/style&gt;
&lt;/head&gt;
&lt;body&gt;
    &lt;table&gt;
        &lt;thead&gt;
            &lt;tr&gt;
                &lt;th&gt;Column 1&lt;/th&gt;
                &lt;th&gt;Column 2&lt;/th&gt;
                &lt;th&gt;Column 3&lt;/th&gt;
            &lt;/tr&gt;
        &lt;/thead&gt;
        &lt;tbody&gt;
            &lt;tr&gt;
                &lt;td&gt;Row 1, Cell 1&lt;/td&gt;
                &lt;td&gt;Row 1, Cell 2&lt;/td&gt;
                &lt;td&gt;Row 1, Cell 3&lt;/td&gt;
            &lt;/tr&gt;
            &lt;tr&gt;
                &lt;td&gt;Row 2, Cell 1&lt;/td&gt;
                &lt;td&gt;Row 2, Cell 2&lt;/td&gt;
                &lt;td&gt;Row 2, Cell 3&lt;/td&gt;
            &lt;/tr&gt;
        &lt;/tbody&gt;
    &lt;/table&gt;
&lt;/body&gt;
&lt;/html&gt;</MadCap:codeSnippetBody> </MadCap:codeSnippet><p>In this example, we have a table with a header row (&lt;thead&gt;) and body rows (&lt;tbody&gt;). The table has three columns and two rows. The table is styled using CSS to have borders, padding, and background color for the header row.</p>
<p>You can customize this table by adding more rows or columns, changing the content of the cells, and modifying the CSS styles to suit your needs.</p>'
*/
@Pipe({
    name: 'mcMarkdownToFlareXML'
})
export class MarkdownToFlareXMLPipe implements PipeTransform {

    constructor(private markdownService: MarkdownService) {

    }

    transform(markdown: string): string {
        return this.markdownService.toFlareXML(markdown);
    }
}
