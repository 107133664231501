import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { nodeToFlareXML } from '@common/flare/util/prosemirror-flare-xml';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';

export class MMLSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    mmlmath: {
      group: 'inline',
      inline: true,
      attrs: {
        'xmlns:mml': { default: 'http://www.w3.org/1998/Math/MathML', specRequired: true },
        content: { default: undefined }
      },
      parseDOM: [{
        tag: 'mml\\:math',
        getAttrs: function (dom: HTMLElement) {
          return {
            content: dom.outerHTML
          };
        }
      }],
      toDOM() { return ['div', { class: 'mc-mml-math' }, 'mml:math']; },
      toFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
        return nodeToFlareXML(node, options, 'mml:math', {
          xml: node.attrs.content
        });
      },
      tagName: 'mml:math'
    }
  };
}
