import { ChangeDetectionStrategy, Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreService } from '@portal-client/core/core.service';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'mc-app-full-navbar-heading',
  templateUrl: './app-full-navbar-heading.component.html',
  styleUrls: ['./app-full-navbar-heading.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppFullNavbarHeadingComponent implements OnInit {
  @HostBinding('class.navbar-heading-logo') navbarHeadingLogoClass: boolean = true;

  routerLinkCommand$: Observable<any[]>;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.routerLinkCommand$ = this.coreService.getActiveLicense$().pipe(
      map(license => license ? ['/', license.Id] : ['/']),
    );
  }
}
