<mc-editor-toolbar #toolbar [toolbarControl]="toolbarControl$ | async" (itemClick)="onItemClicked($event)" (dropdownMenuClosed)="onDropdownMenuClosed($event)">
  <ng-template mcEditorToolbarItem="AI" let-item>
    <div class="mc-disabled-button-tooltip" [matTooltip]="(userCanEditWithAIAssist$ | async) ? item.tooltip : 'AI Assist not enabled in permissions'">
      <button mat-icon-button type="button" [disabled]="!(userCanEditWithAIAssist$ | async) || item.disabled" (click)="openProjectFilesAIDialog(item)">
        <mat-icon *ngIf="item.icon" fontSet="project-titan" [fontIcon]="item.icon"></mat-icon>
        <ng-container *ngIf="item.text">{{ item.text }}</ng-container>
      </button>
    </div>
  </ng-template>

  <!-- Same as default button template but click opens dialog instead of running a command -->
  <ng-template mcEditorToolbarItem="projectSnippet" let-item>
    <div class="mc-disabled-button-tooltip" [matTooltip]="item.tooltip">
      <button mat-icon-button [disabled]="item.disabled" [ngClass]="{ 'mc-bg-active-button': item.active }" (click)="openInsertProjectSnippetDialog(item)">
        <mat-icon *ngIf="item.icon" fontSet="project-titan" [fontIcon]="item.icon"></mat-icon>
        <ng-container *ngIf="item.text">{{ item.text }}</ng-container>
      </button>
    </div>
  </ng-template>

  <ng-template mcEditorToolbarItem="table" let-item>
    <mc-dimension-picker [tooltip]="item.tooltip" icon="icon-table" (options)="onTableDimensionsPicked(item, $event)" (dropdownMenuClosed)="onDimensionPickerDropdownMenuClosed(item, $event)"></mc-dimension-picker>
  </ng-template>
</mc-editor-toolbar>