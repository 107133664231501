import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mc-full-navbar',
  templateUrl: './full-navbar.component.html',
  styleUrls: ['./full-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullNavbarComponent { }
