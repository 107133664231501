<ng-container *ngIf="selectionSize !== SelectionSize.None">
  <mat-toolbar class="mc-toolbar-small mc-toolbar-inline" color="basic">
    <span>Tag Info:&nbsp;</span>

    <span *ngIf="selectionSize === SelectionSize.All">All selected</span>
    <span *ngIf="selectionSize === SelectionSize.Multiple">Multiple selected</span>
    <ng-container *ngIf="selectionSize === SelectionSize.Single">
      <ng-container *ngFor="let tag of tagPath; last as isLast">
        <button mat-button class="mc-flare-file-text-editor-tagbar-button mc-button-small" (click)="onTagClicked(tag)">&nbsp;{{ tag.text }}&nbsp;</button>
        <span *ngIf="!isLast" class="text-light-weight">&nbsp;&gt;&nbsp;</span>
      </ng-container>
    </ng-container>
  </mat-toolbar>
</ng-container>

<mat-toolbar *ngIf="tagDetails" class="mc-toolbar-small mc-toolbar-inline" color="basic">
  <ng-container *ngFor="let tagDetail of tagDetails; last as isLast">
    <span>{{ tagDetail.label }}&nbsp;</span>
    <span class="text-light-weight">{{ tagDetail.text }}</span>
    <ng-container *ngIf="!isLast">&nbsp;</ng-container>
  </ng-container>
</mat-toolbar>

<mat-toolbar *ngIf="annotation" class="mc-toolbar-small mc-toolbar-inline" color="basic">
  <span>Annotation:&nbsp;</span>
  <span class="text-light-weight">Created by&nbsp;</span>
  <span>{{ annotation['MadCap:creator'] }} {{ annotation['MadCap:createDate'] | mcDate : 'relative' }}</span>
  <ng-container *ngIf="annotation['MadCap:editor']">
    <span class="text-light-weight">.&nbsp;Modified by&nbsp;</span>
    <span>{{ annotation['MadCap:editor'] }} {{ annotation['MadCap:editDate'] | mcDate : 'relative' }}</span>.
  </ng-container>
</mat-toolbar>

<mat-toolbar *ngIf="changeList" class="mc-toolbar-small mc-toolbar-inline" color="basic">
  <span>Tracked Changes:&nbsp;</span>
  <span class="text-light-weight">{{ changeList.length === 1 ? 'Change' : 'Changes' }} by&nbsp;</span>
  <span *ngFor="let change of changeList; first as first; last as last">
    {{ last && !first ? '&nbsp;and' : (!last && !first ? ',' : '') }}
    {{ change.userName }} {{ change.timestamp | mcDate : 'relative' }}
  </span>
</mat-toolbar>