import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CollabSessionError } from '@common/sockets/enums/collab-session-error.enum';
import { CollabSessionErrorEvent } from '@common/sockets/types/collab-session-error-event.type';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { TextEditorService } from '@portal-core/text-editor/services/text-editor.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { Observable, combineLatest, map } from 'rxjs';

@Component({
  selector: 'mc-collab-file-text-editor-status-bar',
  templateUrl: './collab-file-text-editor-status-bar.component.html',
  styleUrls: ['./collab-file-text-editor-status-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollabFileTextEditorStatusBarComponent implements OnInit {
  @Input() error: CollabSessionErrorEvent;
  @Input() exceedsMaxSize: boolean;
  @Input() exceedsMongoDBMaxSize: boolean;
  @Input() licenseUser: LicenseUser;
  @Input() offline: boolean;
  @Input() readonly: boolean;
  @Input() size: number;

  @InputObservable('error') error$: Observable<CollabSessionErrorEvent>;
  @InputObservable('exceedsMaxSize') exceedsMaxSize$: Observable<boolean>;
  @InputObservable('exceedsMongoDBMaxSize') exceedsMongoDBMaxSize$: Observable<boolean>;
  @InputObservable('offline') offline$: Observable<boolean>;
  @InputObservable('readonly') readonly$: Observable<boolean>;
  @InputObservable('size') size$: Observable<number>;

  @Output() createCollabFile: EventEmitter<void> = new EventEmitter<void>();
  @Output() revertDocToSavedState: EventEmitter<void> = new EventEmitter<void>();

  CollabSessionError: typeof CollabSessionError = CollabSessionError;
  LicenseUserSeatType: typeof LicenseUserSeatType = LicenseUserSeatType;

  collabSessionError$: Observable<CollabSessionError>;
  collabSessionReadonly$: Observable<boolean>;
  readonly maxSize: number;
  showExceedsMaxSize$: Observable<boolean>;
  showExceedsMongoDBMaxSize$: Observable<boolean>;
  showSizeWarning$: Observable<boolean>;
  toolbarClassName$: Observable<string>;
  toolbarColorName$: Observable<string>;
  private readonly warningSize: number;

  constructor(private textEditorService: TextEditorService) {
    this.maxSize = this.textEditorService.flareTextEditorDocMaxCharLength;
    this.warningSize = this.maxSize * .8;
  }

  ngOnInit() {
    // There is a collab session error if the user is online and there is an error
    this.collabSessionError$ = combineLatest([
      this.offline$,
      this.error$
    ]).pipe(
      map(([offline, error]) => {
        if (offline) {
          return null;
        } else if (error?.type === CollabSessionError.VersionMismatch || error?.type === CollabSessionError.DocVersionInvalid) {
          return null; // Version mismatch errors should not be displayed
        } else if (error) {
          return error.type;
        }
      })
    );

    // The collab session is readonly if the user is online and the file is readonly
    this.collabSessionReadonly$ = combineLatest([
      this.offline$,
      this.readonly$
    ]).pipe(
      map(([offline, readonly]) => {
        if (offline) {
          return false;
        } else {
          return readonly;
        }
      })
    );

    // Show the size warning if the user is online, the file is not readonly, there is no error, and if the file size is greater than 90% of the max size and less than the max size
    this.showSizeWarning$ = combineLatest([
      this.error$,
      this.exceedsMongoDBMaxSize$,
      this.offline$,
      this.readonly$,
      this.size$
    ]).pipe(
      map(([error, exceedsMongoDBMaxSize, offline, readonly, size]) => {
        if (error || exceedsMongoDBMaxSize || offline || readonly) {
          return false;
        } else {
          return size > this.warningSize && size < this.maxSize;
        }
      })
    );

    // Show the exceeds max export length warning if the user is online, the file is not readonly, there is no error, and if the file size is undefined (because the length is too long to calculate)
    this.showExceedsMaxSize$ = combineLatest([
      this.error$,
      this.exceedsMaxSize$,
      this.offline$,
      this.readonly$,
      this.size$
    ]).pipe(
      map(([error, exceedsMaxSize, offline, readonly, size]) => {
        if (error || offline || readonly) {
          return false;
        } else {
          return exceedsMaxSize && size > this.maxSize;
        }
      })
    );

    // Show the exceeds MongoDB max size warning if the user is online, the file is not readonly, there is no error, the doc xml is not larger than the max size, and if the MongoDB document is greater than the MongoDB document max size
    this.showExceedsMongoDBMaxSize$ = combineLatest([
      this.error$,
      this.showExceedsMaxSize$,
      this.exceedsMongoDBMaxSize$,
      this.offline$,
      this.readonly$
    ]).pipe(
      map(([error, showExceedsMaxSize, exceedsMongoDBMaxSize, offline, readonly]) => {
        if (error || offline || readonly || showExceedsMaxSize) {
          return false;
        } else {
          return exceedsMongoDBMaxSize;
        }
      })
    );

    // The toolbar class name is what shows the toolbar with CSS
    this.toolbarClassName$ = combineLatest([
      this.collabSessionError$,
      this.offline$,
      this.collabSessionReadonly$,
      this.showExceedsMaxSize$,
      this.showExceedsMongoDBMaxSize$,
      this.showSizeWarning$
    ]).pipe(
      map(([error, offline, readonly, showExceedsMaxSize, showExceedsMongoDBMaxSize, showSizeWarning]) => {
        if (offline || error || readonly || showExceedsMaxSize || showExceedsMongoDBMaxSize || showSizeWarning) {
          return 'mc-collab-file-text-editor-status-bar-visible';
        } else {
          return '';
        }
      })
    );

    // The toolbar color name is what sets the color of the toolbar with CSS and is based on the type of error or state of the doc.
    this.toolbarColorName$ = combineLatest([
      this.collabSessionError$,
      this.offline$,
      this.collabSessionReadonly$,
      this.showExceedsMaxSize$,
      this.showExceedsMongoDBMaxSize$,
      this.showSizeWarning$
    ]).pipe(
      map(([error, offline, readonly, showExceedsMaxSize, showExceedsMongoDBMaxSize, showSizeWarning]) => {
        if (offline) {
          return 'notice';
        } else if (showExceedsMaxSize || showExceedsMongoDBMaxSize || error === CollabSessionError.UnexpectedError || error === CollabSessionError.DocLoadFailed || error === CollabSessionError.DocSizeTooLarge || error === CollabSessionError.MongoDBDocumentSizeTooLarge) {
          return 'warn';
        } else if (error || readonly || showSizeWarning) {
          return 'caution';
        } else {
          return '';
        }
      })
    );
  }

  onCreateCollabFile(event: MouseEvent) {
    event.preventDefault();
    this.createCollabFile.emit();
  }

  onRevertDocClicked() {
    this.revertDocToSavedState.emit();
  }
}
