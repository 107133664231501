import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

export class MadCapQRCodeSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapqrcode: {
      group: 'inline',
      inline: true,
      content: 'text*',
      atom: true,
      definingForContent: true,
      parseDOM: [{
        tag: 'MadCap\\:qrCode'
      }],
      toDOM() {
        return ['span', { class: 'mc-madcap-qr-code' }, 'QR Code'];
      },
      tagName: 'MadCap:qrCode'
    }
  };
}
