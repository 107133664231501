import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

export class MadCapPageFooterSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcappagefooter: {
      group: 'block',
      content: 'block*',
      atom: true,
      parseDOM: [{
        tag: 'MadCap\\:pageFooter'
      }],
      toDOM() {
        return ['div', { class: 'mc-madcap-page-footer' }, 'Page Footer'];
      },
      tagName: 'MadCap:pageFooter'
    }
  };
}
